import GroupIcon from '@mui/icons-material/Group';
import LogoutIcon from '@mui/icons-material/Logout';
import { Box, Drawer, List, ListItem, ListItemIcon } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';

import useQuickStartApi from '../../features/Quickstart/api/useQuickStartApi';
import { getEnv, getHeaderRoutes, MobileDrawerWidth, MobileDrawerWidthPercentage } from './common';
import HeaderList from './HeaderList';
import TeamToggle from './Toggles/TeamToggle';

export default function MobileDrawer({
  open,
  handleDrawerToggle,
  setLoading,
}: {
  open: boolean;
  handleDrawerToggle: () => void;
  setLoading: (loading: boolean) => void;
}) {
  const { data: quickStartInfo } = useQuickStartApi();
  let qsAppId: string;
  if (quickStartInfo === undefined || quickStartInfo.length === 0) {
    qsAppId = '';
  } else {
    qsAppId = quickStartInfo[0].customer_app_id;
  }
  const env = getEnv(window.location.hostname);
  const headerRoutes = getHeaderRoutes(env, qsAppId);
  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={handleDrawerToggle}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={{
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: `max(${MobileDrawerWidthPercentage}, ${MobileDrawerWidth}px)`,
          border: 'none',
        },
      }}
    >
      <Toolbar />
      <List
        sx={{
          mt: 3,
        }}
      >
        {headerRoutes.map((props, idx) => (
          <HeaderList key={`headerList-${idx}`} {...props} />
        ))}
      </List>
      <Box sx={{ flex: 1 }} />
      <List>
        <ListItem>
          <ListItemIcon>
            <GroupIcon />
          </ListItemIcon>
          <TeamToggle setLoading={setLoading} />
        </ListItem>
        <HeaderList key="headerList-logout" route="/logout" buttonText="Logout" Icon={<LogoutIcon />} />
      </List>
    </Drawer>
  );
}

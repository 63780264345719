import { ReactElement } from 'react';

import { Redirect, Route, Switch } from 'react-router-dom';

import Routes from '../../lib/routes';
import QuickStartCustomizations from './components/QuickStartCustomizations';
import QuickStartForm from './components/QuickStartForm';
import QuickStartGuide from './components/QuickStartGuide';
import { QuickStartLinkedUsers } from './components/QuickStartLinkedUsers';
import QuickStartSettings from './components/QuickStartSettings';

const QuickStart = (): ReactElement => {
  return (
    <Switch>
      <Route exact path={Routes.QuickStart.New}>
        <QuickStartForm />
      </Route>
      <Route path={`${Routes.QuickStart.LinkedUsers}/:customer_app_id`}>
        <QuickStartLinkedUsers />
      </Route>
      <Route exact path={Routes.QuickStart.Settings}>
        <QuickStartSettings />
      </Route>
      <Route exact path={Routes.QuickStart.Guide}>
        <QuickStartGuide />
      </Route>
      <Route exact path={Routes.QuickStart.Customizations}>
        <QuickStartCustomizations />
      </Route>
      <Route path={Routes.QuickStart.Base}>
        <Redirect to={Routes.QuickStart.Settings} />
      </Route>
    </Switch>
  );
};

export default QuickStart;

import { useState, useEffect } from 'react';

// import ExtensionIcon from '@mui/icons-material/Extension';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, CircularProgress, IconButton, Link as MaterialLink } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useHistory } from 'react-router-dom';

import useResponsive from '../../hooks/useResponsive';
import { Backdrop } from '../Backdrop';
import { DesktopDrawerWidth, styles } from './common';
import DesktopDrawer from './DesktopDrawer';
import MobileDrawer from './MobileDrawer';

export default function AuthenticatedHeader({ children }: { children: React.ReactNode }): JSX.Element {
  const history = useHistory();
  const [mobileOpen, setMobileOpen] = useState(false);
  const { isDesktop } = useResponsive();

  const [loading, setLoading] = useState(false);

  const toggleDrawer = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    setMobileOpen(false);
  }, [history.location.pathname]);

  return (
    <Box sx={isDesktop ? { display: 'flex' } : {}}>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: 'background.default',
          boxShadow: 'none',
          width: { lg: `calc(100% - ${DesktopDrawerWidth})` },
          ml: { lg: DesktopDrawerWidth },
        }}
      >
        {!isDesktop && (
          <Toolbar sx={{ gap: 1 }}>
            <IconButton
              edge="start"
              color="primary"
              size="large"
              aria-label="open navigation drawer"
              onClick={toggleDrawer}
              sx={{ display: { xs: 'flex', lg: 'none' }, alignItems: 'center' }}
            >
              <MenuIcon sx={{ fontSize: 40 }} />
            </IconButton>
            <MaterialLink
              href="https://www.finverse.com"
              sx={{
                display: { xs: 'flex', lg: 'none' },
                alignContent: 'center',
              }}
            >
              <Box sx={styles.logo} component="img" src={process.env.PUBLIC_URL + '/logo.svg'} alt="finverse logo" />
            </MaterialLink>
          </Toolbar>
        )}
      </AppBar>
      <Box
        component="nav"
        sx={{
          width: { lg: DesktopDrawerWidth },
          flexShrink: { lg: 1 },
          zIndex: '999',
        }}
        aria-label="navigation bar"
      >
        {isDesktop ? (
          <DesktopDrawer setLoading={setLoading} />
        ) : (
          <MobileDrawer open={mobileOpen} handleDrawerToggle={toggleDrawer} setLoading={setLoading} />
        )}
      </Box>
      <Box sx={{ flexGrow: 1, p: 3, width: { lg: `calc(100% - ${DesktopDrawerWidth})` }, backgroundColor: '' }}>
        {!isDesktop && <Toolbar />}
        {children}
      </Box>
      <Backdrop open={loading}>
        <CircularProgress
          size={64}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-12px',
            marginLeft: '-12px',
          }}
        />
      </Backdrop>
    </Box>
  );
}

import { useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Grid,
  Alert,
  FormControl,
  MenuItem,
  InputLabel,
  Box,
  IconButton,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';

export enum ConnectionType {
  Live = 'live',
  Test = 'test',
  None = '',
}

type Props = {
  /**
   * whether this dialog is displayed or not
   */
  show: boolean;
  /**
   * if exists an error message will be shown
   */
  errorMessage?: string;
  /**
   * this will close this dialog without doing anything
   */
  onClose: () => void;
  /**
   * on success this function will be called
   */
  onSuccess: (type: ConnectionType) => void;
};

const generateErrorSection = (details?: string): JSX.Element => {
  if (details !== undefined) {
    return (
      <Grid container item display="flex" sx={{ marginBottom: 3 }}>
        <Alert sx={{ flex: 1 }} severity="warning" variant="standard">
          {details}
        </Alert>
      </Grid>
    );
  } else {
    return <></>;
  }
};

export default function SelectConnectionType(props: Props): JSX.Element {
  const [connectionType, setConnectionType] = useState<ConnectionType>(ConnectionType.None);
  const handleChange = (event: SelectChangeEvent) => {
    setConnectionType(event.target.value as ConnectionType);
  };

  const connect = (type: ConnectionType) => {
    if (type === ConnectionType.None) {
      return;
    }
    props.onSuccess(type);
  };

  return (
    <Dialog
      open={props.show}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box sx={{ height: '100%', margin: 1 }}>
        <DialogTitle sx={{ textAlign: 'center', fontWeight: 600, padding: 4 }} id="alert-dialog-title">
          Configure your Xero connection
          <IconButton sx={{ position: 'absolute', top: 20, right: 15 }} onClick={props.onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {generateErrorSection(props.errorMessage)}
          <Box sx={{ marginTop: 1 }}>
            <FormControl required fullWidth>
              <InputLabel id="select-connection-type-label">Connection Type</InputLabel>
              <Select
                labelId="select-connection-type-label"
                id="select-connection-type"
                value={connectionType}
                fullWidth
                label="Connection Type"
                onChange={handleChange}
              >
                <MenuItem value="test">Test Connection</MenuItem>
                <MenuItem value="live">Live Connection</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <DialogContentText sx={{ marginTop: 1, paddingLeft: 2, fontSize: 15 }}>
            &bull; Select “Live connection” to connect your actual Xero organization and enable collections from real
            banks.
          </DialogContentText>
          <DialogContentText sx={{ paddingLeft: 2, fontSize: 15 }}>
            &bull;
            {` Select "Test connection" to connect Xero's "Demo Company" and enable simulated collections from Finverse's testbank.`}
          </DialogContentText>
          <DialogContentText sx={{ marginTop: 2, paddingLeft: 2, height: 80, fontSize: 15 }}>
            {connectionType === ConnectionType.Test && (
              <>
                <div>{`Make sure you authorize "Demo Company" on Xero's authorization screen.`}</div>
                <div>
                  In test mode, we do not recommend connecting your actual Xero organization to avoid recording
                  simulated payments to real accounts in Xero.
                </div>
              </>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button
            disabled={connectionType === ConnectionType.None}
            variant="contained"
            onClick={() => connect(connectionType)}
          >
            Continue
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

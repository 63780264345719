import { useContext, useState } from 'react';

import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useParams } from 'react-router-dom';

import { AppContext } from '../contexts/AppContext/AppContext';
import { getAuth0CallbackUri } from '../lib/auth0';
import { getSDKClient } from '../lib/sdk';

type ParamTypes = {
  message: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    title: {
      marginBottom: '4vh',
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.primary,
      height: '80vh',
    },
    a: {
      textDecoration: 'none',
      color: '#2196F3',
      cursor: 'pointer',
    },
    message: {
      marginTop: '2vh',
      marginBottom: '2vh',
      fontSize: '1.3em',
    },
    button: {
      margin: '1vh 0',
    },
  }),
);

const errors = new Map<string, string>();

errors.set(
  'EMAIL_NOT_VERIFIED',
  'We sent you a verification email. Please check your inbox to verify your email, then login again.',
);
errors.set('CANNOT_VERIFY_EMAIL', 'Your email cannot be verified.');

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function ErrorScreen(): JSX.Element {
  const classes = useStyles();
  const { app } = useContext(AppContext);
  const { message } = useParams<ParamTypes>();
  const errMesaage = errors.get(message);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showFailure, setShowFailure] = useState(false);

  const token = app.tokens.get(app.env);

  const handleClose = (_event?: React.SyntheticEvent<unknown> | Event, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowSuccess(false);
    setShowFailure(false);
  };

  const resendVerificationEmail = async () => {
    try {
      await getSDKClient(app.env, String(token)).resendVerificationEmail();
      setShowSuccess(true);
    } catch (err) {
      setShowFailure(true);
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <div className={classes.paper}>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={showSuccess}
            autoHideDuration={3000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity="info">
              Verification email sent successfully!
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={showFailure}
            autoHideDuration={3000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity="error">
              Error sending verification email!
            </Alert>
          </Snackbar>
          <Typography variant="h1" component="h1" className={classes.title}>
            Verify your email
          </Typography>
          <Typography variant="h6" component="h6" className={classes.message}>
            {errMesaage}
          </Typography>
          {message === 'EMAIL_NOT_VERIFIED' && (
            <div className={classes.button}>
              Did not receive a verification email?{' '}
              <a className={classes.a} onClick={() => resendVerificationEmail()}>
                Resend verification email
              </a>
            </div>
          )}
          <div className={classes.button}>
            Already verified?{' '}
            <a className={classes.a} href={getAuth0CallbackUri(app.env)}>
              Login
            </a>
          </div>
          <div className={classes.button}>
            <a className={classes.a} href="/logout?action=signup_after_logout">
              Sign up
            </a>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

import { ReactElement, useState } from 'react';

import { TextField } from '@mui/material';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

import { FormInputText } from '../../../components/form-components/FormInputText';
import { getErrorMessage } from '../../../lib/error';
import Routes from '../../../lib/routes';
import useQuickStartApi from '../api/useQuickStartApi';
import { getEnvAppPrefix } from '../common';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

interface FormInput {
  appPath: string;
}

const defaultValues = {
  appPath: '',
};

const QuickStartForm = (): ReactElement => {
  const classes = useStyles();
  const [disabled, setDisabled] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const methods = useForm<FormInput>({ defaultValues: defaultValues });
  const { createQuickStartLink, refetchInfo, env } = useQuickStartApi();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = methods;
  const onSubmit = async (data: FormInput) => {
    setDisabled(true);
    try {
      await createQuickStartLink(data);
      refetchInfo();
      enqueueSnackbar('New quick start link was created successfully', { variant: 'success' });
      setTimeout(() => {
        window.location.href = Routes.QuickStart.Base;
      }, 1000); // reload so we show the QS detail page
    } catch (e) {
      setDisabled(false);
      enqueueSnackbar(getErrorMessage(e), { variant: 'error' });
    }
  };

  return (
    <Container maxWidth="md">
      <Paper
        sx={{
          mt: 8,
          p: 5,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
        }}
      >
        <Typography variant="h1" component="h1" align="center" gutterBottom>
          Create a new quick start link
        </Typography>
        <Typography variant="h6" component="h6" align="center" gutterBottom>
          Complete the following form to create a new quick start link
        </Typography>
        <Box component="form" sx={{ mt: '2rem', mr: 5, ml: 5 }}>
          <Box
            sx={{
              display: 'flex',
            }}
          >
            <RightTextFlat
              size="small"
              fullWidth
              variant="outlined"
              margin="normal"
              value={getEnvAppPrefix(env)}
              sx={{
                bgcolor: '#bfbfbf',
                input: {
                  color: '#404040',
                },
                width: getWidthForEnv(env),
              }}
            ></RightTextFlat>
            <LeftFormFlat
              name="appPath"
              label="App path"
              rules={{ required: true, pattern: /^[a-zA-Z0-9_-]+$/i }}
              control={control}
              margin="normal"
              required
              sx={{
                borderTopLeftRadius: '0px',
                borderBottomLeftRadius: '0px',
              }}
            />
          </Box>

          {errors?.appPath?.type === 'required' && <Alert severity="error">This field is required</Alert>}
          {errors?.appPath?.type === 'pattern' && (
            <Alert severity="error">
              Invalid app path, only letters (a-z), numbers (0-9), underscores (_) and hyphens (-) are allowed
            </Alert>
          )}
          <Button
            onClick={handleSubmit(onSubmit)}
            fullWidth
            color="primary"
            variant={'contained'}
            className={classes.submit}
            disabled={disabled}
          >
            Submit
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

const getWidthForEnv = (env: string): string => {
  return env === 'prod' ? '80%' : '102%';
};

const RightTextFlat = styled(TextField)(() => ({
  '& .MuiOutlinedInput-root': {
    borderTopRightRadius: '0px',
    borderBottomRightRadius: '0px',
  },
}));

const LeftFormFlat = styled(FormInputText)(() => ({
  '& .MuiOutlinedInput-root': {
    borderTopLeftRadius: '0px',
    borderBottomLeftRadius: '0px',
  },
}));

export default QuickStartForm;

import DialogContent from '@mui/material/DialogContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import DialogWrapper from './Wrapper';

type Props = {
  title: string;
  open: boolean;
  handleClose: () => void;
  details: MandateDetails;
};

type MandateDetails = {
  currency: string;
  description: string;
  startDate: string;
  endDate: string;
  maxTotalAmount: string;
  maxTxnAmount: string;
  maxTxnCount: number;
  period: string;
  recipientName: string;
  recipientAccountId: string;
  customerAppId: string;
};

export default function MandateDetails({ title, open, handleClose, details }: Props) {
  return (
    <DialogWrapper id="" onClose={handleClose} aria-labelledby="customized-dialog-title" title={title} open={open}>
      <DialogContent dividers>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Recipient Name</TableCell>
              <TableCell align="right">{details.recipientName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Recipient Account ID</TableCell>
              <TableCell align="right">{details.recipientAccountId}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Customer App ID</TableCell>
              <TableCell align="right">{details.customerAppId}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Currency</TableCell>
              <TableCell align="right">{details.currency}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Description</TableCell>
              <TableCell align="right">{details.description}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Start Date</TableCell>
              <TableCell align="right">{details.startDate}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>End Date</TableCell>
              <TableCell align="right">{details.endDate}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Maximum Total Amount</TableCell>
              <TableCell align="right">{details.maxTotalAmount}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Maximum Transaction Count</TableCell>
              <TableCell align="right">{details.maxTxnCount}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Maximum Transaction Amount</TableCell>
              <TableCell align="right">{details.maxTxnAmount}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Period</TableCell>
              <TableCell align="right">{details.period}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </DialogContent>
    </DialogWrapper>
  );
}

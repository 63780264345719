import { SxProps, Theme } from '@mui/material';
import { inputClasses } from '@mui/material/Input';
import { menuItemClasses } from '@mui/material/MenuItem';
import { selectClasses } from '@mui/material/Select';

export interface ToggleProps {
  setLoading: (loading: boolean) => void;
}

export const toggleStyles: Record<string, SxProps<Theme>> = {
  select: {
    [`& .${selectClasses.select}`]: {
      color: 'primary.main',
    },
    [`& .${selectClasses.icon}`]: {
      paddingLeft: '6px',
      color: 'primary.main',
    },
    '&:before': {
      borderColor: 'primary.main',
    },
    [`&:hover:not(.${inputClasses.disabled}):before`]: {
      borderColor: 'primary.dark',
    },
  },
  menuItem: {
    [`.${menuItemClasses.root}`]: {
      color: 'primary.main',
    },
  },
};

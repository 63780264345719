import { useContext, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import { AppContext } from '../contexts/AppContext';
import { isValidJwt } from '../lib/jwt';
import Routes from '../lib/routes';

export default function Home(): JSX.Element {
  const { app } = useContext(AppContext);
  const history = useHistory();
  useEffect(() => {
    const token = app.tokens.get(app.env);
    if (!isValidJwt(token)) {
      history.push(Routes.Login.Base);
    } else {
      history.push(Routes.Overview);
    }
  }, [window.location]);
  return <></>;
}

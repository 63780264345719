import { useContext, useMemo } from 'react';

import axios, { AxiosRequestConfig } from 'axios';
import useSWR, { mutate } from 'swr';

import { AppContext } from '../../../contexts/AppContext';
import useTeam from '../../../hooks/useTeam';

interface CreateQuickStartLinkProps {
  appPath: string;
}

export type LinkedUser = {
  id: string;
  institution_id: string;
  login_identity_id: string;
  status: string;
  user_id: string;
  connection_date: string;
  download_date: string;
  update_date: string;
  application_id: string;
};

export interface QuickStartLink {
  app_path: string;
  customer_app_name: string;
  customer_app_id: string;
  quickstart_url: string;
  quickstart_link_id: string;
}

const fetcher = (key: string, config: AxiosRequestConfig) => axios(config).then((res) => res.data);

const useQuickStartApi = (): {
  data: QuickStartLink[] | undefined;
  isLoading: boolean;
  isError: unknown;
  refetchInfo: () => void;
  createQuickStartLink: (props: CreateQuickStartLinkProps) => Promise<void>;
  env: string;
  isAuthError: boolean;
} => {
  const { app } = useContext(AppContext);
  const { team } = useTeam();
  const { env, tokens } = app;
  const token = tokens.get(env);
  const config: AxiosRequestConfig = useMemo(
    () => ({
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: `https://quickstart.${env}.finverse.net/team/${team.team_id}/info`,
    }),
    [token, env, team.team_id],
  );
  const { data, error } = useSWR(['/info', config], fetcher, { shouldRetryOnError: false });

  const createQuickStartLink = async (form: CreateQuickStartLinkProps) => {
    await axios({
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: `https://quickstart.${env}.finverse.net/create`,
      data: {
        customer_app_name: form.appPath, // explicitly same...
        app_path: form.appPath,
        team_id: team.team_id,
      },
    });
  };

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
    refetchInfo: () => mutate('/info'),
    createQuickStartLink: createQuickStartLink,
    env: env,
    isAuthError: error?.response?.status === 401,
  };
};

export default useQuickStartApi;

export const GetLinkedUsers = (customerAppId: string) => {
  const { app } = useContext(AppContext);
  const { env, tokens } = app;
  const token = tokens.get(env) || '';

  const fetcher = (customerAppId: string, config: AxiosRequestConfig) =>
    axios({
      ...config,
      params: {
        customer_app_id: customerAppId,
      },
    }).then((res) => res.data);
  const config: AxiosRequestConfig = useMemo(
    () => ({
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: `https://quickstart.${env}.finverse.net/users`,
    }),
    [token, env],
  );

  const resp = useSWR<{ linked_users: LinkedUser[] }>([customerAppId, config], fetcher, {
    shouldRetryOnError: false,
  });
  return resp;
};

export type DownloadZip = (customerAppId: string, linkedUserId: string) => Promise<void>;

export const useDownloadZip = () => {
  const { app } = useContext(AppContext);
  const { env, tokens } = app;
  const token = tokens.get(env) || '';

  const downloadZip: DownloadZip = async (customerAppId: string, linkedUserId: string): Promise<void> => {
    const downloadLinkResponse = await axios({
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: `https://quickstart.${env}.finverse.net/download_link/zip`,
      params: {
        customer_app_id: customerAppId,
        linked_user_id: linkedUserId,
      },
    });

    const downloadLink = downloadLinkResponse.data.download_url;

    if (typeof downloadLink === 'string') {
      window.open(downloadLink, '_blank');
    }
  };

  return {
    downloadZip,
  };
};

export type BuildLinkParams = {
  quickstart_link_id: string;
  user_id?: string;
  application_id?: string;
};

export const useBuildLink = (): {
  buildLink: (buildLinkParams: BuildLinkParams) => Promise<string>;
} => {
  const { app } = useContext(AppContext);
  const url = `https://quickstart.${app.env}.finverse.net/quickstart/token`;
  const token = app.tokens.get(app.env);

  const buildLink = async (buildLinkParams: BuildLinkParams) => {
    const resp = await axios.post<{ quickstart_url: string }>(url, buildLinkParams, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    });
    return resp.data.quickstart_url;
  };

  return { buildLink };
};

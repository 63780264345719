export const getAuth0CallbackUri = (env: string): string => {
  const origin = `${window.location.origin}/callback`;
  return `https://developer.${env}.finverse.net/auth/login?redirect_uri=${origin}`;
};

export const getAuth0CallbackUriToConnection = (env: string, connection: string): string => {
  const origin = `${window.location.origin}/callback`;
  return `https://developer.${env}.finverse.net/auth/login?redirect_uri=${origin}&connection=${connection}`;
};

export const getAuth0CallbackUriForSignup = (env: string): string => {
  const origin = `${window.location.origin}/callback`;
  return `https://developer.${env}.finverse.net/auth/login?screen=signup&redirect_uri=${origin}`;
};

export const redirectToAuth0 = (env: string) => {
  window.location.href = getAuth0CallbackUri(env);
};

export const redirectToAuth0ForSignup = (env: string) => {
  window.location.href = getAuth0CallbackUriForSignup(env);
};

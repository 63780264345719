import React from 'react';
import ReactDOM from 'react-dom';

import { green, blue, red, grey } from '@mui/material/colors';
import { listItemButtonClasses } from '@mui/material/ListItemButton';
import { ThemeProvider, Theme, StyledEngineProvider, createTheme, ThemeOptions } from '@mui/material/styles';
import StylesProvider from '@mui/styles/StylesProvider';

import App from './App';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const theme = createTheme(getTheme());

ReactDOM.render(
  <React.StrictMode>
    <StylesProvider injectFirst>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </StyledEngineProvider>
    </StylesProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// The following theme function could be moved to a theme related file. Currently, keeping it here.
export function getTheme(): ThemeOptions {
  const colors = {
    primary: {
      light: blue[400],
      main: blue[500],
      dark: blue[600],
    },
    secondary: {
      light: grey[300],
      main: grey[400],
      dark: grey[500],
    },
    success: {
      light: green[300],
      main: green[400],
      dark: green[500],
    },
    action: {
      main: blue[400],
    },
    error: {
      light: red[600],
      main: red[700],
      dark: red[800],
    },
    background: {
      default: '#f2f5fb',
    },
  };
  return {
    palette: {
      primary: colors.primary,
      secondary: colors.secondary,
      background: colors.background,
      success: colors.success,
      error: colors.error,
      // Used by `getContrastText()` to maximize the contrast between
      // the background and the text.
      contrastThreshold: 3,
      // Used by the functions below to shift a color's luminance by approximately
      // two indexes within its tonal palette.
      // E.g., shift from Red 500 to Red 300 or Red 700.
      tonalOffset: 0.2,
      text: {
        primary: '#000000',
        secondary: '#000000a6',
      },
    },
    typography: {
      fontFamily: 'clarika grotesque',
      h1: {
        fontSize: '2.1rem',
        fontWeight: 600,
        lineHeight: '36px',
      },
      h2: {
        fontSize: '1.8rem',
        fontWeight: 500,
        lineHeight: '32px',
      },
      h3: {
        fontSize: '1.6rem',
        fontWeight: 500,
        lineHeight: '28px',
      },
      h4: {
        fontSize: '1.5rem',
        fontWeight: 400,
        lineHeight: '24px',
      },
      h5: {
        fontSize: '1.25rem',
        fontWeight: 400,
        lineHeight: '22px',
      },
      h6: {
        fontSize: '1.1rem',
        fontWeight: 400,
        lineHeight: '1em',
      },
      subtitle1: {
        fontSize: '1.1em',
      },
      body1: {
        fontSize: '1em',
      },
      body2: {
        fontSize: '0.95em',
      },
    },
    components: {
      MuiButtonBase: {
        defaultProps: {
          // No more ripple in the whole application
          disableRipple: true,
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          MuiTooltip: {
            tooltip: {
              fontSize: '2em',
              top: '-10px',
            },
          },
          body: {
            scrollbarColor: '#6b6b6b #c4c4c4',
            '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
              backgroundColor: '#c4c4c4',
            },
            '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
              borderRadius: 8,
              backgroundColor: '#6b6b6b',
              minHeight: 24,
              border: '3px solid #c4c4c4',
            },
            '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
              backgroundColor: '#959595',
            },
            '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
              backgroundColor: '#959595',
            },
            '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#959595',
            },
            '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
              backgroundColor: '#c4c4c4',
            },
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            '&:hover': {
              backgroundColor: '#E5F5FF',
            },
            [`&.${listItemButtonClasses.selected}`]: {
              backgroundColor: '#E5F5FF',
            },
          },
        },
      },
    },
    zIndex: {
      appBar: 1250,
    },
  };
}

import GroupIcon from '@mui/icons-material/Group';
import LogoutIcon from '@mui/icons-material/Logout';
import { Box, Drawer, List, ListItem, Link as MaterialLink, ListItemIcon } from '@mui/material';

import useQuickStartApi from '../../features/Quickstart/api/useQuickStartApi';
import { DesktopDrawerWidth, getEnv, getHeaderRoutes, styles } from './common';
import HeaderList from './HeaderList';
import TeamToggle from './Toggles/TeamToggle';

interface Props {
  setLoading: (loading: boolean) => void;
}

export default function DesktopDrawer({ setLoading }: Props) {
  const { data: quickStartInfo } = useQuickStartApi();
  let qsAppId: string;
  if (quickStartInfo === undefined || quickStartInfo.length === 0) {
    qsAppId = '';
  } else {
    qsAppId = quickStartInfo[0].customer_app_id;
  }
  const env = getEnv(window.location.hostname);
  const headerRoutes = getHeaderRoutes(env, qsAppId);
  return (
    <Drawer
      variant="permanent"
      sx={{
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: DesktopDrawerWidth,
          border: 'none',
        },
      }}
      open
    >
      <List>
        <ListItem>
          <MaterialLink
            href="https://www.finverse.com"
            sx={{
              mt: 1,
              mb: 1,
              display: 'flex',
              alignContent: 'center',
            }}
          >
            <Box sx={styles.logo} component="img" src={process.env.PUBLIC_URL + '/logo.svg'} alt="finverse logo" />
          </MaterialLink>
        </ListItem>
        {headerRoutes.map((props, idx) => (
          <HeaderList key={`headerList-${idx}`} {...props} />
        ))}
      </List>
      <Box sx={{ flex: 1 }} />
      <List>
        <ListItem>
          <ListItemIcon>
            <GroupIcon />
          </ListItemIcon>
          <TeamToggle setLoading={setLoading} />
        </ListItem>
        <HeaderList key="headerList-logout" route="/logout" buttonText="Logout" Icon={<LogoutIcon />} />
      </List>
    </Drawer>
  );
}

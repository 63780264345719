import { useCallback, useEffect, useState } from 'react';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Button,
  Container,
  Typography,
  Grid,
  Paper,
  Collapse,
  Checkbox,
  FormControlLabel,
  FormControl,
  Box,
  IconButton,
  Tooltip,
} from '@mui/material';
import { useSnackbar } from 'notistack';

import useApp from '../../hooks/useApp';
import useTeam from '../../hooks/useTeam';
import { getErrorMessage } from '../../lib/error';
import { getSDKClient, TeamMember } from '../../lib/sdk';
import { allTeamRoles, BASIC_USER, displayRoles, roleToRoleInfo } from './utils/roles';

const updateRoleOptions = allTeamRoles.filter((role) => role != BASIC_USER);

type TeamMemberCardProps = {
  member: TeamMember;
  updateRolesAction: (developerId: string, roles: string[]) => void;
  removeMemberAction: (developerId: string) => void;
};
function TeamMemberCard({ member, updateRolesAction, removeMemberAction }: TeamMemberCardProps): JSX.Element {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [roles, setRoles] = useState<string[]>(member.roles);

  const handleCheckboxChange = (option: string, checked: boolean) => {
    if (checked) {
      setRoles([...roles, option]);
    } else {
      setRoles(roles.filter((r) => r != option));
    }
  };

  return (
    <Grid container item direction="row">
      <FormControl fullWidth>
        <Paper sx={{ py: 2, px: 4, flex: 1 }}>
          <Grid container spacing={2} direction="row" sx={{ display: { xs: 'block', sm: 'flex' } }}>
            <Grid item sm={5}>
              <Typography variant="subtitle1" component="p" noWrap>
                {member.email ?? member.developer_id}
              </Typography>
            </Grid>
            <Grid item sm={6}>
              <Typography variant="subtitle1" component="p" noWrap>
                {displayRoles(member.roles)}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                style={{ fontWeight: 'bold' }}
                onClick={() => {
                  if (isExpanded) {
                    // i.e. card is currently expanded, and the button is being clicked => we are closing the card
                    // reset member roles if we are closing the collapse
                    setRoles(member.roles);
                  }
                  setIsExpanded(!isExpanded);
                }}
                size="small"
              >
                {
                  // when expanded, show cancel text
                  isExpanded ? 'Cancel' : 'Edit'
                }
              </Button>
            </Grid>
          </Grid>
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            <Grid container>
              <Typography variant="h6" component="h6" my={2} fontWeight="bold">
                Select Roles
              </Typography>
              <Grid container item>
                {updateRoleOptions.map((option) => (
                  <Grid key={option} item xs={12} md={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={roles.includes(option)}
                          value={option}
                          onChange={(_, checked) => handleCheckboxChange(option, checked)}
                        />
                      }
                      label={
                        <Grid container alignItems="center" spacing={0.1} wrap="nowrap" display="flex">
                          <Grid item zeroMinWidth>
                            {roleToRoleInfo[option].name}
                          </Grid>
                          <Grid item>
                            <Tooltip
                              title={roleToRoleInfo[option].info}
                              enterTouchDelay={200}
                              placement="top-start"
                              arrow
                            >
                              <IconButton>
                                <InfoOutlinedIcon fontSize="small" color="primary" />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid container item direction="row" justifyContent="space-between" my={2} spacing={2}>
              <Grid item>
                <Button
                  variant="outlined"
                  color="error"
                  style={{ fontWeight: 'bold' }}
                  size="large"
                  onClick={() => removeMemberAction(member.developer_id)}
                >
                  Remove Member
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ fontWeight: 'bold' }}
                  size="large"
                  onClick={() => updateRolesAction(member.developer_id, roles)}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Collapse>
        </Paper>
      </FormControl>
    </Grid>
  );
}

export default function TeamMembersView(): JSX.Element {
  const { app } = useApp();
  const { team } = useTeam();
  const { enqueueSnackbar } = useSnackbar();
  const [teamMembers, setTeamMembers] = useState<TeamMember[]>([]);
  const [token, setToken] = useState<string>('');
  const [env, setEnv] = useState<string>('');

  const getTeamMembers = useCallback(
    async (env: string, token: string) => {
      const sdk = getSDKClient(env, token);
      try {
        const teamMemberResponse = await sdk.getTeamMembers(team.team_id);
        setTeamMembers(teamMemberResponse.team_members);
      } catch (err) {
        enqueueSnackbar(getErrorMessage(err), { variant: 'error' });
      }
    },
    [team, enqueueSnackbar],
  );

  const removeTeamMember = useCallback(
    async (developerId: string) => {
      const sdk = getSDKClient(env, token);
      try {
        await sdk.removeTeamMember(team.team_id, developerId);
        await getTeamMembers(env, token);
        enqueueSnackbar('Team member removed', { variant: 'success' });
      } catch (err) {
        enqueueSnackbar(getErrorMessage(err), { variant: 'error' });
      }
    },
    [env, token, team, getTeamMembers, enqueueSnackbar],
  );

  const updateMemberRoles = useCallback(
    async (developerId: string, roles: string[]) => {
      const sdk = getSDKClient(env, token);
      try {
        await sdk.updateTeamMemberRoles(team.team_id, developerId, roles);
        await getTeamMembers(env, token);
        enqueueSnackbar('Roles updated', { variant: 'success' });
      } catch (err) {
        enqueueSnackbar(getErrorMessage(err), { variant: 'error' });
      }
    },
    [env, token, team, getTeamMembers, enqueueSnackbar],
  );

  useEffect(() => {
    if (team.team_id === '') {
      return;
    }

    const { tokens, env } = app;
    const currentToken = tokens.get(env) ?? '';
    getTeamMembers(env, currentToken);
    setToken(currentToken);
    setEnv(env);
  }, [app, team, getTeamMembers]);

  return (
    <Container maxWidth="lg" sx={{ flexGrow: 1, p: 4 }}>
      <Grid container spacing={3} sx={{ textAlign: 'left' }} justifyContent="center" alignItems="center">
        <Box>
          <Typography variant="h1" component="h1" align="center" gutterBottom>
            Team Settings
          </Typography>
          <Typography variant="h6" component="h6" align="center" gutterBottom>
            Manage team members, settings and grant permissions
          </Typography>
        </Box>
        <Grid container item direction="row" justifyContent="space-between">
          <Grid item>
            <Typography variant="h2" component="h2" sx={{ marginBottom: 4, fontWeight: 'bold' }}>
              {team.team_name}
            </Typography>
          </Grid>
          {/*
          <Grid item>
            <Button variant="contained" color="primary" href={Teams.AddMember} sx={{ fontWeight: 'bold' }}>
              Add Members
            </Button>
          </Grid>
          */}
        </Grid>
        <Grid container item direction="row" spacing={1} sx={{ display: { xs: 'none', sm: 'flex' }, mx: 2 }}>
          <Grid item xs={6} sm={5}>
            <Typography variant="h5" component="h5" sx={{ fontWeight: 'bold' }} noWrap>
              Team member
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6}>
            <Typography variant="h5" component="h5" sx={{ fontWeight: 'bold' }}>
              Roles
            </Typography>
          </Grid>
        </Grid>
        {teamMembers.map((member) => (
          <TeamMemberCard
            key={member.developer_id}
            member={member}
            updateRolesAction={updateMemberRoles}
            removeMemberAction={removeTeamMember}
          />
        ))}
      </Grid>
    </Container>
  );
}

// although called "View Statement", its more like the page
// to set filters and then download the balance statement
import { ReactElement, useCallback, useState } from 'react';

import {
  Stack,
  TextFieldProps,
  Box,
  TextField,
  outlinedInputClasses,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  Button,
  SelectChangeEvent,
  Theme,
  useTheme,
  Grid,
  Typography,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useSnackbar } from 'notistack';

import DateRangePicker, { DateRangeValues, NullableDate } from '../../components/DateRangePicker';
import { getErrorMessage } from '../../lib/error';
import { useDownloadBalanceStatement } from '../../lib/useDeveloperApi';

const SUPPORTED_CURRENCIES = ['HKD', 'CNY', 'PHP', 'SGD', 'USD'];
const getStyles = (name: string, currencies: string[], theme: Theme) => {
  return {
    fontWeight:
      currencies.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
};

type ViewStatementProps = {
  customerAppId: string;
};

export const ViewStatement = (props: ViewStatementProps): ReactElement => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [dateRangeValue, setDateRangeValue] = useState<DateRangeValues>([null, null]);

  // this function will set the current date time so the UTC value for date string will be the same as the locale time
  // we do this by adding the UTC offset to the date (local), eg 8am in HKT -> 12am in UTC same date.
  // by default the DatePicker component returns the date time as midnight which when converted to UTC will return yesterday's date
  const setDateWithOffset = (newValue: Date) => {
    newValue.getTimezoneOffset(); // get the UTC offset
    newValue.setHours(0); // set the hour to 0 so adding offset will always be the same
    newValue.setMinutes(-newValue.getTimezoneOffset()); // add the offset so we get the local time with UTC offset
  };

  const [currencies, setCurrencies] = useState<string[]>([]);
  const handleCurrenciesChange = (event: SelectChangeEvent<typeof SUPPORTED_CURRENCIES>) => {
    const {
      target: { value },
    } = event;
    setCurrencies(typeof value === 'string' ? value.split(',') : value);
  };

  const downloadBalanceStatementHelper = useDownloadBalanceStatement();
  const downloadBalanceStatemenent = useCallback(async () => {
    try {
      await downloadBalanceStatementHelper(props.customerAppId, {
        date_from: dateRangeValue[0] !== null ? dateRangeValue[0].toISOString().slice(0, 10) : undefined,
        date_to: dateRangeValue[1] !== null ? dateRangeValue[1].toISOString().slice(0, 10) : undefined,
        currencies: currencies,
      });
      enqueueSnackbar('Downloaded successfully', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: 'error' });
    }
  }, [dateRangeValue, currencies, downloadBalanceStatementHelper, enqueueSnackbar, props.customerAppId]);

  const filterBar = () => {
    return (
      <Stack direction={{ xs: 'column', sm: 'column' }} alignItems="flex-start" spacing={2}>
        <DateRangePicker
          values={dateRangeValue}
          views={['day']}
          inputFormat="yyyy-MM-dd"
          onStartDateChange={(newValue: NullableDate) => {
            if (newValue !== null) {
              setDateWithOffset(newValue);
            }
            setDateRangeValue([newValue, dateRangeValue[1]]);
          }}
          onEndDateChange={(newValue: NullableDate) => {
            if (newValue !== null) {
              setDateWithOffset(newValue);
            }
            setDateRangeValue([dateRangeValue[0], newValue]);
          }}
          renderInput={(params: TextFieldProps) => (
            <Box sx={{ width: 200 }}>
              <TextField
                fullWidth
                {...params}
                sx={{
                  [`& .${outlinedInputClasses.root}:hover > fieldset`]: { borderColor: 'primary.dark' },
                }}
              />
            </Box>
          )}
        />
        <FormControl sx={{ width: 200 }}>
          <InputLabel id="select-currencies-label">Currency</InputLabel>
          <Select
            labelId="select-currencies-label"
            id="select-currencies"
            multiple
            value={currencies}
            fullWidth
            onChange={handleCurrenciesChange}
            renderValue={(selected) => selected.join(', ')}
            label="Status"
          >
            {SUPPORTED_CURRENCIES.map((s) => (
              <MenuItem sx={{ fontSize: '0.8em' }} key={s} value={s} style={getStyles(s, currencies, theme)}>
                <Checkbox checked={currencies.includes(s)} />
                {s}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel id="statement-type">Statement Type</FormLabel>
          <RadioGroup defaultValue="csv" name="statement-type">
            <FormControlLabel value="csv" control={<Radio />} label="CSV" />
          </RadioGroup>
        </FormControl>
        <Button
          sx={{ width: 400, height: 50 }}
          variant="contained"
          onClick={() => {
            downloadBalanceStatemenent();
          }}
        >
          Download Statement
        </Button>
      </Stack>
    );
  };
  return (
    <>
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
        sx={{
          marginBottom: '1em',
          marginTop: '0.5em',
        }}
      >
        <Grid item>
          <Typography variant="h4" component="h4">
            Statement
          </Typography>
        </Grid>
      </Grid>

      {filterBar()}
    </>
  );
};

import axios, { AxiosResponse } from 'axios';

export function getSDKClient(env: string, token: string): FinverseSDKClient {
  return new FinverseSDKClient(env, token);
}

// Finverse Developer SDK
export class FinverseSDKClient {
  private apiHost: string;
  constructor(env: string, private token: string) {
    this.apiHost = `https://developer.${env}.finverse.net`;
    this.token = token;
  }

  async registerDeveloper(developer: RegisterDeveloperParams) {
    const url = `${this.apiHost}/developer`;
    const resp = await postData(
      url,
      { 'content-type': contentType.json, Authorization: `Bearer ${this.token}` },
      developer,
    );
    return resp.data === '' ? null : resp.data;
  }

  async resendVerificationEmail() {
    const url = `${this.apiHost}/auth/resend`;
    const resp = await postData(url, { 'content-type': contentType.json, Authorization: `Bearer ${this.token}` });
    return resp.data === '' ? null : resp.data;
  }

  async getInstitutions(): Promise<Institution[]> {
    const url = `${this.apiHost}/institutions`;
    const resp = await getData(url, { 'content-type': contentType.json, Authorization: `Bearer ${this.token}` });
    return resp.data;
  }

  async getCustomerInfo(teamId: string): Promise<CustomerInfo | null> {
    const url = `${this.apiHost}/team/${teamId}/customer/info`;
    const resp = await getData(url, { 'content-type': contentType.json, Authorization: `Bearer ${this.token}` });
    return resp.data === '' ? null : resp.data;
  }

  async getCustomerSecret(customerAppId: string): Promise<CustomerSecret[] | null> {
    const url = `${this.apiHost}/customer/${customerAppId}/secret`;
    const resp = await getData(url, { 'content-type': contentType.json, Authorization: `Bearer ${this.token}` });
    return resp.data === '' ? null : resp.data.secrets;
  }

  async refreshClientSecret(customerAppId: string): Promise<CustomerInfo | null> {
    const url = `${this.apiHost}/customer/${customerAppId}/secret`;
    const resp = await postData(url, { 'content-type': contentType.json, Authorization: `Bearer ${this.token}` }, {});
    return resp.data;
  }

  async createCustomer(customer: CreateCustomerParams): Promise<CustomerInfo> {
    if (customer.redirect_uris instanceof Array && customer.redirect_uris.length === 0) {
      customer.redirect_uris = [defaultRedirectUri];
    }
    const url = `${this.apiHost}/customer`;
    const resp = await postData(
      url,
      { 'content-type': contentType.json, Authorization: `Bearer ${this.token}` },
      customer,
    );
    return resp.data;
  }

  async updateCustomer(customerAppId: string, updatedInfo: UpdateCustomerParams) {
    const url = `${this.apiHost}/customer/${customerAppId}`;
    const resp = await postData(
      url,
      { 'content-type': contentType.json, Authorization: `Bearer ${this.token}` },
      updatedInfo,
    );

    return resp.data;
  }

  async getCustomizations(customerAppId: string): Promise<CustomizationData[]> {
    const url = `${this.apiHost}/customer/${customerAppId}/customizations`;
    const resp = await getData(url, { Authorization: `Bearer ${this.token}` });
    return resp.data;
  }

  async createCustomization(
    customerAppId: string,
    newCustomization: NewCustomization,
  ): Promise<CreateCustomizationResponse> {
    const url = `${this.apiHost}/customer/${customerAppId}/customizations`;
    const resp = await postData(url, { Authorization: `Bearer ${this.token}` }, newCustomization);
    return resp.data;
  }

  async updateCustomization(
    customerAppId: string,
    customizationId: string,
    updatedCustomization: UpdatedCustomization,
  ): Promise<void> {
    const url = `${this.apiHost}/customer/${customerAppId}/customizations/${customizationId}`;
    await postData(url, { Authorization: `Bearer ${this.token}` }, updatedCustomization);
    return;
  }

  async createTeam(team_name: string): Promise<TeamResponse> {
    const url = `${this.apiHost}/team`;
    const resp = await postData(url, { Authorization: `Bearer ${this.token}` }, { team_name });
    return resp.data;
  }

  async getTeams(): Promise<TeamResponse[]> {
    const url = `${this.apiHost}/team`;
    const resp = await getData(url, { Authorization: `Bearer ${this.token}` });
    return resp.data;
  }

  async updateTeam(teamId: string, team_name: string) {
    const url = `${this.apiHost}/team/${teamId}`;
    await postData(url, { Authorization: `Bearer ${this.token}` }, { team_name });
    return;
  }

  async leaveTeam(teamId: string) {
    const url = `${this.apiHost}/team/${teamId}/leave`;
    await postData(url, { Authorization: `Bearer ${this.token}` });
    return;
  }

  async getTeamMembers(teamId: string): Promise<GetTeamMemberResponse> {
    const url = `${this.apiHost}/team/${teamId}/members`;
    const resp = await getData(url, { Authorization: `Bearer ${this.token}` });
    return resp.data;
  }

  async addTeamMembers(teamId: string, email: string) {
    const url = `${this.apiHost}/team/${teamId}/members`;
    await postData(url, { Authorization: `Bearer ${this.token}` }, { email });
    return;
  }

  async removeTeamMember(teamId: string, developerId: string) {
    const url = `${this.apiHost}/team/${teamId}/members/${developerId}`;
    await axios.delete(url, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
    return;
  }

  async updateTeamMemberRoles(teamId: string, developerId: string, roles: string[]) {
    const url = `${this.apiHost}/team/${teamId}/role`;
    await postData(
      url,
      { Authorization: `Bearer ${this.token}` },
      {
        developer_id: developerId,
        roles: roles,
      },
    );
  }

  async connectToXero(teamId: string, live: boolean): Promise<IntegrationConnectionResponse> {
    const url = `${this.apiHost}/connect/xero`;
    const response = await postData(
      url,
      { Authorization: `Bearer ${this.token}` },
      {
        team_id: teamId,
        live: live,
      },
    );
    return response.data;
  }

  async disconnectXero(customerAppId: string): Promise<void> {
    const url = `${this.apiHost}/customer/${customerAppId}/disconnect/xero`;
    await postData(url, { Authorization: `Bearer ${this.token}` });
  }

  async getXeroAccountConnections(xeroConnectionSessionId: string): Promise<XeroConnection[]> {
    const url = `${this.apiHost}/connect/xero/connections?xero_connection_session_id=${xeroConnectionSessionId}`;
    const response = await getData(url, { Authorization: `Bearer ${this.token}` });
    return response.data;
  }

  // TODO: We might want to generalize this fn when we implement account_type filter as well
  async getXeroConnectionExpenseAccounts(xeroConnectionSessionId: string, tenantId: string): Promise<XeroAccount[]> {
    const url = `${this.apiHost}/connect/xero/${tenantId}/accounts?xero_connection_session_id=${xeroConnectionSessionId}&account_class_type=EXPENSE`;
    const response = await getData(url, { Authorization: `Bearer ${this.token}` });
    return response.data;
  }

  async getXeroConnectionBankAccounts(xeroConnectionSessionId: string, tenantId: string): Promise<XeroAccount[]> {
    const url = `${this.apiHost}/connect/xero/${tenantId}/accounts?xero_connection_session_id=${xeroConnectionSessionId}&account_type=BANK`;
    const response = await getData(url, { Authorization: `Bearer ${this.token}` });
    return response.data;
  }

  async confirmXeroSelections(
    teamId: string,
    xeroConnectionSessionId: string,
    tenantId: string,
    feeAccountId: string,
    payoutAccountId: string,
    bankDetails: XeroBankDetails,
    customerAppId?: string,
  ) {
    const url = `${this.apiHost}/connect/xero/confirm`;
    await postData(
      url,
      { Authorization: `Bearer ${this.token}` },
      {
        xero_connection_session_id: xeroConnectionSessionId,
        team_id: teamId,
        tenant_id: tenantId,
        fees_account_id: feeAccountId,
        payout_account_id: payoutAccountId,
        bank_details: bankDetails,
        customer_app_id: customerAppId,
      },
    );
  }

  async getXeroConnectionStatus(customerAppId: string): Promise<XeroConnectionResponse> {
    const url = `${this.apiHost}/customer/${customerAppId}/xero`;
    const res = await getData(url, { Authorization: `Bearer ${this.token}` });

    return res.data;
  }
}

// Helper functions to either get or post data. These are private functions so coverage should be ignored.
/* istanbul ignore next */
async function postData<T>(url: string, headers = {}, data = {}): Promise<AxiosResponse> {
  const response = await axios.post<T>(url, data, { headers });
  return response;
}

/* istanbul ignore next */
async function getData(url: string, headers = {}): Promise<AxiosResponse> {
  const response = await axios.get(url, { headers });
  return response;
}

export const contentType = {
  json: 'application/json',
  form: 'application/x-www-form-urlencoded',
};

export const defaultRedirectUri = 'https://developer.prod.finverse.net/sink';

export enum IntegrationStatus {
  CONNECTED = 'CONNECTED',
  NOT_CONNECTED = 'NOT_CONNECTED',
}

export type Institution = {
  institution_id: string;
  institution_name: string;
  products_supported: string[];
  status: string;
};

export type CustomerInfo = {
  client_id: string;
  client_secret?: string;
  prefixes: string[]; // client_secret_prefixes
  customer_app_id: string;
  email: string;
  name: string;
  redirect_uris: string[];
  webhook_uris: string[];
  payment_webhook_uris: string[];
  authentication_webhook_uris: string[] | null;
  real_enabled: boolean;
};

export type XeroDetails = {
  short_code: string;
  tenant_id: string;
  tenant_name: string;
  payment_account: XeroAccount;
  fee_account: XeroAccount;
  payout_account: XeroAccount;
};

export type XeroConnectionResponse = {
  xero_status: IntegrationStatus;
  xero_details: XeroDetails;
};

type CustomerAppParams = {
  name: string;
  email: string;
  redirect_uris: string[];
  webhook_uris: string[];
};

export type CreateCustomerParams = CustomerAppParams & {
  team_id: string;
};

export type UpdateCustomerParams = CustomerAppParams;

export type RegisterDeveloperParams = {
  first_name: string;
  last_name: string;
  email: string;
  country: string;
  organization?: string;
  consent_to_emails: boolean;
};

export type Developer = {
  developer_id: string;
  verified: boolean;
};

export type CustomerSecret = {
  client_secret_prefix: string;
  secret_version: number;
};

export type NewCustomization = {
  customization_name: string;
  display_name: string;
  logo_id: string;
};

export type UpdatedCustomization = NewCustomization;

export type CustomizationData = NewCustomization & {
  customization_id: string;
};

export type CreateCustomizationResponse = {
  customization_id: string;
};

export type TeamResponse = {
  created_at: string;
  team_id: string;
  team_name: string;
};
export type TeamMember = {
  developer_id: string;
  email: string;
  roles: string[];
};

export type GetTeamMemberResponse = {
  team_id: string;
  team_members: TeamMember[];
};

export type Error400Model = {
  error: {
    code: number;
    message: string;
    name: string;
  };
};

export type IntegrationConnectionResponse = {
  oauth_uri: string;
};

export type XeroConnection = {
  name: string;
  tenant_id: string;
};

export type XeroAccount = {
  account_class_type: string;
  account_id: string;
  account_code: string;
  name: string;
  bank_account_number?: string; // Only for BANK
  masked_account_number?: string; // Only for BANK
};

export type XeroBankDetails = {
  institution_id: string;
  account_number: string;
  account_holder_name: string;
  currency: string;
};

export type ErrBodyModelV2 = {
  error: {
    details: string;
    /**
     * @example CREDENTIALS_INVALID
     */
    error_code: string;
    message: string;
    request_id: string;
    /**
     * @example API_ERROR
     */
    type: string;
  };
};

import { useContext, useState } from 'react';

import InfoIcon from '@mui/icons-material/Info';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

import { AppContext } from '../../contexts/AppContext';
import useTeam from '../../hooks/useTeam';
import { getErrorMessage } from '../../lib/error';
import { CustomerInfo, CreateCustomerParams, getSDKClient, defaultRedirectUri } from '../../lib/sdk';
import ClientInfo from './ClientInfo';
import { SubMessage, StyledErrorIcon } from './Styled';

const FvToolTip = styled(Tooltip)({
  marginLeft: '5px',
  verticalAlign: 'sub',
  fontSize: '0.85em',
});

/*
 * This component is used for displaying the create customer form
 */
export default function CreateCustomer(): JSX.Element {
  const { app } = useContext(AppContext);
  const { team } = useTeam();
  const { enqueueSnackbar } = useSnackbar();
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [customerInfo, setCustomerInfo] = useState<CustomerInfo | null>(null);

  const token = app.tokens.get(app.env);

  const onSubmit = async (customer: CreateCustomerParams) => {
    try {
      // set the customer team id to be current team id
      customer.team_id = team.team_id;
      if (customer.redirect_uris === undefined) {
        customer.redirect_uris = [];
      }
      const redirectURIs = customer.redirect_uris
        .toString()
        .split(',')
        .filter((uri) => uri?.length > 0);
      customer.redirect_uris = redirectURIs;

      if (customer.webhook_uris === undefined) {
        customer.webhook_uris = [];
      }
      const webhookURIs = customer.webhook_uris
        .toString()
        .split(',')
        .filter((uri) => uri.length > 0);
      customer.webhook_uris = webhookURIs;
      const c = await getSDKClient(app.env, String(token)).createCustomer(customer);
      enqueueSnackbar('Created customer app.', { variant: 'success' });
      setCustomerInfo(c);
      setSubmitted(true);
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: 'error' });
    }
  };

  const initialName = 'example-prod';

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<CreateCustomerParams>();

  if (submitted && customerInfo !== null) {
    return (
      <div>
        <ClientInfo customerInfo={customerInfo} />
      </div>
    );
  }

  return (
    <Container sx={{ flexGrow: 1, textAlign: 'center' }}>
      <Typography variant="h1" component="h1" sx={{ marginBottom: 4 }}>
        Register a new application
      </Typography>
      <Typography
        variant="h5"
        component="h5"
        sx={{
          marginBottom: 4,
        }}
      >
        {"Use the following settings to register a new application and generate API keys to access Finverse's API."}
      </Typography>

      <Paper sx={{ p: 3 }}>
        <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
            sx={{ textAlign: 'left' }}
          >
            <Grid container item xs={12} sm={12}>
              <Grid item xs={12} sm={6}>
                <Typography> App Name </Typography>
                <SubMessage variant="body2">
                  Shown to users in Finverse Link (<i>&quot;[App Name] will link your bank&quot;</i> )
                </SubMessage>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <OutlinedInput
                    id="name"
                    endAdornment={<InputAdornment position="end">{errors.name && <StyledErrorIcon />}</InputAdornment>}
                    {...register('name', { required: true, minLength: 3 })}
                    fullWidth
                    placeholder={initialName}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Divider />
            </Grid>

            <Grid container item xs={12} sm={12}>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">
                  Callback URLs
                  <FvToolTip title="Used to receive the end-user's authorization code after successfully linking an institution. If left blank, we will generate a temporary URL which you can edit later.">
                    <InfoIcon color="primary" fontSize="small" />
                  </FvToolTip>
                </Typography>
                <SubMessage variant="body2">Optional, comma-separated list of URLs</SubMessage>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <OutlinedInput
                    id="redirect_uris"
                    endAdornment={
                      <InputAdornment position="end">{errors.redirect_uris && <StyledErrorIcon />}</InputAdornment>
                    }
                    {...register('redirect_uris')}
                    fullWidth
                    rows={3}
                    multiline
                    placeholder={defaultRedirectUri}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Divider />
            </Grid>

            <Grid container item xs={12} sm={12}>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">
                  Webhook URLs
                  <FvToolTip title="Set up your webhook endpoint to receive authentication and data retrieval events. See docs.finverse.com to learn more">
                    <InfoIcon color="primary" fontSize="small" />
                  </FvToolTip>
                </Typography>
                <SubMessage variant="body2">Optional, comma-separated list of URLs</SubMessage>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <OutlinedInput
                    id="webhook_uris"
                    endAdornment={
                      <InputAdornment position="end">{errors.webhook_uris && <StyledErrorIcon />}</InputAdornment>
                    }
                    {...register('webhook_uris')}
                    fullWidth
                    rows={3}
                    multiline
                    placeholder="https://"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Button
            sx={{
              marginTop: 2,
            }}
            variant="contained"
            color="primary"
            type="submit"
          >
            Generate API keys
          </Button>
        </form>
      </Paper>
    </Container>
  );
}

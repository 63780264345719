import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import ProgressCarousel from '../../../components/ProgressCarousel';
import useResponsive from '../../../hooks/useResponsive';
import { BulletOne, BulletTwo, BulletThree } from '../../../icons/BulletIcons';
import QuickStartLinkBar from './QuickStartLinkBar';

const BulletHeader = styled(Typography)({
  fontWeight: 600,
});

const GuideInfo = [
  {
    header: 'Create a Link',
    subHeader: 'Create a Quickstart Link for your end-users to connect their financial accounts.',
    imageOptions: {
      src: `${process.env.PUBLIC_URL}/quick-start/create_link.png`,
      alt: 'Create Link Image ',
    },
    Icon: <BulletOne />,
  },
  {
    header: 'Share the Link',
    subHeader:
      'Embed your Quickstart Link in your app, website, or send it to your end-users. This link allows end-users to launch Finverse’s UI and provide you their financial data within minutes.',
    imageOptions: {
      src: `${process.env.PUBLIC_URL}/quick-start/share_link.png`,
      alt: 'Share Link Image ',
    },
    Icon: <BulletTwo />,
  },
  {
    header: 'View Linked Users',
    subHeader: 'Access your list of linked end-users and view their data.',
    imageOptions: {
      src: `${process.env.PUBLIC_URL}/quick-start/view_data.png`,
      alt: 'View Data Image ',
    },
    Icon: <BulletThree />,
  },
];

const BulletPoints = (): JSX.Element => {
  const { isDesktop } = useResponsive();
  return (
    <List component="nav">
      {GuideInfo.map(({ header, subHeader, imageOptions, Icon }, idx) => (
        <div key={idx}>
          <ListItem alignItems="flex-start">
            {isDesktop && <ListItemIcon sx={{ mt: 2 }}>{Icon}</ListItemIcon>}
            <Box sx={[{ display: 'flex', flexDirection: 'column' }, !isDesktop && { placeItems: 'center' }]}>
              <ListItemText
                primary={<BulletHeader>{header}</BulletHeader>}
                primaryTypographyProps={{ variant: 'h3' }}
                sx={{ mb: 1 }}
              />
              <ListItemText secondary={subHeader} />
              {!isDesktop && (
                <ListItemIcon>
                  <Box
                    component="img"
                    sx={{ maxWidth: '100%', height: 'auto', objectFit: 'contain' }}
                    {...imageOptions}
                  />
                </ListItemIcon>
              )}
            </Box>
          </ListItem>
        </div>
      ))}
    </List>
  );
};

const Carousel = () => (
  <ProgressCarousel>
    {GuideInfo.map(({ imageOptions }, index) => (
      <Box
        key={index}
        component="img"
        sx={{ maxWidth: '100%', height: 'auto', objectFit: 'contain' }}
        {...imageOptions}
      />
    ))}
  </ProgressCarousel>
);

export default function QuickStartGuide() {
  const { isDesktop } = useResponsive();
  return (
    <Container sx={{ textAlign: 'center' }}>
      <Typography variant="h1" component="h1" sx={{ marginBottom: 4 }}>
        How to use Quickstart Links
      </Typography>
      <Typography variant="h5" component="h5" sx={{ marginTop: 2, marginBottom: 4 }}>
        Access permissioned end-user financial data in minutes, without a single line of code
      </Typography>
      <Paper sx={{ width: 1, pt: 0, p: 1 }}>
        <Grid container spacing={3}>
          <Grid item md={12} lg={4} sx={{ p: 0, display: 'flex', placeItems: 'center' }}>
            <BulletPoints />
          </Grid>
          {isDesktop && (
            <Grid item md={12} lg={8} sx={{ display: 'flex', placeContent: 'center', placeItems: 'center' }}>
              <Carousel />
            </Grid>
          )}
        </Grid>
      </Paper>
      <QuickStartLinkBar />
    </Container>
  );
}

import { ReactElement } from 'react';

import { Button, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Link } from 'react-router-dom';

import LoadingOverlay from '../../../../components/LoadingOverlay';
import useApp from '../../../../hooks/useApp';
import useResponsive from '../../../../hooks/useResponsive';
import { isValidJwt } from '../../../../lib/jwt';
import { QuickStart } from '../../../../lib/routes';
import useQuickStartApi from '../../api/useQuickStartApi';
import QuickStartForm from '../QuickStartForm';
import RequestProductAccess from '../RequestProductAccess';
import LinkCard from './LinkCard';

const isMissingQuickStartError = (error: any) => {
  const responseData = error?.response?.data;
  return responseData?.error?.code === 40004;
};

const QuickStartSettings = (): ReactElement => {
  const { data, isLoading, isError, isAuthError } = useQuickStartApi();
  const { isDesktop } = useResponsive();

  const { app } = useApp();
  const { tokens, env } = app;
  const currentToken = tokens.get(env) ?? '';

  if (isLoading) {
    return <LoadingOverlay />;
  }

  if (isMissingQuickStartError(isError)) {
    return <QuickStartForm />;
  }

  if (isValidJwt(currentToken) && isAuthError) {
    return <RequestProductAccess />;
  }

  return (
    <Container maxWidth={isDesktop ? 'md' : 'xl'} sx={{ p: { xs: 0, lg: null } }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Typography
          variant="h1"
          component="h1"
          sx={{
            textAlign: 'left',
          }}
        >
          Quickstart Settings
        </Typography>
        <Box sx={{ display: 'flex', placeItems: 'center' }}>
          <Button
            size={isDesktop ? 'medium' : 'small'}
            component={Link}
            to={QuickStart.New}
            variant="contained"
            color="primary"
          >
            Create Link
          </Button>
        </Box>
      </Box>
      {data?.map((d, i) => (
        <Box sx={{ marginBottom: 2 }} key={i}>
          <LinkCard link={d} />
        </Box>
      ))}
    </Container>
  );
};

export default QuickStartSettings;

import { useEffect, useContext, useState } from 'react';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useLocation } from 'react-router-dom';

import BulletPoints from '../../components/BulletPoints';
import { AppContext } from '../../contexts/AppContext';
import RegisterDeveloper from './RegisterDeveloper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }),
);

export default function Register(): JSX.Element {
  const { app, setApp } = useContext(AppContext);
  const classes = useStyles();
  const query = new URLSearchParams(useLocation().search);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const accessToken = query.get('access_token');

  useEffect(() => {
    if (accessToken !== null) {
      const currEnv = app.env;
      const tokens = app.tokens;
      tokens.set(currEnv, accessToken);
      setApp({ ...app, tokens });
    }
  }, [window.location]);
  return (
    <Grid container spacing={3}>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid item xs={12} sm={6}>
        <RegisterDeveloper setIsLoading={setIsLoading} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <BulletPoints
          header="Sign-up as a developer to:"
          items={['Get instant sandbox access and API keys', 'Connect real banks', 'Upgrade to production access']}
        />
      </Grid>
    </Grid>
  );
}

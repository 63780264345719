import { useCallback, useState, useEffect } from 'react';

import { Box, Card, Container, Grid, List, ListItem, ListItemIcon, Stack, Typography, Link } from '@mui/material';
import { useSnackbar } from 'notistack';

import IntegrationCard, { ConnectionStatus } from '../../../components/Integrations/IntegrationCard';
import useApp from '../../../hooks/useApp';
import { BulletOne, BulletFour, BulletTwo, BulletThree } from '../../../icons/BulletIcons';
import { getErrorMessage } from '../../../lib/error';
import Routes from '../../../lib/routes';
import { getSDKClient, XeroDetails } from '../../../lib/sdk';

interface XeroConnectedProps {
  xeroDetails: XeroDetails;
  customerAppId: string;
  isLive?: boolean;
}

const maskedAccountNumberText = (maskedNum?: string): string => {
  if (maskedNum === undefined) {
    return '';
  }

  return `(${maskedNum})`;
};

export default function XeroConnected({ xeroDetails, customerAppId, isLive }: XeroConnectedProps) {
  const { app } = useApp();
  const { enqueueSnackbar } = useSnackbar();
  const [payoutAccount, setPayoutAccount] = useState<string>('');
  const [feeAccount, setFeeAccount] = useState<string>('');

  useEffect(() => {
    setPayoutAccount(
      xeroDetails.payout_account.account_code === undefined
        ? xeroDetails.payout_account.name // only use name if code is missing
        : `${xeroDetails.payout_account.account_code} - ${xeroDetails.payout_account.name}`,
    );
    setFeeAccount(
      xeroDetails.fee_account.account_code === undefined
        ? xeroDetails.fee_account.name // only use name if code is missing
        : `${xeroDetails.fee_account.account_code} - ${xeroDetails.fee_account.name}`,
    );
  }, [xeroDetails]);

  const disconnectXero = useCallback(
    (customerAppId: string) => {
      const { tokens, env } = app;
      const token = tokens.get(env) ?? '';

      const leave = async () => {
        const sdk = getSDKClient(env, token);
        try {
          await sdk.disconnectXero(customerAppId);
          enqueueSnackbar('Disconnected xero!', { variant: 'success' });
          // refresh page to home, to show "Connect Xero" again
          window.location.href = Routes.Integrations.Xero;
        } catch (err) {
          const errMessage = getErrorMessage(err);
          enqueueSnackbar(errMessage, { variant: 'error' });
        }
      };

      leave();
    },
    [app, enqueueSnackbar],
  );

  return (
    <Container maxWidth="lg" sx={{ flexGrow: 1, p: 4 }}>
      <Grid container justifyContent="center" alignItems="center" sx={{ textAlign: 'left' }} spacing={3}>
        <Grid container item direction="row" sx={{ marginBottom: 4 }}>
          <Grid item>
            <Typography variant="h2" component="h2" sx={{ fontWeight: 'bold' }}>
              Integrate with Xero
            </Typography>
          </Grid>
        </Grid>
        <Grid container item display="flex">
          <IntegrationCard
            logoSrc="/integrations/xero/xero_logo.svg"
            name="Xero"
            description="Automate payment collection and reconciliation by enabling customers to make instant bank payments from any Xero invoice"
            integrationStatus={ConnectionStatus.CONNECTED}
            isLive={isLive}
            onDisconnectAction={() => disconnectXero(customerAppId)}
          />
        </Grid>
        <Grid container item display="flex">
          <Card sx={{ flex: 1, p: 4 }}>
            <Typography fontWeight="bold" gutterBottom variant="h5" marginBottom={2}>
              Connected organization
            </Typography>
            <Typography variant="h6">{xeroDetails.tenant_name}</Typography>
          </Card>
        </Grid>
        <Grid container item display="flex">
          <Card sx={{ flex: 1, p: 4 }}>
            <Typography fontWeight="bold" variant="h5">
              Fees account
            </Typography>
            <Typography variant="subtitle2" marginBottom={2} gutterBottom>
              Xero expense account where fees charged by Finverse will be recorded
            </Typography>
            <Typography variant="h6">{feeAccount}</Typography>
          </Card>
        </Grid>
        <Grid container item display="flex">
          <Card sx={{ flex: 1, p: 4 }}>
            <Typography fontWeight="bold" variant="h5">
              Payout account
            </Typography>
            <Typography variant="subtitle2" marginBottom={2} gutterBottom>
              Xero bank account to record your payouts
            </Typography>
            <Typography variant="h6">
              {`${payoutAccount} ${maskedAccountNumberText(xeroDetails.payout_account.masked_account_number)}`}
            </Typography>
          </Card>
        </Grid>
        <Grid container item display="flex">
          <Card sx={{ flex: 1, p: 4 }}>
            <Typography fontWeight="bold" gutterBottom variant="h5" marginBottom={2}>
              Finverse UI customizations
            </Typography>
            <Typography variant="h6">
              Customize Finverse UI with your company name and logo:{' '}
              <Link href="/customizations">view customizations</Link>
            </Typography>
          </Card>
        </Grid>
        <Grid container item display="flex">
          <GetStartedSegment />
        </Grid>
        <Grid container item display="flex">
          <XeroInvoicingBestPracticeSegment />
        </Grid>
        <Grid container item display="flex">
          <Card sx={{ flex: 1, p: 4 }}>
            <Typography fontWeight="bold" variant="h5">
              Connection details
            </Typography>
            <Typography variant="subtitle2" gutterBottom marginBottom={2}>
              Please provide these IDs to Finverse Customer Support during troubleshooting
            </Typography>
            <Stack marginY={1}>
              <Typography variant="subtitle2" fontWeight="bold">
                Finverse Customer App ID:
              </Typography>
              <Typography variant="subtitle2">{customerAppId}</Typography>
            </Stack>
            <Stack>
              <Typography variant="subtitle2" fontWeight="bold">
                Xero Tenant ID:
              </Typography>
              <Typography variant="subtitle2">{xeroDetails.tenant_id}</Typography>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

const GetStartedSegment = () => {
  return (
    <Card sx={{ flex: 1, p: 3 }}>
      <Typography variant="h5" component="h5" fontWeight="bold" gutterBottom paddingLeft={2}>
        Get Started
      </Typography>
      <List>
        <ListItem alignItems="flex-start" key={1}>
          <ListItemIcon sx={{ marginTop: 0 }}>
            <BulletOne />
          </ListItemIcon>
          <Box>
            <Typography variant="h6" fontWeight="bold" gutterBottom>
              Create a test invoice and send it to yourself
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              <ul>
                <li>
                  Go to <a href="https://invoicing.xero.com">https://invoicing.xero.com</a>
                </li>
                <li>Add your email as the invoice recipient</li>
                <li>Make sure Finverse is selected under {'"Online payments"'}</li>
                <li>Set currency to HKD</li>
                <li>Select your desired branding theme (invoice template)</li>
                <li>Click {'"Approve & email"'} to send the invoice</li>
              </ul>
            </Typography>
            <Grid container direction="row" spacing={2}>
              <Grid item>
                <Box
                  component="img"
                  src="/integrations/xero/xero_test_invoice.png"
                  alt="Xero Test Invoice"
                  sx={{ maxWidth: '600px', width: '100%', height: 'auto', border: '1px #22222280 solid' }}
                />
              </Grid>
            </Grid>
          </Box>
        </ListItem>
        <ListItem alignItems="flex-start" key={2}>
          <ListItemIcon sx={{ marginTop: 0 }}>
            <BulletTwo />
          </ListItemIcon>
          <Box flex={1}>
            <Typography variant="h6" fontWeight="bold" gutterBottom mb={2}>
              Open the invoice & select {'"Pay now by bank (HK)"'}
            </Typography>
            <Grid container direction="row" alignItems="center">
              <Grid item>
                <Box
                  component="img"
                  src="/integrations/xero/xero_pay_now.png"
                  alt="Xero Test Invoice"
                  sx={{ maxWidth: '600px', width: '100%', height: 'auto', border: '1px #22222280 solid' }}
                />
              </Grid>
            </Grid>
          </Box>
        </ListItem>
        <ListItem alignItems="flex-start" key={3}>
          <ListItemIcon sx={{ mt: 0 }}>
            <BulletThree />
          </ListItemIcon>
          <Box flex={1}>
            <Typography variant="h6" fontWeight="bold" gutterBottom mb={2}>
              Complete the test payment flow
            </Typography>
            <Grid container direction="row" alignItems="center">
              <Grid item>
                <Box
                  component="img"
                  src="/integrations/xero/xero_payment_submitted.png"
                  alt="Xero Test Invoice"
                  sx={{ maxWidth: '300px', width: '100%', height: 'auto', border: '1px #22222280 solid' }}
                />
              </Grid>
            </Grid>
          </Box>
        </ListItem>
        <ListItem alignItems="flex-start" key={4}>
          <ListItemIcon sx={{ mt: 0 }}>
            <BulletFour />
          </ListItemIcon>
          <Box flex={1}>
            <Typography variant="h6" fontWeight="bold" gutterBottom mb={2}>
              {`Verify that the invoice is marked as "Paid" in Xero`}
            </Typography>
            <Grid container direction="row" alignItems="center">
              <Grid item>
                <Box
                  component="img"
                  src="/integrations/xero/xero_paid_invoice.png"
                  alt="Xero Test Invoice"
                  sx={{ maxWidth: '600px', width: '100%', height: 'auto', border: '1px #22222280 solid' }}
                />
              </Grid>
            </Grid>
          </Box>
        </ListItem>
      </List>
    </Card>
  );
};

const XeroInvoicingBestPracticeSegment = () => {
  return (
    <Card sx={{ flex: 1, p: 3 }}>
      <Typography variant="h5" component="h5" fontWeight="bold" gutterBottom paddingLeft={2}>
        Xero invoicing best practices
      </Typography>
      <List>
        <ListItem alignItems="flex-start" key={1}>
          <ListItemIcon sx={{ marginTop: 0 }}>
            <BulletOne />
          </ListItemIcon>
          <Box flex={1} paddingBottom={1.5}>
            <Typography variant="h6" fontWeight="bold" gutterBottom mb={2}>
              {`Use the payer's full legal name when creating invoices in Xero`}
            </Typography>
            <Typography variant="subtitle2">
              {`For security reasons, Finverse will verify that the bank accountholder name provided by the payer matches the invoice recipient's contact name in Xero.`}
            </Typography>
          </Box>
        </ListItem>
        <ListItem alignItems="flex-start" key={2}>
          <ListItemIcon sx={{ mt: 0 }}>
            <BulletTwo />
          </ListItemIcon>
          <Box flex={1}>
            <Typography variant="h6" fontWeight="bold" gutterBottom mb={2}>
              Provide a valid contact email for the invoice recipient
            </Typography>
            <Typography variant="subtitle2">
              Finverse will send email alerts for any successful or failed payments, allowing invoice recipients to
              promptly resolve any issues.
            </Typography>
            <Grid container direction="row" alignItems="center">
              <Grid item>
                <Box
                  component="img"
                  src="/integrations/xero/xero_invoicing_best_practices.png"
                  alt="Xero Test Invoice"
                  sx={{ maxWidth: '250px', width: '100%', height: 'auto', border: '1px #22222280 solid' }}
                />
              </Grid>
            </Grid>
          </Box>
        </ListItem>
      </List>
    </Card>
  );
};

import { useState } from 'react';

import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { PayoutDetails, PayoutDetailsModal, PayoutRecipient, PayoutRecipientModal } from './Components/PayoutModals';
import { getCurrencyView } from './MandatesTable';

export type Payout = {
  amount: number;
  created_at: string;
  currency: string;
  payout_details: PayoutDetails;
  recipient_details: PayoutRecipient;
  payout_id: string;
  status: string;
  updated_at: string;
};

enum ModalType {
  NONE,

  PAYOUT_DETAILS,
  PAYOUT_RECIPIENT,
}

export const PayoutsTable = ({ customerAppId, items }: { customerAppId: string; items: Payout[] }) => {
  const [itemIndex, setItemIndex] = useState<number>(-1);
  const [modalType, setModalType] = useState<ModalType>(ModalType.NONE);

  const handleClose = () => {
    setItemIndex(-1);
    setModalType(ModalType.NONE);
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="payouts table">
        <TableHead>
          <TableRow sx={{ '& th': { fontWeight: 600 } }}>
            <TableCell align="center">Updated At (UTC)</TableCell>
            <TableCell align="center">Payout ID</TableCell>
            <TableCell align="center">Status</TableCell>
            <TableCell align="center">Amount</TableCell>
            <TableCell align="center">Currency</TableCell>
            <TableCell align="center">Details</TableCell>
            <TableCell align="center">Recipient</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <PayoutDetailsModal
            open={itemIndex !== -1 && modalType === ModalType.PAYOUT_DETAILS}
            handleClose={handleClose}
            details={items[itemIndex]?.payout_details}
          />
          <PayoutRecipientModal
            open={itemIndex !== -1 && modalType === ModalType.PAYOUT_RECIPIENT}
            handleClose={handleClose}
            details={items[itemIndex]?.recipient_details}
          />
          {items.map((item, index) => (
            <TableRow key={item.payout_id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell align="center">{item.updated_at}</TableCell>
              <TableCell align="center">{item.payout_id}</TableCell>
              <TableCell align="center">{item.status}</TableCell>
              <TableCell align="center">{getCurrencyView(item.amount)}</TableCell>
              <TableCell align="center">{item.currency}</TableCell>
              <TableCell align="center">
                <Button
                  onClick={() => {
                    setItemIndex(index);
                    setModalType(ModalType.PAYOUT_DETAILS);
                  }}
                >
                  {item.payout_details.description}
                </Button>
              </TableCell>
              <TableCell align="center">
                <Button
                  onClick={() => {
                    setItemIndex(index);
                    setModalType(ModalType.PAYOUT_RECIPIENT);
                  }}
                >
                  {item.recipient_details.recipient_name}
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

import { FilterOptionsState } from '@mui/material';

import { GetInstitutions } from '../../lib/useDeveloperApi';

export interface paymentInstitutions {
  institutionIds: string[];
  institutionIdToName: Record<string, string>;
}

export function getInstitutions(): paymentInstitutions {
  const institutions = GetInstitutions();
  if (institutions.data !== undefined) {
    const filteredInstitutions = institutions.data.filter((institution: { products_supported: string[] }) =>
      institution.products_supported.includes('PAYMENTS'),
    );

    const institutionIds = filteredInstitutions.map((item: { institution_id: string }) => item.institution_id);
    const institutionIdToName = filteredInstitutions.reduce(
      (prevInstitution: any, currInstitution: { institution_id: string; institution_name: string }) => {
        return {
          ...prevInstitution,
          [currInstitution.institution_id]: currInstitution.institution_name,
        };
      },
      {},
    );

    return { institutionIds, institutionIdToName };
  }
  return { institutionIds: [], institutionIdToName: {} };
}

export const filterInstitutions = (options: string[], state: FilterOptionsState<string>) => {
  const input = state.inputValue.replaceAll(/\s/g, '').toLowerCase();

  const filteredOptions = options.filter((institutionId) => {
    const institutionName = state.getOptionLabel(institutionId).replaceAll(/\s/g, '').toLowerCase();

    return institutionName.indexOf(input) > -1 || institutionId.indexOf(input) > -1;
  });

  return filteredOptions;
};

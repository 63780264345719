import { useEffect, useMemo, useState } from 'react';

import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  outlinedInputClasses,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { useHistory, useLocation } from 'react-router-dom';

import DateRangePicker, { DateRangeValues, NullableDate } from '../../components/DateRangePicker';
import { getErrorMessage } from '../../lib/error';
import { getPayoutQueryParams, ListPayouts, PayoutQuery } from '../../lib/useDeveloperApi';
import { Payout, PayoutsTable } from './PayoutsTable';

const payoutStatuses = ['EXECUTED'];

const getStyles = (name: string, statuses: string[], theme: Theme) => {
  return {
    fontWeight: statuses.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
};

const getNumericVal = (str: string | null, defaultVal: number): number => {
  if (str === null || isNaN(parseInt(str))) return defaultVal;

  return parseInt(str);
};

export const ViewPayouts = ({ customerAppId }: { customerAppId: string }) => {
  const theme = useTheme();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [payouts, setPayouts] = useState<Payout[]>([]);
  const [total, setTotal] = useState<number>(0);
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  // change the following constant to change page size
  const pageSize = 100;

  const [dateRangeValue, setDateRangeValue] = useState<DateRangeValues>([null, null]);
  const [status, setStatus] = useState<string[]>([]);
  const [currency, setCurrency] = useState<string>('');

  const [payoutQuery, setPayoutQuery] = useState<PayoutQuery>({});

  let filterStatuses: string[] = [];
  const queryStatus = query.get('statuses');
  if (queryStatus !== null) {
    filterStatuses = queryStatus.split(',');
  }
  const offset = getNumericVal(query.get('offset'), 0);
  const { data, error } = ListPayouts(customerAppId, {
    from: query.get('date_from') ?? undefined,
    to: query.get('date_to') ?? undefined,
    statuses: filterStatuses,
    currency: query.get('currency') ?? undefined,
    limit: pageSize,
    offset,
  });

  useEffect(() => {
    setPayouts(data?.payouts ?? []);
    setTotal(data?.total_payouts ?? 0);
    if (data) {
      enqueueSnackbar('Loaded payouts', { variant: 'info', autoHideDuration: 1000 });
    }
  }, [data, enqueueSnackbar]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(getErrorMessage(error), { variant: 'error' });
    }
  }, [error, enqueueSnackbar]);

  useEffect(() => {
    const searchParams = getPayoutQueryParams(payoutQuery);
    history.push({
      search: searchParams,
    });
  }, [payoutQuery, history]);

  // this function will set the current date time so the UTC value for date string will be the same as the locale time
  // we do this by adding the UTC offset to the date (local), eg 8am in HKT -> 12am in UTC same date.
  // by default the DatePicker component returns the date time as midnight which when converted to UTC will return yesterday's date
  const setDateWithOffset = (newValue: Date) => {
    newValue.getTimezoneOffset(); // get the UTC offset
    newValue.setHours(0); // set the hour to 0 so adding offset will always be the same
    newValue.setMinutes(-newValue.getTimezoneOffset()); // add the offset so we get the local time with UTC offset
  };

  const searchBar = () => {
    return (
      <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="flex-start" spacing={2}>
        <DateRangePicker
          values={dateRangeValue}
          views={['day']}
          inputFormat="yyyy-MM-dd"
          onStartDateChange={(newVal: NullableDate) => {
            if (newVal !== null) {
              setDateWithOffset(newVal);
            }
            setDateRangeValue([newVal, dateRangeValue[1]]);
          }}
          onEndDateChange={(newVal: NullableDate) => {
            if (newVal != null) {
              setDateWithOffset(newVal);
            }
            setDateRangeValue([dateRangeValue[0], newVal]);
          }}
          renderInput={(params: TextFieldProps) => (
            <Box sx={{ width: 200 }}>
              <TextField
                fullWidth
                {...params}
                sx={{
                  [`& .${outlinedInputClasses.root}:hover > fieldset`]: { borderColor: 'primary.dark' },
                }}
              />
            </Box>
          )}
        />
        <FormControl fullWidth>
          <InputLabel id="select-status-label">Status</InputLabel>
          <Select
            labelId="select-status-label"
            id="select-status"
            multiple
            value={status}
            fullWidth
            onChange={(event: SelectChangeEvent<typeof payoutStatuses>) => {
              const {
                target: { value },
              } = event;
              setStatus(typeof value === 'string' ? value.split(',') : value);
            }}
            renderValue={(selected) => selected.join(', ')}
            label="Status"
          >
            {payoutStatuses.map((s) => (
              <MenuItem sx={{ fontSize: '0.8em' }} key={s} value={s} style={getStyles(s, payoutStatuses, theme)}>
                <Checkbox checked={status.includes(s)} />
                {s}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="currency-select-label">Currency</InputLabel>
          <Select
            labelId="currency-select-label"
            id="currency-select"
            label="Currency"
            value={currency}
            onChange={(e) => setCurrency(e.target.value)}
          >
            <MenuItem value="" sx={{ fontSize: '0.8em' }}>
              <em>Any</em>
            </MenuItem>
            <MenuItem value="HKD" sx={{ fontSize: '0.8em' }}>
              HKD
            </MenuItem>
            <MenuItem value="CNY" sx={{ fontSize: '0.8em' }}>
              CNY
            </MenuItem>
            <MenuItem value="PHP" sx={{ fontSize: '0.8em' }}>
              PHP
            </MenuItem>
          </Select>
        </FormControl>
        <Button
          fullWidth
          variant="contained"
          onClick={() => {
            setPayoutQuery({
              ...payoutQuery, // maintain offset
              from: dateRangeValue[0]?.toISOString().split('T')[0],
              to: dateRangeValue[1]?.toISOString().split('T')[0],
              currency: currency,
              statuses: status,
            });
          }}
        >
          View
        </Button>
      </Stack>
    );
  };

  const titleBar = () => {
    return (
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
        sx={{ marginBottom: '1em', marginTop: '0.5em' }}
      >
        <Grid item>
          <Typography variant="h4" component="h4">
            Payouts
          </Typography>
        </Grid>
        <Grid item>
          Showing {Math.min(offset + 1, total)}-{Math.min(offset + pageSize, total)} of {total}
        </Grid>
      </Grid>
    );
  };

  const pagination = () => {
    return (
      <ButtonGroup
        disableElevation
        size="small"
        aria-label="Pagination"
        variant="contained"
        sx={{ width: '250px', marginTop: '1em' }}
      >
        <Button
          fullWidth
          disabled={offset < pageSize}
          onClick={() => {
            setPayoutQuery({
              ...payoutQuery,
              offset: offset - pageSize,
            });
          }}
        >
          Previous
        </Button>
        <Button
          fullWidth
          disabled={offset + pageSize >= Number(total)}
          onClick={() => {
            setPayoutQuery({
              ...payoutQuery,
              offset: offset + pageSize,
            });
          }}
        >
          Next
        </Button>
      </ButtonGroup>
    );
  };

  return (
    <>
      {searchBar()}
      {titleBar()}
      <PayoutsTable customerAppId={customerAppId} items={payouts} />
      {pagination()}
    </>
  );
};

import { useCallback, useState } from 'react';

import {
  Alert,
  AlertTitle,
  Card,
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  Typography,
  Divider,
} from '@mui/material';
import { Box } from '@mui/system';
import { useSnackbar } from 'notistack';
import { useLocation } from 'react-router-dom';

import IntegrationCard, { ConnectionStatus } from '../../../components/Integrations/IntegrationCard';
import SelectConnectionType, { ConnectionType } from '../../../components/SelectConnectionType';
import { Env } from '../../../contexts/AppContext';
import useApp from '../../../hooks/useApp';
import { BulletOne, BulletTwo, BulletThree, BulletFour } from '../../../icons/BulletIcons';
import { getErrorMessage } from '../../../lib/error';
import { getSDKClient } from '../../../lib/sdk';

/**
 * generateErrorSection will be responsible for making the error dialog
 * if something goes wrong when connecting Xero via Oauth (populated by hooks-api)
 *
 * e.g. query params: ?details=Failed+to+connect+xero.+Please+try+again.&error=true&message=Xero+Integration+Failed
 * @param searchParams URL query param object
 * @returns JSX for error (if exists)
 */
const generateErrorSection = (searchParams: URLSearchParams): JSX.Element => {
  if (searchParams.get('error') === 'true') {
    // Defaults should come in from backend, but just in case
    const message = searchParams.get('message') || 'Xero connection failed';
    const details =
      searchParams.get('details') ||
      'An error occurred while connecting Xero. Please try again later or contact Finverse support.';

    return (
      <>
        <Grid container item display="flex">
          <Alert sx={{ flex: 1 }} severity="warning" variant="standard">
            <AlertTitle sx={{ fontWeight: 'bold' }}>{message}</AlertTitle>
            {details}
          </Alert>
        </Grid>
      </>
    );
  } else {
    return <></>;
  }
};

type XeroUnconnectedProps = {
  teamId: string;
  isLive?: boolean;
};

export default function XeroUnconnected(props: XeroUnconnectedProps) {
  const { app } = useApp();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const [showSelectType, setShowSelectionType] = useState<boolean>(false);
  const [connectionError, setConnectionError] = useState<string | undefined>(undefined);

  const dialogClose = () => {
    setShowSelectionType(false);
    setConnectionError(undefined);
  };

  const showSelectConnectionType = () => {
    setShowSelectionType(true);
  };

  /**
   * we might get redirected back to this page with failed attempt to connect to Xero
   * e.g. user might click connect, but then cancel oauth for whatever reason.
   * In the case there is a failure, we get ?error=oauth_failed in query params
   */
  const searchParams = new URLSearchParams(location.search.substring(1)); // remove ? from query params

  const connectionSelected = (connectionType: ConnectionType) => {
    if (app.env !== Env.Production && connectionType === ConnectionType.Live) {
      // show error as we do not allow creating live app in non-production env
      setConnectionError(
        'Cannot create a “Live” app in non-production environments. Switch to the “Production” environment in the Finverse dashboard and try again.',
      );
    } else if (props.isLive !== undefined && props.isLive && connectionType !== ConnectionType.Live) {
      // show error as the app is LIVE and user selected TEST connection
      setConnectionError(
        'This Team has an existing app, with "Live Connection" enabled. We do not allow mixing Live and Test connections within the same Team. Please create a new Team and try again.',
      );
    } else if (props.isLive !== undefined && !props.isLive && connectionType !== ConnectionType.Test) {
      // show error as the app is NOT LIVE and user selected LIVE connection
      setConnectionError(
        'This Team has an existing app, with "Test Connection" enabled. We do not allow mixing Live and Test connections within the same Team. Please create a new Team and try again.',
      );
    } else {
      // this mean either no existing customer app exists or user selected the same mode
      connectToXero(props.teamId, connectionType);
    }
  };

  const connectToXero = useCallback(
    async (teamId: string, connectionType: ConnectionType) => {
      const { tokens, env } = app;
      const currentToken = tokens.get(env) ?? '';
      const sdk = getSDKClient(env, currentToken);
      try {
        enqueueSnackbar('Redirecting to Xero for authentication', { variant: 'info' });
        const response = await sdk.connectToXero(teamId, connectionType === ConnectionType.Live);
        const xeroAuthUrl = response.oauth_uri;
        window.location.href = xeroAuthUrl;
      } catch (err) {
        enqueueSnackbar(getErrorMessage(err), { variant: 'error' });
      }
    },
    [app, enqueueSnackbar],
  );

  return (
    <Container maxWidth="lg" sx={{ flexGrow: 1, p: 4 }}>
      <SelectConnectionType
        show={showSelectType}
        errorMessage={connectionError}
        onSuccess={connectionSelected}
        onClose={dialogClose}
      />
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{ textAlign: 'left' }}
        spacing={3}
      >
        <Grid container item direction="row" sx={{ marginBottom: 4 }}>
          <Grid item>
            <Typography variant="h2" component="h2" sx={{ fontWeight: 'bold' }}>
              Integrate with Xero
            </Typography>
          </Grid>
        </Grid>
        {generateErrorSection(searchParams)}
        <Grid container item display="flex">
          <IntegrationCard
            logoSrc="/integrations/xero/xero_logo.svg"
            name="Xero"
            description="Automate payment collection and reconciliation by enabling customers to make instant bank payments from any Xero invoice"
            buttonText="Connect Xero"
            onConnectAction={() => showSelectConnectionType()}
            integrationStatus={ConnectionStatus.NOT_CONNECTED}
          />
        </Grid>
        <Grid container item display="flex">
          <Card sx={{ flex: 1, padding: 3 }}>
            <Typography variant="h5" component="h5" fontWeight="bold" gutterBottom paddingLeft={2}>
              Automate payment collections with bank transfers
            </Typography>
            <Divider sx={{ marginTop: 4 }} />
            <List sx={{ marginTop: 4 }}>
              <ListItem alignItems="flex-start" key={1}>
                <ListItemIcon sx={{ marginTop: 0 }}>
                  <BulletOne />
                </ListItemIcon>
                <Box>
                  <Typography variant="h6" component="h6" fontWeight="bold" gutterBottom>
                    Low cost
                  </Typography>
                  <Typography variant="body1" gutterBottom marginBottom={5}>
                    Save on collection costs with a transaction fee of 0.5% on each payment received.
                  </Typography>
                </Box>
              </ListItem>
              <ListItem alignItems="flex-start" key={2}>
                <ListItemIcon sx={{ marginTop: 0 }}>
                  <BulletTwo />
                </ListItemIcon>
                <Box>
                  <Typography variant="h6" component="h6" fontWeight="bold" gutterBottom>
                    Get paid in a few clicks
                  </Typography>
                  <Typography variant="body1" gutterBottom marginBottom={5}>
                    Enable your customers to pay directly from your Xero invoice and complete a bank transfer within
                    minutes.
                  </Typography>
                </Box>
              </ListItem>
              <ListItem alignItems="flex-start" key={3}>
                <ListItemIcon sx={{ marginTop: 0 }}>
                  <BulletThree />
                </ListItemIcon>
                <Box>
                  <Typography variant="h6" component="h6" fontWeight="bold" gutterBottom>
                    Automate recurring payments
                  </Typography>
                  <Typography variant="body1" gutterBottom marginBottom={5}>
                    Allow your customers to opt-in to autopay future invoices, or to continue to approve each invoice
                    for more control.
                  </Typography>
                </Box>
              </ListItem>
              <ListItem alignItems="flex-start" key={4}>
                <ListItemIcon sx={{ marginTop: 0 }}>
                  <BulletFour />
                </ListItemIcon>
                <Box>
                  <Typography variant="h6" component="h6" fontWeight="bold" gutterBottom>
                    Eliminate admin and reconciliation work
                  </Typography>
                  <Typography variant="body1" gutterBottom marginBottom={5}>
                    Reduce busy-work with payments automatically reconciled to Xero and invoices marked as paid. Retain
                    full visibility on Finverse payments and clearing account balances in your Xero dashboard and in
                    Finverse’s dashboard.
                  </Typography>
                </Box>
              </ListItem>
            </List>
          </Card>
        </Grid>

        <Grid container item display="flex">
          <Card sx={{ flex: 1, padding: 3 }}>
            <Typography variant="h5" component="h5" fontWeight="bold" gutterBottom paddingLeft={2}>
              How Xero Integration works
            </Typography>
            <Divider sx={{ marginTop: 4 }} />
            <List sx={{ marginTop: 4 }}>
              <ListItem alignItems="flex-start" key={1}>
                <ListItemIcon sx={{ marginTop: 0 }}>
                  <BulletOne />
                </ListItemIcon>
                <Box>
                  <Typography variant="h6" component="h6" fontWeight="bold" gutterBottom>
                    Setup Finverse as a Xero payment service provider
                  </Typography>
                  <Typography variant="body1" gutterBottom marginBottom={5}>
                    {
                      'Click the "CONNECT XERO" button above, login to Xero and select which organization to connect to Finverse'
                    }
                  </Typography>
                  <Grid
                    container
                    direction="row"
                    spacing={{ xs: 3, md: 10 }}
                    justifyContent="start"
                    alignItems="center"
                  >
                    <Grid item>
                      <Box
                        component="img"
                        sx={{
                          maxWidth: '250px',
                          width: '100%',
                          height: 'auto',
                          objectFit: 'contain',
                          border: '1px #22222280 solid',
                        }}
                        src="/integrations/xero/xero_login.png"
                        alt="Xero login page"
                      />
                    </Grid>
                    <Grid item>
                      <Box
                        component="img"
                        sx={{
                          maxWidth: '250px',
                          width: '100%',
                          height: 'auto',
                          objectFit: 'contain',
                          border: '1px #22222280 solid',
                        }}
                        src="/integrations/xero/xero_select_organization.png"
                        alt="Xero grant access page"
                      />
                    </Grid>
                  </Grid>
                </Box>
              </ListItem>
              <ListItem alignItems="flex-start" key={2}>
                <ListItemIcon sx={{ marginTop: 0 }}>
                  <BulletTwo />
                </ListItemIcon>
                <Box>
                  <Typography variant="h6" component="h6" fontWeight="bold">
                    {'Your customers see a "Pay now" option on their Xero invoices and emails'}
                  </Typography>
                  <Typography variant="body1" component="div">
                    Works alongside any existing online payment methods (like Stripe, Paypal). Invoice recipient chooses
                    payment method if multiple methods are offered.
                  </Typography>
                  <Typography variant="body1" component="div" gutterBottom marginBottom={5}>
                    {'Payments received are automatically reconciled in Xero and invoices are marked as "Paid".'}
                  </Typography>
                  <Grid container direction="row" spacing={10} justifyContent="start" alignItems="center">
                    <Grid item>
                      <Box
                        component="img"
                        sx={{
                          maxWidth: '400px',
                          width: '100%',
                          height: 'auto',
                          objectFit: 'contain',
                          border: '1px #22222280 solid',
                        }}
                        src="/integrations/xero/xero_pay_by_bank.png"
                        alt="Xero pay now by bank (HK)"
                      />
                    </Grid>
                    <Grid item>
                      <Box
                        component="img"
                        sx={{
                          maxWidth: '400px',
                          width: '100%',
                          height: 'auto',
                          objectFit: 'contain',
                        }}
                        src="/integrations/xero/xero_paid_invoice_new.png"
                        alt="Xero paid invoice"
                      />
                    </Grid>
                  </Grid>
                </Box>
              </ListItem>
              <ListItem alignItems="flex-start" key={3}>
                <ListItemIcon sx={{ marginTop: 0 }}>
                  <BulletThree />
                </ListItemIcon>
                <Box>
                  <Typography variant="h6" component="h6" fontWeight="bold" gutterBottom marginBottom={5}>
                    {"Your customers setup a direct debit or FPS payment using Finverse's bank payment page"}
                  </Typography>
                  <Grid container direction="row" spacing={10} justifyContent="start" alignItems="center">
                    <Grid item>
                      <Box
                        component="img"
                        sx={{
                          maxWidth: '250px',
                          width: '100%',
                          height: 'auto',
                          objectFit: 'contain',
                          border: '1px #22222280 solid',
                        }}
                        src="/integrations/xero/xero_payment_submitted.png"
                        alt="Xero pay now with bank (HK)"
                      />
                    </Grid>
                  </Grid>
                </Box>
              </ListItem>
              <ListItem alignItems="flex-start" key={4}>
                <ListItemIcon sx={{ marginTop: 0 }}>
                  <BulletFour />
                </ListItemIcon>
                <Box>
                  <Typography variant="h6" component="h6" fontWeight="bold" gutterBottom marginBottom={5}>
                    {'Payments received are automatically reconciled in Xero and invoices are marked "Paid"'}
                  </Typography>
                  <Grid container direction="row" spacing={10} justifyContent="start" alignItems="center">
                    <Grid item>
                      <Box
                        component="img"
                        sx={{
                          maxWidth: '400px',
                          width: '100%',
                          height: 'auto',
                          objectFit: 'contain',
                          border: '1px #22222280 solid',
                        }}
                        src="/integrations/xero/xero_paid_invoice.png"
                        alt="Xero pay now with bank (HK)"
                      />
                    </Grid>
                  </Grid>
                </Box>
              </ListItem>
            </List>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

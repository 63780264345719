import { Route, Switch } from 'react-router-dom';

import NoResourcePage from '../../components/NoResourcePage';
import RequestProductionAccessMessage from '../../components/RequestProductionAccessMsg';
import useApp from '../../hooks/useApp';
import useTeam from '../../hooks/useTeam';
import { isValidJwt } from '../../lib/jwt';
import Routes from '../../lib/routes';
import AddTeam from './AddTeam';
import AddTeamMembers from './AddTeamMember';
import TeamSettings from './Settings';
import TeamMembersView from './TeamMembers';

export default function TeamRouter(): JSX.Element {
  const { app } = useApp();
  const { allTeams } = useTeam();
  const token = app.tokens.get(app.env);

  if (allTeams === null && isValidJwt(token)) {
    return <RequestProductionAccessMessage />;
  }

  if (allTeams?.length === 0) {
    return (
      <Switch>
        {/* user should still be able to add team */}
        <Route path={Routes.Teams.AddTeam}>
          <AddTeam />
        </Route>
        {/* for any other routes, we will show no teams */}
        <Route>
          <NoResourcePage
            title="Team Settings"
            subtitle="You do not belong to any teams. Please create a team to get started"
            buttonText="Create Team"
            buttonHref={Routes.Teams.AddTeam}
          />
        </Route>
      </Switch>
    );
  }

  return (
    <Switch>
      <Route path={Routes.Teams.AddMember}>
        <AddTeamMembers />
      </Route>
      <Route path={Routes.Teams.AddTeam}>
        <AddTeam />
      </Route>
      <Route path={Routes.Teams.Settings}>
        <TeamSettings />
      </Route>
      <Route path={Routes.Teams.Base}>
        <TeamMembersView />
      </Route>
    </Switch>
  );
}

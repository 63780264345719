import { useEffect, useContext } from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import { AppContext } from '../contexts/AppContext';
import { hasRegistered } from '../lib/jwt';
import Routes from '../lib/routes';

export default function Callback(): JSX.Element {
  const { app, setApp } = useContext(AppContext);
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const accessToken = query.get('access_token');
  const error = query.get('error');

  useEffect(() => {
    const getDeveloper = async (token: string): Promise<void> => {
      if (hasRegistered(token)) {
        const route = app.nextRedirect;
        history.push(route || Routes.Overview);
      } else {
        history.push(Routes.Register);
      }
    };
    if (accessToken !== null) {
      const currEnv = app.env;
      const tokens = app.tokens;
      tokens.set(currEnv, accessToken);
      setApp({ ...app, tokens });
      getDeveloper(accessToken);
    } else if (error === 'EMAIL_NOT_VERIFIED') {
      const currEnv = app.env;
      const tokens = app.tokens;
      const t = query.get('token');
      if (t !== null) {
        tokens.set(currEnv, t);
        setApp({ ...app, tokens });
      }
      history.push(`${Routes.Error}/EMAIL_NOT_VERIFIED`);
    }
  });

  return <div className="pageContainer"></div>;
}

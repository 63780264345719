import { useMemo, useState } from 'react';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { ListItemButton, ListItemText, Tooltip, SxProps, Theme, ListItemIcon, Collapse, List } from '@mui/material';
import { Link, useHistory } from 'react-router-dom';

interface HeaderNavProps {
  buttonText: string;
  route?: string;
  nestedRoutes?: HeaderNavProps[];
  disabled?: boolean;
  external?: boolean;
  sx?: SxProps<Theme>;
  Icon?: JSX.Element;
}

type DisabledListItemProps = Pick<HeaderNavProps, 'sx' | 'buttonText' | 'Icon'>;
type ExternalListItemProps = Pick<Omit<HeaderNavProps, 'route'>, 'sx' | 'buttonText' | 'Icon'> & { route: string };
type RouteListItemProps = ExternalListItemProps;
type ExpandableRouteListItemProps = DisabledListItemProps & { nestedRoutes: HeaderNavProps[] };

const DisabledListItem = ({ sx, buttonText, Icon }: DisabledListItemProps) => (
  <ListItemButton sx={sx}>
    <ListItemIcon>{Icon}</ListItemIcon>
    <Tooltip title="Coming soon!" arrow sx={{ fontSize: '1rem' }} placement="right">
      <ListItemText secondary={buttonText} />
    </Tooltip>
  </ListItemButton>
);

const ExternalListItem = ({ sx, buttonText, route, Icon }: ExternalListItemProps) => (
  <ListItemButton sx={sx} component="a" target="_blank" href={route}>
    <ListItemIcon>{Icon}</ListItemIcon>
    <ListItemText secondary={buttonText} />
  </ListItemButton>
);

const RouteListItem = ({ sx, buttonText, route, Icon }: RouteListItemProps) => {
  const history = useHistory();

  const selected = useMemo(() => {
    return history.location.pathname === route;
  }, [history.location.pathname, route]);

  return (
    <ListItemButton sx={sx} component={Link} to={route} selected={selected}>
      <ListItemIcon>{Icon}</ListItemIcon>
      <ListItemText secondary={buttonText} />
    </ListItemButton>
  );
};

const ExpandableRouteListItem = ({ sx, buttonText, nestedRoutes, Icon }: ExpandableRouteListItemProps) => {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItemButton sx={sx} onClick={handleClick}>
        <ListItemIcon>{Icon}</ListItemIcon>
        <ListItemText secondary={buttonText} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {nestedRoutes.map(
            ({ route, buttonText }, idx) =>
              route && <RouteListItem key={`${route}-${idx}`} sx={{ pl: 4 }} route={route} buttonText={buttonText} />,
          )}
        </List>
      </Collapse>
    </>
  );
};

export default function HeaderList(props: HeaderNavProps) {
  const { sx, route, nestedRoutes, buttonText, disabled, external, Icon } = props;

  if (disabled) {
    return <DisabledListItem sx={sx} Icon={Icon} buttonText={buttonText} />;
  }

  if (external && route) {
    return <ExternalListItem sx={sx} Icon={Icon} buttonText={buttonText} route={route} />;
  }

  if (route) {
    return <RouteListItem sx={sx} Icon={Icon} buttonText={buttonText} route={route} />;
  }

  if (nestedRoutes) {
    return <ExpandableRouteListItem sx={sx} Icon={Icon} buttonText={buttonText} nestedRoutes={nestedRoutes} />;
  }

  return null;
}

import CircularProgress from '@mui/material/CircularProgress';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() =>
  createStyles({
    loader: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      display: 'flex',
      placeItems: 'center',
      zIndex: 9999,
    },
  }),
);

const Loader: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.loader}>
      <CircularProgress />
    </div>
  );
};

export default Loader;

import AddIcon from '@mui/icons-material/Add';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Divider, FormControl, ListItemIcon, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useHistory } from 'react-router-dom';

import useTeam from '../../../hooks/useTeam';
import Routes from '../../../lib/routes';
import { ToggleProps, toggleStyles } from './toggleStyle';

export default function TeamToggle({ setLoading }: ToggleProps) {
  const history = useHistory();
  const { team, allTeams, setTeam } = useTeam();
  const createTeamOption = 'createTeam';
  const handleChange = (event: SelectChangeEvent) => {
    if (event.target.value === createTeamOption) return; // need to add this so the select closes

    setLoading(true);

    setTeam(event.target.value);

    setTimeout(function () {
      setLoading(false);
    }, 500);
  };

  const teams = allTeams ?? [];
  const currentSelectValue = teams.find((t) => t.team_id === team.team_id) !== undefined ? team.team_id : '';

  return (
    <FormControl id="teamToggle" variant="standard" sx={{ minWidth: 80 }} size="small">
      <Select
        autoWidth
        value={currentSelectValue}
        onChange={(e) => handleChange(e)}
        sx={toggleStyles.select}
        MenuProps={{
          sx: toggleStyles.menuItem,
        }}
        IconComponent={ExpandMore}
        disabled={allTeams === null}
      >
        {teams.map((t) => (
          <MenuItem key={t.team_id} value={t.team_id}>
            {t.team_name}
          </MenuItem>
        ))}
        <Divider />
        <MenuItem value={createTeamOption} onClick={() => history.push(Routes.Teams.AddTeam)}>
          <ListItemIcon>
            <AddIcon color="primary" />
          </ListItemIcon>
          New Team
        </MenuItem>
      </Select>
    </FormControl>
  );
}

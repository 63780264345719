import { Card, CardProps, CircularProgress, Button, ButtonProps } from '@mui/material';

// A simple card component that contains loading circle when `loading` is passed as true
export const LoadingCard = ({ loading, sx: cardStyleOverride, ...props }: CardProps & { loading: boolean }) => {
  return (
    <Card
      sx={{ paddingX: 4, paddingY: 4, flex: 1, textAlign: loading ? 'center' : 'start', ...cardStyleOverride }}
      elevation={3}
      {...props}
    >
      {loading ? <CircularProgress /> : props.children}
    </Card>
  );
};

// a simple button component that contains a loading circle when `loading` is passed as true
// when button is loading, it is also marked as disabled so it cannot be clicked
export const LoadingButton = ({ loading, ...props }: ButtonProps & { loading: boolean }) => {
  return (
    <Button {...props} disabled={loading || props.disabled}>
      {loading ? <CircularProgress /> : props.children}
    </Button>
  );
};

import DoneIcon from '@mui/icons-material/Done';
import { Card, CardMedia, Grid, Box, Typography, Button, Chip, Stack } from '@mui/material';

export enum ConnectionStatus {
  NOT_CONNECTED = 0,
  CONNECTED = 1,
  USER_ACTION_REQUIRED = 2,
}

type IntegrationCardProps = {
  name: string;
  /**
   * path of the logo svg
   *
   * Should look something like `/integrations/{{integration_name}}_logo.svg`
   */
  logoSrc: string;
  description?: string;
  buttonText?: string;
  isLive?: boolean;
  /**
   * if true, then we will treat the integration to be connected to customer app
   */
  integrationStatus?: ConnectionStatus;
  /**
   * Action to be performed when user clicks connect button
   */
  onConnectAction?: () => void;
  /**
   * Action to be performed when user clicks disconnect button
   */
  onDisconnectAction?: () => void;
};

export default function IntegrationCard({
  name,
  description,
  logoSrc,
  buttonText,
  integrationStatus,
  isLive,
  onConnectAction,
  onDisconnectAction,
}: IntegrationCardProps) {
  function getButton() {
    if (integrationStatus === ConnectionStatus.CONNECTED) {
      return (
        <Button variant="contained" sx={{ fontWeight: 'bold' }} size="large" color="error" onClick={onDisconnectAction}>
          {buttonText ?? 'Disconnect'}
        </Button>
      );
    } else if (integrationStatus === ConnectionStatus.USER_ACTION_REQUIRED) {
      return (
        <Button variant="contained" sx={{ fontWeight: 'bold' }} size="large" disabled={true}>
          {buttonText ?? 'User Action Required'}
        </Button>
      );
    } else {
      return (
        <Button variant="contained" sx={{ fontWeight: 'bold' }} size="large" onClick={onConnectAction}>
          {buttonText ?? `Connect ${name}`}
        </Button>
      );
    }
  }

  function getStatusChip() {
    if (integrationStatus === ConnectionStatus.CONNECTED) {
      const statusText = isLive ? 'LIVE CONNECTION' : 'TEST CONNECTION';
      return (
        <>
          <Chip
            label={statusText}
            sx={{ borderRadius: 1, fontWeight: 'bold' }}
            variant="outlined"
            color="info"
            size="medium"
          />
          <Chip
            label="Connected"
            sx={{ fontWeight: 'bold', color: 'white' }}
            color="success"
            size="small"
            icon={<DoneIcon />}
          />
        </>
      );
    }

    if (integrationStatus === ConnectionStatus.USER_ACTION_REQUIRED) {
      const statusText = isLive ? 'LIVE CONNECTION' : 'TEST CONNECTION';
      return (
        <Chip
          label={statusText}
          sx={{ borderRadius: 1, fontWeight: 'bold' }}
          variant="outlined"
          color="info"
          size="medium"
        />
      );
    }
  }

  return (
    <Card sx={{ paddingX: 4, paddingY: 4, flex: 1 }} elevation={3}>
      <Grid container direction="row" spacing={4} alignItems="center" justifyContent="center">
        <Grid item>
          <CardMedia
            component="img"
            image={logoSrc}
            alt={`${name} Logo`}
            sx={{
              height: 'auto',
              width: '100%',
              maxWidth: '100px',
            }}
          />
        </Grid>
        <Grid item flex={1}>
          <Box sx={{ minWidth: '175px' }}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography fontWeight="bold">{name}</Typography>
              {getStatusChip()}
            </Stack>
            <Typography>{description}</Typography>
          </Box>
        </Grid>
        <Grid item>{getButton()}</Grid>
      </Grid>
    </Card>
  );
}

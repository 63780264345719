import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

export default function RequestProductionAccessMessage(): JSX.Element {
  return (
    <Container maxWidth="lg">
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          mt: 2,
          minHeight: '30vh',
        }}
      >
        <Typography variant="h1" sx={{ marginBottom: 4 }}>
          Request production access
        </Typography>
        <Typography variant="h5" sx={{ marginTop: 2, marginBottom: 2 }}>
          Contact
          <Link href="mailto:sales@finverse.com" target="_blank" sx={{ marginLeft: 1, marginRight: 1 }}>
            sales@finverse.com
          </Link>
          {"to request access to Finverse's production environment."}
        </Typography>
      </Paper>
    </Container>
  );
}

import { AppBar } from '@mui/material';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

import { DesktopDrawerWidth } from '../../../components/AuthenticatedHeader/common';
import useResponsive from '../../../hooks/useResponsive';
import { QuickStart } from '../../../lib/routes';

export default function QuickStartLinkBar(): JSX.Element {
  const { isDesktop } = useResponsive();
  return (
    <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0 }}>
      <div>
        <Button
          variant="outlined"
          size="medium"
          disableElevation
          component={Link}
          to={QuickStart.Settings}
          sx={{
            color: 'white',
            borderColor: 'white',
            margin: isDesktop ? 2 : 1,
            marginLeft: isDesktop ? DesktopDrawerWidth : 'none',
          }}
        >
          MANAGE QUICKSTART LINKS
        </Button>
      </div>
    </AppBar>
  );
}

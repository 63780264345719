import { useCallback, useState } from 'react';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, Container, Grid, IconButton, InputAdornment, Paper, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';

import useApp from '../../hooks/useApp';
import useTeam from '../../hooks/useTeam';
import { getErrorMessage } from '../../lib/error';
import { getSDKClient } from '../../lib/sdk';

export default function AddTeamMembers(): JSX.Element {
  const { app } = useApp();
  const { team } = useTeam();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [email, setEmail] = useState<string>('');

  const addTeamMember = useCallback(
    async (email: string) => {
      if (email === '') {
        enqueueSnackbar('Empty email provided', { variant: 'error' });
        return;
      }

      const { env, tokens } = app;
      const currentToken = tokens.get(env) ?? '';
      const sdk = getSDKClient(env, currentToken);
      try {
        await sdk.addTeamMembers(team.team_id, email);
        enqueueSnackbar(`${email} has been added to ${team.team_name}`, { variant: 'success' });
        setEmail('');
      } catch (err) {
        let newErrorMessage = '';
        if (axios.isAxiosError(err) && err.response?.status === 601) {
          newErrorMessage = 'Invalid Email';
        } else {
          newErrorMessage = getErrorMessage(err);
        }
        enqueueSnackbar(newErrorMessage, { variant: 'error' });
      }
    },
    [app, team, enqueueSnackbar],
  );

  return (
    <Container maxWidth="lg" sx={{ flexGrow: 1, p: 4 }}>
      <form
        onSubmit={(e) => {
          // prevent form from needlessly refreshing page
          e.preventDefault();
          addTeamMember(email);
        }}
      >
        <Grid
          container
          spacing={3}
          direction="row"
          alignItems="start"
          justifyContent="start"
          sx={{ display: { xs: 'block', sm: 'flex' } }}
        >
          <Grid item>
            <IconButton onClick={() => history.goBack()}>
              <ArrowBackIcon />
            </IconButton>
          </Grid>
          <Grid item flex={1}>
            <Grid container spacing={3} direction="column" display="flex">
              <Grid item>
                <Typography variant="h1" component="h1" gutterBottom>
                  Add Member
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h5" component="h5" fontWeight="bold">
                  Add a team member to {team.team_name}
                </Typography>
              </Grid>
              <Grid item>
                <Paper sx={{ p: 4 }}>
                  <TextField
                    id="email"
                    variant="outlined"
                    type="email"
                    fullWidth
                    label="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" sx={{ display: { md: 'flex', xs: 'none' } }}>
                          <AccountCircleIcon color="primary" />
                        </InputAdornment>
                      ),
                    }}
                    helperText="Input the email of the person you would like to add (note: the person must already have registered an account on dashboard.finverse.com) "
                    autoComplete="off"
                  />
                </Paper>
              </Grid>
              <Grid item justifyContent="center" display="flex">
                <Button variant="contained" size="large" type="submit">
                  Add Member
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
}

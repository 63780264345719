import { DialogContent, Table, TableBody, TableCell, TableRow } from '@mui/material';

import DialogWrapper from './Wrapper';

type ModalProps<K> = {
  open: boolean;
  handleClose: () => void;
  details?: K;
};

export type PayoutDetails = {
  description: string;
  transaction_reference_id: string;
};

export function PayoutDetailsModal({ open, handleClose, details }: ModalProps<PayoutDetails>) {
  if (details === undefined) {
    return <></>;
  }

  return (
    <DialogWrapper id="" onClose={handleClose} title="Payout Details" open={open}>
      <DialogContent dividers>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Description</TableCell>
              <TableCell align="right">{details.description}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Transaction Reference ID</TableCell>
              <TableCell align="right">{details.transaction_reference_id}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </DialogContent>
    </DialogWrapper>
  );
}

export type PayoutRecipient = {
  recipient_id: string;
  recipient_account_id: string;
  recipient_institution_id: string;
  recipient_name: string;
};

export function PayoutRecipientModal({ open, handleClose, details }: ModalProps<PayoutRecipient>) {
  if (details === undefined) {
    return <></>;
  }

  return (
    <DialogWrapper id="" onClose={handleClose} title="Payout Details" open={open}>
      <DialogContent dividers>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Recipient Name</TableCell>
              <TableCell align="right">{details.recipient_name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Recipient Institution ID</TableCell>
              <TableCell align="right">{details.recipient_institution_id}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Recipient ID</TableCell>
              <TableCell align="right">{details.recipient_id}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Recipient Account ID</TableCell>
              <TableCell align="right">{details.recipient_account_id}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </DialogContent>
    </DialogWrapper>
  );
}

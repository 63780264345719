import { ReactElement, useEffect, useMemo, useState } from 'react';

import {
  Container,
  FormControl,
  MenuItem,
  outlinedInputClasses,
  Select,
  Stack,
  SxProps,
  TextField,
  TextFieldProps,
  Theme,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import { inputClasses } from '@mui/material/Input';
import { menuItemClasses } from '@mui/material/MenuItem';
import { selectClasses } from '@mui/material/Select';
import { addDays, isAfter, isBefore, parseISO, subDays } from 'date-fns';
import { useHistory, useParams } from 'react-router-dom';

import DateRangePicker, { DateRangeValues, NullableDate } from '../../../../components/DateRangePicker';
import NoResourcePage from '../../../../components/NoResourcePage';
import SearchBar from '../../../../components/SearchBar';
import useApp from '../../../../hooks/useApp';
import useDebounce from '../../../../hooks/useDebounce';
import { isValidJwt } from '../../../../lib/jwt';
import { QuickStart } from '../../../../lib/routes';
import useQuickStartApi, { GetLinkedUsers, QuickStartLink } from '../../api/useQuickStartApi';
import RequestProductAccess from '../RequestProductAccess';
import QuickStartTable from './QuickStartTable';

const styles: Record<string, SxProps<Theme>> = {
  select: {
    [`& .${selectClasses.select}`]: {
      color: 'primary.main',
      pl: 1,
    },
    [`& .${selectClasses.icon}`]: {
      paddingLeft: '6px',
      color: 'primary.main',
    },
    '&:before': {
      borderColor: 'primary.main',
    },
    [`&:hover:not(.${inputClasses.disabled}):before`]: {
      borderColor: 'primary.dark',
    },
  },
  menuItem: {
    [`.${menuItemClasses.root}`]: {
      color: 'primary.main',
    },
  },
};

const LinkedUserDropDown = ({ customerApps }: { customerApps?: QuickStartLink[] }) => {
  const history = useHistory();
  const params = useParams<{ customer_app_id: string }>();

  const currentCustomerAppId = params.customer_app_id;

  // if customer apps is undefined or the current URL's ID does not exist in the customer app ids, do not display drop down
  if (customerApps?.find((c) => c.customer_app_id === currentCustomerAppId) === undefined) return null;

  return (
    <FormControl variant="standard" sx={{ minWidth: 80, alignSelf: 'flex-start', paddingBottom: 2 }} size="small">
      <Select
        value={currentCustomerAppId}
        onChange={(e) => {
          history.push(`${QuickStart.LinkedUsers}/${e.target.value}`);
        }}
        sx={styles.select}
        MenuProps={{ sx: styles.menuItem }}
      >
        {customerApps.map(({ customer_app_name, customer_app_id }) => (
          <MenuItem key={customer_app_name} value={customer_app_id}>
            {customer_app_name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export const QuickStartLinkedUsers = (): ReactElement => {
  const {
    app: { tokens, env },
  } = useApp();
  const history = useHistory();

  const currentToken = tokens.get(env) ?? '';
  const params = useParams<{ customer_app_id: string }>();
  const { data } = GetLinkedUsers(params.customer_app_id);

  // Search Bar State
  const [search, setSearch] = useState('');
  const debouncedSearchTerm = useDebounce(search, 500);
  const formattedSearch = debouncedSearchTerm.trim().toLowerCase();

  // Date range state
  const [dateRangeValue, setDateRangeValue] = useState<DateRangeValues>([null, null]);

  const filteredUsers = useMemo(() => {
    const users = data?.linked_users ?? [];

    if (users.length === 0) {
      return users;
    }

    // filter by search
    let filteredUsers = users.filter(
      (user) =>
        formattedSearch.length === 0 ||
        user.status.toLowerCase().match(formattedSearch) ||
        user.institution_id.toLowerCase().match(formattedSearch) ||
        user.user_id.toLowerCase().match(formattedSearch),
    );

    // filter by date
    const [startDate, endDate] = dateRangeValue;
    if (startDate !== null && endDate !== null) {
      filteredUsers = filteredUsers.filter(
        ({ update_date }) =>
          isAfter(parseISO(update_date), subDays(startDate, 1)) && isBefore(parseISO(update_date), addDays(endDate, 1)),
      );
    } else if (startDate === null && endDate !== null) {
      filteredUsers = filteredUsers.filter(({ update_date }) => isBefore(parseISO(update_date), addDays(endDate, 1)));
    } else if (startDate !== null && endDate === null) {
      filteredUsers = filteredUsers.filter(({ update_date }) => isAfter(parseISO(update_date), subDays(startDate, 1)));
    }

    return filteredUsers;
  }, [formattedSearch, data?.linked_users, dateRangeValue]);

  const handleSearch = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const { data: quickStartInfo, isAuthError } = useQuickStartApi();

  useEffect(() => {
    if (quickStartInfo === undefined || quickStartInfo.length === 0) {
      return;
    }
    history.push(`${QuickStart.LinkedUsers}/${quickStartInfo[0].customer_app_id}`);
  }, [quickStartInfo, history]);

  if (isValidJwt(currentToken) && isAuthError) {
    return <RequestProductAccess />;
  }

  if (quickStartInfo === undefined || quickStartInfo.length === 0) {
    return (
      <NoResourcePage
        title="No Quickstart Apps"
        subtitle="This team does not have any quickstart link. Please create one to get started"
        buttonText="Create Quickstart Link"
        buttonHref={QuickStart.New}
      />
    );
  }

  return (
    <Container maxWidth="lg">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <LinkedUserDropDown customerApps={quickStartInfo} />
        <Typography variant="h1" component="h1" align="center" gutterBottom>
          Users
        </Typography>
        <Typography variant="h6" component="h6" align="center" gutterBottom>
          Users connected with Quickstart Links
        </Typography>
        <Box sx={{ marginTop: 2 }}>
          <Stack gap={3} sx={{ flexDirection: { sm: 'column', md: 'row' }, marginBottom: 2 }}>
            <SearchBar onSearch={handleSearch} />
            <DateRangePicker
              values={dateRangeValue}
              views={['day']}
              inputFormat="yyyy-MM-dd"
              onStartDateChange={(newValue: NullableDate) => {
                setDateRangeValue([newValue, dateRangeValue[1]]);
              }}
              onEndDateChange={(newValue: NullableDate) => {
                setDateRangeValue([dateRangeValue[0], newValue]);
              }}
              renderInput={(params: TextFieldProps) => (
                <Box sx={{ width: 1, backgroundColor: 'common.white' }}>
                  <TextField
                    fullWidth
                    {...params}
                    sx={{
                      [`& .${outlinedInputClasses.root}:hover > fieldset`]: { borderColor: 'primary.dark' },
                    }}
                  />
                </Box>
              )}
            />
          </Stack>
          <QuickStartTable users={filteredUsers ?? []} />
        </Box>
      </Box>
    </Container>
  );
};

import { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useLocation } from 'react-router-dom';

import LoadingOverlay from '../../../components/LoadingOverlay';
import useApp from '../../../hooks/useApp';
import useTeam from '../../../hooks/useTeam';
import { getErrorMessage } from '../../../lib/error';
import { getSDKClient, IntegrationStatus, XeroDetails } from '../../../lib/sdk';
import XeroConnected from './Connected';
import XeroUnconnected from './Unconnected';
import XeroUserActionReq from './UserActionRequired';

type XeroRouterProps = {
  /**
   * This may not be present if the user has no customer apps
   */
  customerAppId?: string;
};

export default function XeroRouter(props: XeroRouterProps): JSX.Element {
  const { app } = useApp();
  const { team } = useTeam();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(true);
  const [hasXeroIntegration, setHasXeroIntegration] = useState<boolean>(false);
  const [appLive, setAppLive] = useState<boolean | undefined>(undefined);
  const [xeroDetails, setXeroDetails] = useState<XeroDetails | null>(null);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search.substring(1));
  const xeroConnectionSessionId = searchParams.get('xero_connection_session_id');

  const checkIfXeroIsConnected = useCallback(
    async (token: string, env: string) => {
      const sdk = getSDKClient(env, token);
      try {
        const customerInfo = await sdk.getCustomerInfo(team.team_id);
        if (customerInfo === null) {
          return;
        }
        const xeroInfo = await sdk.getXeroConnectionStatus(customerInfo.customer_app_id);
        setHasXeroIntegration(xeroInfo.xero_status === IntegrationStatus.CONNECTED);
        setAppLive(customerInfo?.real_enabled);
        setXeroDetails(xeroInfo.xero_details ?? null);
      } catch (err) {
        enqueueSnackbar(getErrorMessage(err), { variant: 'error' });
      } finally {
        setLoading(false);
      }
    },
    [enqueueSnackbar, team],
  );

  useEffect(() => {
    const { tokens, env } = app;
    const currentToken = tokens.get(env) ?? '';
    checkIfXeroIsConnected(currentToken, env);
  }, [app, checkIfXeroIsConnected]);

  if (loading === true) {
    return <LoadingOverlay />;
  }

  if (hasXeroIntegration && props.customerAppId !== undefined) {
    if (xeroDetails === null) {
      return <></>;
    }
    return <XeroConnected xeroDetails={xeroDetails} customerAppId={props.customerAppId} isLive={appLive} />;
  }

  // if not connected, we should check the URL params and see if this is a redirect from oauth
  // if the returned search param contains ?success=true then we have authenticated correctly
  if (searchParams.get('success') === 'true' && xeroConnectionSessionId !== null) {
    return (
      <XeroUserActionReq
        teamId={team.team_id}
        customerAppId={props.customerAppId}
        xeroConnectionSessionId={xeroConnectionSessionId}
        isLive={searchParams.get('live') === 'true'}
      />
    );
  }

  return <XeroUnconnected teamId={team.team_id} isLive={appLive} />;
}

import { useContext, useState } from 'react';

import { ErrorMessage } from '@hookform/error-message';
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { AppContext } from '../../contexts/AppContext';
import { redirectToAuth0 } from '../../lib/auth0';
import { countries } from '../../lib/countries';
import { getEmail } from '../../lib/jwt';
import Routes from '../../lib/routes';
import { getSDKClient, RegisterDeveloperParams } from '../../lib/sdk';

type Props = {
  setIsLoading: (b: boolean) => void;
};

const StyledErrorMessage = styled(ErrorMessage)({
  color: '#F44336',
});

const Consent = styled(Typography)({
  cursor: 'pointer',
});

/*
 * This component is used for displaying the register developer form
 */
export default function RegisterDeveloper({ setIsLoading }: Props): JSX.Element {
  const { app } = useContext(AppContext);
  const [apiError, setApiError] = useState<boolean>(false);
  const [apiErrorMsg, setApiErrorMsg] = useState<string>('');
  // const [customerInfo, setCustomerInfo] = useState<CustomerInfo | null>(null);
  const [consent, setConsent] = useState(true);
  const history = useHistory();

  const token = app.tokens.get(app.env);

  const email = getEmail(token);
  const onSubmit = async (params: RegisterDeveloperParams) => {
    setIsLoading(true);
    try {
      await getSDKClient(app.env, String(token)).registerDeveloper({ ...params, email, consent_to_emails: consent });
      redirectToAuth0(app.env);
    } catch (err) {
      if (err.response) {
        // if a similar logic is used anywhere then we should consider moving this logic to sdk
        if (err.response.status === 401) {
          history.push(Routes.Logout, {
            message: 'You have been logged out due to inactivity. Please sign in again to continue.',
          });
        }
        let msg = 'Unexpected API error';
        if (err.response.data) {
          const data = err.response.data;
          if (data.error?.message) {
            msg = data.error?.message;
          } else if (data.message) {
            msg = data.message;
          }
        }
        setApiError(true);
        setApiErrorMsg(msg);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<RegisterDeveloperParams>();

  return (
    <>
      {apiError && <Alert severity="error">{apiErrorMsg}</Alert>}
      <Typography variant="h5" component="h5" sx={{ mb: 2 }}>
        Please register to continue
      </Typography>
      <Paper sx={{ p: 3 }}>
        <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              textAlign: 'left',
              dispay: 'flex',
              flexDirection: 'column',
              placeConent: 'center',
              gap: 1,
            }}
          >
            <StyledErrorMessage errors={errors} name="first_name" as="p" />
            <FormControl fullWidth>
              <TextField
                id="first_name"
                label="First name"
                variant="outlined"
                {...register('first_name', { required: 'First name is required' })}
              />
            </FormControl>

            <StyledErrorMessage errors={errors} name="last_name" as="p" />
            <FormControl fullWidth>
              <TextField
                id="last_name"
                label="Last name"
                variant="outlined"
                {...register('last_name', { required: 'Last name is required' })}
              />
            </FormControl>

            <StyledErrorMessage errors={errors} name="email" as="p" />
            <FormControl fullWidth>
              <TextField id="email" label="Email" variant="outlined" type="email" value={email} disabled />
            </FormControl>

            <StyledErrorMessage errors={errors} name="country" as="p" />
            <FormControl fullWidth>
              <Autocomplete
                autoHighlight
                options={countries}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Country"
                    variant="outlined"
                    {...register('country', { required: 'Country is required' })}
                    sx={{ mb: 2 }}
                  />
                )}
              />
            </FormControl>

            <FormControl fullWidth>
              <TextField id="organization" label="Organization" variant="outlined" {...register('organization')} />
            </FormControl>

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox
                checked={consent}
                id="consent_to_emails"
                color="primary"
                onClick={() => setConsent(!consent)}
                sx={{ pl: 0 }}
              />
              <Consent onClick={() => setConsent(!consent)}>I accept to receive marketing emails from Finverse</Consent>
            </Box>
          </Box>
          <Button sx={{ mt: 2 }} variant="contained" color="primary" type="submit">
            Register
          </Button>
        </form>
      </Paper>
    </>
  );
}

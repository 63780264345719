import { ReactElement, useEffect, useState, MouseEventHandler, useMemo } from 'react';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import { inputClasses } from '@mui/material/Input';
import MenuItem, { menuItemClasses } from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select, { SelectChangeEvent, selectClasses } from '@mui/material/Select';
import { SxProps, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import { Control, FieldError, useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';

import { FormInputText } from '../../components/form-components/FormInputText';
import NoResourcePage from '../../components/NoResourcePage';
import useApp from '../../hooks/useApp';
import useResponsive from '../../hooks/useResponsive';
import { getErrorMessage } from '../../lib/error';
import Routes from '../../lib/routes';
import { CustomizationData, getSDKClient, NewCustomization, UpdatedCustomization } from '../../lib/sdk';
import { GetCustomerApps } from '../../lib/useDeveloperApi';

const styles: Record<string, SxProps<Theme>> = {
  select: {
    [`& .${selectClasses.select}`]: {
      color: 'primary.main',
      pl: 1,
    },
    [`& .${selectClasses.icon}`]: {
      paddingLeft: '6px',
      color: 'primary.main',
    },
    '&:before': {
      borderColor: 'primary.main',
    },
    [`&:hover:not(.${inputClasses.disabled}):before`]: {
      borderColor: 'primary.dark',
    },
  },
  menuItem: {
    [`.${menuItemClasses.root}`]: {
      color: 'primary.main',
    },
  },
};

type AppNameDropDownProps = {
  currentAppId: string;
  quickstartApps?: QuickstartApp[];
  onChange: (x: SelectChangeEvent<string>) => Promise<void>;
};

const AppNameDropDown = ({ currentAppId, quickstartApps, onChange }: AppNameDropDownProps): JSX.Element | null => {
  if (quickstartApps === undefined) return null;
  return (
    <FormControl variant="standard" sx={{ minWidth: 80, alignSelf: 'flex-start', paddingBottom: 2 }} size="small">
      <Select
        id="select-app-name-select"
        value={currentAppId}
        label="App Name"
        onChange={onChange}
        sx={styles.select}
        MenuProps={{ sx: styles.menuItem }}
      >
        {quickstartApps.map((qsApp) => {
          return (
            <MenuItem
              data-cid={qsApp.customer_app_id}
              key={`menuitem_${qsApp.customer_app_id}`}
              value={qsApp.customer_app_id}
            >
              {qsApp.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

type CustomizationInputProps = {
  heading: string | JSX.Element;
  subHeading: string | JSX.Element;
  /**
   * If there is an associated input box
   */
  formInput?: {
    name: string;
    label: string;
    control: Control<CustomizationFormInput, any>;
    error?: FieldError;
    required: boolean;
  };
};

const CustomizationInput = ({ heading, subHeading, formInput }: CustomizationInputProps): JSX.Element => {
  return (
    <Paper sx={{ display: 'flex', flexDirection: 'column', gap: 1, padding: 3 }}>
      {typeof heading === 'string' ? (
        <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
          {heading}
        </Typography>
      ) : (
        heading
      )}
      {typeof subHeading === 'string' ? (
        <Typography gutterBottom variant="subtitle1">
          {subHeading}
        </Typography>
      ) : (
        subHeading
      )}
      {formInput && (
        <>
          <FormInputText
            name={formInput.name}
            label={formInput.label}
            rules={{ required: formInput.required }}
            control={formInput.control}
            id="outlined-basic"
            variant="outlined"
          />
          {formInput.error?.type === 'required' && <Alert severity="error">This field is required</Alert>}
        </>
      )}
    </Paper>
  );
};

const Customizations = (): ReactElement => {
  // Extract the react form stuffs
  const history = useHistory();
  const { isDesktop } = useResponsive();
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<CustomizationFormInput>({
    defaultValues: {
      customization_name: '',
      display_name: '',
    },
  });

  const { app } = useApp();
  const { tokens, env } = app;
  const { enqueueSnackbar } = useSnackbar();
  const currentToken = tokens.get(env) ?? '';

  const sdk = useMemo(() => getSDKClient(env, currentToken), [env, currentToken]);
  const { data } = GetCustomerApps();
  const [apps, setApps] = useState<QuickstartApp[]>([]);
  const [currentAppId, setCurrentAppId] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [existingCustomization, setExistingCustomization] = useState<CustomizationData | null>(null);

  useEffect(() => {
    if (data !== undefined) {
      setApps(data);
      if (data.length > 0) {
        setCurrentAppId(data[0].customer_app_id);
      } else {
        setCurrentAppId('');
      }
    }
  }, [data]);

  useEffect(() => {
    if (currentAppId === '') {
      setExistingCustomization(null);
      setValue('customization_name', '');
      setValue('display_name', '');
      return;
    }
    sdk.getCustomizations(currentAppId).then((cuzs) => {
      // Currently UI only supports one customization
      if (cuzs.length > 0) {
        setExistingCustomization(cuzs[0]);
        setValue('customization_name', cuzs[0].customization_name);
        setValue('display_name', cuzs[0].display_name);
      } else {
        // Wipe all customization info
        setExistingCustomization(null);
        setValue('customization_name', '');
        setValue('display_name', '');
      }
    });
  }, [currentAppId, sdk, setValue]);

  const onClickEmail: MouseEventHandler<HTMLAnchorElement> = (e) => {
    window.location.href = 'mailto:sales@finverse.com';
    e.preventDefault();
  };

  const handleQuickStartAppSelect = async (x: SelectChangeEvent<string>) => {
    setCurrentAppId(x.target.value);
  };

  const onSubmit = async (data: CustomizationFormInput) => {
    setDisabled(true);

    // If existingCustomization is null, then its the case of creating a new customization
    if (existingCustomization === null) {
      const newCustomization: NewCustomization = {
        logo_id: '', // This wont be exposed via frontend, and will set as empty.
        display_name: data.display_name,
        customization_name: data.customization_name,
      };

      try {
        const result = await sdk.createCustomization(currentAppId, newCustomization);

        // Since it is new, we need to set the customizationId in case they want to update it
        setExistingCustomization({
          ...newCustomization,
          customization_id: result.customization_id,
        });
        enqueueSnackbar('Created customization.', { variant: 'success' });
      } catch (e) {
        if (e.response && e.response.status === 401) {
          history.push(Routes.Logout, {
            message: 'You have been logged out due to inactivity. Please sign in again to continue.',
          });
        }
        enqueueSnackbar(getErrorMessage(e), { variant: 'error' });
      }
    } else {
      const updatedCustomization: UpdatedCustomization = {
        logo_id: existingCustomization.logo_id, // If there is a logo, we keep it (do not support updating logo via UI)
        display_name: data.display_name,
        customization_name: data.customization_name,
      };

      try {
        await sdk.updateCustomization(currentAppId, existingCustomization.customization_id, updatedCustomization);
        enqueueSnackbar('Updated customization.', { variant: 'success' });
      } catch (e) {
        if (e.response && e.response.status === 401) {
          history.push(Routes.Logout, {
            message: 'You have been logged out due to inactivity. Please sign in again to continue.',
          });
        }
        enqueueSnackbar(getErrorMessage(e), { variant: 'error' });
      }

      setExistingCustomization({
        ...updatedCustomization,
        customization_id: existingCustomization.customization_id,
      });
    }

    setDisabled(false);
  };

  if (currentAppId === '') {
    return (
      <NoResourcePage
        title="Customizations"
        subtitle="This team does not have any API credentials associated with it. Please create one to get started"
        buttonText="Get API Credentials"
        buttonHref={Routes.Application.Settings}
      />
    );
  }

  return (
    <Container maxWidth="lg" sx={{ display: 'flex', flexDirection: 'column' }}>
      <AppNameDropDown currentAppId={currentAppId} quickstartApps={apps} onChange={handleQuickStartAppSelect} />
      <Typography variant="h1" component="h1" align="center" sx={{ marginBottom: 4 }}>
        Customizations
      </Typography>
      <Box sx={{ display: 'flex', gap: { lg: 5 }, flexDirection: { xs: 'column', lg: 'row' } }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <CustomizationInput
            heading="Customization name"
            subHeading="Used to identify customizations in customer portal"
            formInput={{
              name: 'customization_name',
              label: 'Customization Name',
              control: control,
              error: errors?.customization_name,
              required: true,
            }}
          />
          <CustomizationInput
            heading="Display name"
            subHeading={
              <Typography gutterBottom variant="subtitle1">
                {`Your company's user-facing name, displayed to users in the Finverse UI`}
              </Typography>
            }
            formInput={{
              name: 'display_name',
              label: 'Display Name',
              control: control,
              required: false,
            }}
          />
          <CustomizationInput
            heading="Logo"
            subHeading={
              <>
                <Typography variant="subtitle1">
                  Contact{' '}
                  <Box component={Link} to="#" onClick={onClickEmail}>
                    sales@finverse.com
                  </Box>{' '}
                  {`to add your company's logo to the Finverse UI`}
                </Typography>
                {existingCustomization?.logo_id && (
                  <Box
                    component="img"
                    src={'https://storage.googleapis.com/' + existingCustomization?.logo_id}
                    alt="image showing how customizations will effect finverse link"
                    sx={{
                      maxWidth: 350,
                      height: 'auto',
                      width: '100%',
                      alignSelf: 'center',
                      margin: 2,
                    }}
                  />
                )}
              </>
            }
          />
          {isDesktop && (
            <Button
              color="primary"
              variant="contained"
              disabled={disabled}
              onClick={handleSubmit(onSubmit)}
              sx={{ width: '70%', alignSelf: 'center' }}
            >
              Submit
            </Button>
          )}
        </Box>
        <Paper
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: 3,
            marginTop: { xs: 3, lg: 0 },
            marginBottom: { xs: 3, lg: 0 },
          }}
        >
          <Typography gutterBottom variant="h2" sx={{ fontWeight: 'bold' }}>
            Sample layout
          </Typography>
          <Typography sx={{ marginBottom: 2 }}>
            Your customization inputs will be reflected in the Finverse UI below (sample screenshot for payments)
          </Typography>
          <Box
            component="img"
            src="/assets/customizations/sample_payment_view.png"
            alt="image showing how customizations will effect finverse link"
            sx={{
              maxWidth: 350,
              height: 'auto',
              width: '100%',
              alignSelf: 'center',
              margin: 2,
            }}
          />
        </Paper>
      </Box>
      {!isDesktop && (
        <Button
          onClick={handleSubmit(onSubmit)}
          fullWidth
          color="primary"
          variant={'contained'}
          disabled={disabled}
          sx={{ display: 'block' }}
        >
          Submit
        </Button>
      )}
    </Container>
  );
};

export default Customizations;

// Only the fields via form input
type CustomizationFormInput = {
  customization_name: string;
  display_name: string;
};

type QuickstartApp = {
  client_id: string;
  customer_app_id: string;
  name: string;
  redirect_uris: string[];
  webhook_uris: string[];
};

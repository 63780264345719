import { useEffect } from 'react';

import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Switch, useLocation, Route } from 'react-router-dom';

import { defaultEnv } from '../../contexts/AppContext/AppContext';
import useApp from '../../hooks/useApp';
import { getAuth0CallbackUri, getAuth0CallbackUriForSignup, getAuth0CallbackUriToConnection } from '../../lib/auth0';
import Routes from '../../lib/routes';
import storage from '../../lib/storage';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme: Theme) => ({
  background: {
    position: 'absolute',
    height: '45rem',
    width: '45rem',
    top: 0,
    right: 0,
    backgroundImage: 'url(./background.jpeg)',
    backgroundSize: '45rem',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right top',
    opacity: 0.2,
  },
  title: {
    marginBottom: '4vh',
  },
  subtitle: {
    marginBottom: '2vh',
  },
  alert: {
    margin: '0 !important',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.primary,
    height: '80vh',
    '& div': {
      marginBottom: '3vh',
    },
  },
  a: {
    textDecoration: 'none',
    color: '#FFFFFF',
  },
  signup: {
    textDecoration: 'none',
    color: '#2196f3',
  },
  button: {
    marginTop: '2vh',
  },
}));

type Data = {
  message?: string;
};

export function Login(): JSX.Element {
  const { app, setApp } = useApp();
  const location = useLocation<Data>();
  const classes = useStyles();
  const origin = `${window.location.origin}/callback`;

  useEffect(() => {
    storage.clear();
    const tokens = new Map<string, string>();
    setApp({ env: defaultEnv, tokens });
  }, [location, setApp]);

  return (
    <>
      <div className={classes.background}></div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {location.state?.message && <Alert severity="error">{location.state?.message}</Alert>}
          <div className={classes.paper}>
            <Typography variant="h1" component="h1" className={classes.title}>
              Finverse Customer Portal
            </Typography>
            <Typography variant="h5" component="h5" className={classes.subtitle}>
              Welcome Back
            </Typography>
            <div>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                href={getAuth0CallbackUri(app.env)}
              >
                Sign in
              </Button>
            </div>
            <div>
              {"Don't"} have an account?{' '}
              <a className={classes.signup} href={getAuth0CallbackUriForSignup(app.env)}>
                Sign up
              </a>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default function LoginRouter(): JSX.Element {
  const { app } = useApp();

  return (
    <Switch>
      <Route
        path={Routes.Login.XeroLogin}
        component={() => {
          window.location.href = getAuth0CallbackUriToConnection(app.env, 'Xero');
          return null;
        }}
      />
      <Route path={Routes.Login.Base}>
        <Login />
      </Route>
    </Switch>
  );
}

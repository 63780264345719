import { useCallback, useState } from 'react';

import { Container, Typography, Paper, TextField, Grid, Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';

import useApp from '../../hooks/useApp';
import useTeam from '../../hooks/useTeam';
import { getErrorMessage } from '../../lib/error';
import Routes from '../../lib/routes';
import { getSDKClient } from '../../lib/sdk';

const AddTeam = () => {
  const { app } = useApp();
  const { refreshTeamsThenSet } = useTeam();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [teamName, setTeamName] = useState<string>('');

  const addTeam = useCallback(
    async (teamName: string) => {
      const { env, tokens } = app;
      const token = tokens.get(env) ?? '';
      const sdk = getSDKClient(env, token);
      try {
        const newTeam = await sdk.createTeam(teamName);

        enqueueSnackbar(`Created new team: ${teamName}.`, { variant: 'success' });
        await refreshTeamsThenSet(newTeam.team_id);
        history.push(Routes.Teams.Base);
      } catch (err) {
        const errorMessage = getErrorMessage(err);
        enqueueSnackbar(errorMessage, { variant: 'error' });
      }
    },
    [app, history, refreshTeamsThenSet, enqueueSnackbar],
  );

  return (
    <Container maxWidth="lg" sx={{ flexGrow: 1, p: 4 }}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          addTeam(teamName);
        }}
      >
        <Grid container spacing={3} direction="column" display="flex">
          <Grid item>
            <Typography variant="h1" component="h1" gutterBottom>
              New Team
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h5" component="h5" fontWeight="bold" gutterBottom>
              Create a new team
            </Typography>
          </Grid>
          <Grid item>
            <Paper sx={{ p: 4 }}>
              <TextField
                id="team_name"
                variant="outlined"
                fullWidth
                label="Team Name"
                autoComplete="off"
                placeholder="Pied Piper Inc - Data Team"
                helperText="This is the team name that will be displayed to all members"
                value={teamName}
                onChange={(e) => setTeamName(e.target.value)}
              />
            </Paper>
          </Grid>
          <Grid item justifyContent="center" display="flex">
            <Button size="large" variant="contained" type="submit">
              Create Team
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default AddTeam;

export const BASIC_USER = 'basicUser';

export const allTeamRoles: string[] = [
  'teamOwner',
  'teamManager',
  'apiSettingsEditor',
  'customizationsEditor',
  'paymentsViewer',
  'quickstartLinkEditor',
  'quickstartDataAccessor',

  'basicUser',
];

type RoleInformation = {
  name: string;
  info: string;
};

export const roleToRoleInfo: Record<string, RoleInformation> = {
  teamOwner: { name: 'Team Owner', info: 'Admin role (full permissions)' },
  teamManager: { name: 'Team Manager', info: 'Add and remove team members; assign roles' },
  quickstartLinkEditor: { name: 'Quickstart Link Editor', info: 'Create Quickstart Links; modify related settings' },
  customizationsEditor: {
    name: 'Customization Editor',
    info: 'Create and manage customizations of the Finverse Link UI',
  },
  apiSettingsEditor: {
    name: 'Api Settings Editor',
    info: 'Generate API keys; modify API settings',
  },
  quickstartDataAccessor: {
    name: 'Quickstart Data Accessor',
    info: 'Download user data connected through Quickstart Links (warning: sensitive data access)',
  },
  paymentsViewer: {
    name: 'Payments Viewer',
    info: 'View payments dashboard (including senders/recipients, mandates, payments)',
  },
  basicUser: { name: 'Basic User', info: 'Can read non-sensitive information only.' },
};

export const displayRoles = (roles: string[]): string => {
  if (roles.length === 1 && roles[0] === BASIC_USER) {
    return roleToRoleInfo[roles[0]].name;
  }

  // remove basic user
  return roles
    .filter((role) => role != BASIC_USER)
    .map((role) => roleToRoleInfo[role]?.name ?? '')
    .filter((role) => role !== '')
    .join(', ');
};

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

const imageOptions = {
  src: `${process.env.PUBLIC_URL}/quick-start/customization.png`,
  alt: 'Customize quickstart app',
};

export default function QuickStartCustomizations() {
  return (
    <Container maxWidth="md">
      <Typography variant="h1" component="h1" align="center" gutterBottom>
        UI Customizations
      </Typography>
      <Typography variant="body1" component="h6" align="center" gutterBottom>
        You can customize Finverse UI to show your company logo and/or modify the app name shown to end-users. The
        default UI is shown below. Contact
        <Link href="mailto:sales@finverse.com" target="_blank" sx={{ ml: 1, mr: 1 }}>
          sales@finverse.com
        </Link>
        to customize this screen for your Quickstart Links.
      </Typography>
      <Grid
        sx={{
          mt: 8,
          p: 5,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box sx={{ maxWidth: '25rem', width: '100%' }} component="img" {...imageOptions} />
      </Grid>
    </Container>
  );
}

import SvgIcon from '@mui/material/SvgIcon';

/**
 * This file contains the bullet icons. Bullet icons 1-3 were created by @amagow in PR #91 and are undocumented as to how they were created
 * Bullet #4 was created by @jcai and is our stylistic "standard"
 *
 * To recreate a similar bullet icon:
 * 1. Use figma, create a circle with the appropriate background color of height and width 24
 * 2. Add a text element (centered) that contains the appropriate text and give it a font size of 12 with the font being Roboto
 * 3. Export this as svg and copy the file contents. Adapt them to fit the code style of existing components
 */

export function BulletOne(): JSX.Element {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="12" fill="#2196F3" />
      <path
        d="M13.2024 7.88811V16.6154H12.1455V8.99606H12.0944L9.96373 10.4108V9.33697L12.1455 7.88811H13.2024Z"
        fill="white"
      />
    </SvgIcon>
  );
}

export function BulletTwo(): JSX.Element {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="12" fill="#2196F3" />
      <path
        d="M8.90341 16.6154V15.8483L11.7841 12.6949C12.1222 12.3256 12.4006 12.0046 12.6193 11.7319C12.8381 11.4563 13 11.1978 13.1051 10.9563C13.2131 10.712 13.267 10.4563 13.267 10.1892C13.267 9.88243 13.1932 9.6168 13.0455 9.39237C12.9006 9.16794 12.7017 8.99464 12.4489 8.87249C12.196 8.75033 11.9119 8.68925 11.5966 8.68925C11.2614 8.68925 10.9688 8.75885 10.7188 8.89805C10.4716 9.03442 10.2798 9.22618 10.1435 9.47334C10.0099 9.7205 9.94318 10.0103 9.94318 10.3427H8.9375C8.9375 9.83129 9.0554 9.38243 9.29119 8.99606C9.52699 8.6097 9.84801 8.30856 10.2543 8.09266C10.6634 7.87675 11.1222 7.76879 11.6307 7.76879C12.142 7.76879 12.5952 7.87675 12.9901 8.09266C13.3849 8.30856 13.6946 8.59976 13.919 8.96624C14.1435 9.33271 14.2557 9.74038 14.2557 10.1892C14.2557 10.5103 14.1974 10.8242 14.081 11.131C13.9673 11.435 13.7685 11.7745 13.4844 12.1495C13.2031 12.5216 12.8125 12.9762 12.3125 13.5131L10.3523 15.6097V15.6779H14.4091V16.6154H8.90341Z"
        fill="white"
      />
    </SvgIcon>
  );
}

export function BulletThree(): JSX.Element {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="12" fill="#2196F3" />
      <path
        d="M11.8523 16.7347C11.2898 16.7347 10.7884 16.6381 10.348 16.4449C9.91051 16.2517 9.5625 15.9833 9.30398 15.6395C9.0483 15.2929 8.90909 14.891 8.88636 14.4336H9.96023C9.98295 14.7148 10.0795 14.9577 10.25 15.1623C10.4205 15.364 10.6435 15.5202 10.919 15.631C11.1946 15.7418 11.5 15.7972 11.8352 15.7972C12.2102 15.7972 12.5426 15.7319 12.8324 15.6012C13.1222 15.4705 13.3494 15.2887 13.5142 15.0557C13.679 14.8228 13.7614 14.5529 13.7614 14.2461C13.7614 13.925 13.6818 13.6424 13.5227 13.3981C13.3636 13.1509 13.1307 12.9577 12.8239 12.8185C12.517 12.6793 12.142 12.6097 11.6989 12.6097H11V11.6722H11.6989C12.0455 11.6722 12.3494 11.6097 12.6108 11.4847C12.875 11.3597 13.081 11.1836 13.2287 10.9563C13.3793 10.729 13.4545 10.462 13.4545 10.1552C13.4545 9.8597 13.3892 9.6026 13.2585 9.38385C13.1278 9.1651 12.9432 8.99464 12.7045 8.87249C12.4688 8.75033 12.1903 8.68925 11.8693 8.68925C11.5682 8.68925 11.2841 8.74464 11.017 8.85544C10.7528 8.96339 10.5369 9.12106 10.3693 9.32845C10.2017 9.533 10.1108 9.78016 10.0966 10.0699H9.07386C9.09091 9.61254 9.22869 9.21197 9.48722 8.86822C9.74574 8.52163 10.0838 8.25175 10.5014 8.05856C10.9219 7.86538 11.3835 7.76879 11.8864 7.76879C12.4261 7.76879 12.8892 7.87817 13.2756 8.09692C13.6619 8.31283 13.9588 8.59834 14.1662 8.95345C14.3736 9.30856 14.4773 9.69209 14.4773 10.104C14.4773 10.5955 14.348 11.0145 14.0895 11.3611C13.8338 11.7077 13.4858 11.9478 13.0455 12.0813V12.1495C13.5966 12.2404 14.027 12.4748 14.3366 12.8526C14.6463 13.2276 14.8011 13.6921 14.8011 14.2461C14.8011 14.7205 14.6719 15.1466 14.4134 15.5245C14.1577 15.8995 13.8082 16.1949 13.3651 16.4108C12.9219 16.6267 12.4176 16.7347 11.8523 16.7347Z"
        fill="white"
      />
    </SvgIcon>
  );
}

export function BulletFour(): JSX.Element {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="12" fill="#2196F3" />
      <path
        d="M14.4746 13.1289V14.0195H8.31055V13.3809L12.1309 7.46875H13.0156L12.0664 9.17969L9.54102 13.1289H14.4746ZM13.2852 7.46875V16H12.2012V7.46875H13.2852Z"
        fill="white"
      />
    </SvgIcon>
  );
}

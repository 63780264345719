import { useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { SxProps, Theme } from '@mui/material/styles';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import { useForm, UseFormRegisterReturn } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { BuildLinkParams, useBuildLink } from '../../../../features/Quickstart/api/useQuickStartApi';
import useResponsive from '../../../../hooks/useResponsive';
import { copyToClipboard } from '../../../../lib/copy';
import Routes from '../../../../lib/routes';

type InputRowProps = {
  heading: string;
  hasCopyIcon?: boolean;
  subTitle?: string;
  label?: string;
  placeholder?: string;
  value?: string;
  disabled?: boolean;
  registerReturn?: UseFormRegisterReturn;
  sx?: SxProps<Theme>;
} & TextFieldProps;

const InputRow = ({
  heading,
  registerReturn,
  hasCopyIcon,
  subTitle,
  label,
  placeholder,
  value,
  sx,
  disabled = false,
}: InputRowProps) => {
  const { isMobile } = useResponsive();
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Box sx={{ display: 'flex', marginLeft: 3, marginRight: 5, ...sx }}>
      {!isMobile && (
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', marginRight: 3 }}>
          <Typography variant="h5" component="h5">
            {heading}
          </Typography>
          <Typography variant="subtitle2" sx={{ color: 'grey.600' }}>
            {subTitle}
          </Typography>
        </Box>
      )}
      <Box sx={{ flex: 2 }}>
        {isMobile && (
          <Typography variant="subtitle2" sx={{ color: 'grey.600' }}>
            {subTitle}
          </Typography>
        )}
        <Box sx={{ display: 'flex' }}>
          <TextField
            {...registerReturn}
            sx={{
              '& .Mui-disabled': {
                backgroundColor: 'grey.300',
              },
            }}
            placeholder={placeholder}
            value={value}
            fullWidth
            margin="dense"
            id={`linkbuilder-${label}`}
            label={label}
            variant="outlined"
            disabled={disabled}
          />
          {hasCopyIcon && (
            <IconButton
              size="small"
              color="primary"
              aria-label="copy"
              onClick={() => {
                copyToClipboard(value);
                enqueueSnackbar('Copied to clipboard', { autoHideDuration: 1000 });
              }}
              sx={{ ml: 2 }}
            >
              <FileCopyIcon />
            </IconButton>
          )}
        </Box>
      </Box>
    </Box>
  );
};

InputRow.displayName = 'Input Row';

type Props = {
  open: boolean;
  quickstartLinkId: string;
  handleClose: () => void;
  onSuccess?: (quickstartUrl: string) => void;
  onError?: (err: unknown) => void;
};

export default function LinkBuilderDialog({
  open,
  quickstartLinkId,
  handleClose,
  onSuccess,
  onError,
}: Props): JSX.Element {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, register } = useForm<Omit<BuildLinkParams, 'quickstart_link_id'>>();
  const [uniqueLink, setUniqueLink] = useState<string>('Unique Link');
  const { buildLink } = useBuildLink();

  const onSubmit = async ({ user_id, application_id }: Omit<BuildLinkParams, 'quickstart_link_id'>) => {
    try {
      const quickstartUrl = await buildLink({
        quickstart_link_id: quickstartLinkId,
        user_id: user_id?.trim(),
        application_id: application_id?.trim(),
      });
      setUniqueLink(quickstartUrl);
      enqueueSnackbar('Successfully created link', { variant: 'success' });
      onSuccess && onSuccess(quickstartUrl);
    } catch (err) {
      if (err.response) {
        // if a similar logic is used anywhere then we should consider moving this logic to sdk
        if (err.response.status === 401) {
          history.push(Routes.Logout, {
            message: 'You have been logged out due to inactivity. Please sign in again to continue.',
          });
        }
        enqueueSnackbar('An error occurred. Please retry', { variant: 'error' });
        onError && onError(err);
      }
    }
  };

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
      <form noValidate autoCorrect="off" onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle sx={{ textAlign: 'center' }}>
          Link Builder
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ textAlign: 'center', marginBottom: 1 }}>
            Creates unique link to send to your users
          </DialogContentText>
          <InputRow
            heading="Application ID"
            subTitle="Optional"
            label="Application ID"
            sx={{ marginBottom: { xs: 1 } }}
            registerReturn={register('application_id')}
          />
          <InputRow
            heading="User ID"
            subTitle="Optional, a random value will be generated if not supplied"
            label="User ID"
            sx={{ marginBottom: { xs: 1 } }}
            registerReturn={register('user_id')}
          />
          <InputRow
            heading="Unique Link"
            disabled
            hasCopyIcon
            placeholder={uniqueLink === 'Unique Link' ? uniqueLink : ''}
            value={uniqueLink === 'Unique Link' ? '' : uniqueLink}
          />
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', marginBottom: 2 }}>
          <Button variant="contained" type="submit">
            Generate Link
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

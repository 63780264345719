import { useState, useEffect, useCallback } from 'react';

import Box from '@mui/material/Box';
import useEmblaCarousel from 'embla-carousel-react';

import { PrevButton, NextButton } from './CarouselButtons';

const styles = {
  carouselOuter: {
    display: 'flex',
    flex: 1,
    height: 1,
    placeItems: 'center',
    placeContent: 'center',
    flexDirection: 'column',
  },

  carousel: {
    position: 'relative',
    p: 2,
    minWidth: 'min(670px, 100%)',
    maxWidth: 0.7,
    backgroundColor: 'background.paper',
  },
  carouselViewport: {
    width: 1,
    overflow: 'hidden',
    '&.is-draggable': {
      cursor: 'move grab',
    },
    '&.is-dragging': {
      cursor: 'grabbing',
    },
  },
  carouselContainer: {
    display: 'flex',
    ml: -2,
    userSelect: 'none',
    WebkitTouchCallout: 'none',
    KhtmlUserSelect: 'none',
    WebkitTapHighlightColor: 'transparent',
  },
  carouselSlide: {
    position: 'relative',
    minWidth: 1,
    pl: 1,
  },
  carouselSlideInner: {
    minHeight: '320px',
    position: 'relative',
    overflow: 'hidden',
  },
  carouselSlideImage: {
    position: 'absolute',
    display: 'flex',
    placeItems: 'center',
    placeContent: 'center',
    width: 'auto',
    minHeight: '100%',
    minWidth: '100%',
    maxWidth: 'none',
  },
  progress: {
    position: 'relative',
    backgroundColor: 'background.default',
    mt: 1,
    maxWidth: 0.7,
    width: 'calc(100% - 40px)',
    height: '6px',
    overflow: 'hidden',
    borderRadius: '2px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  progressBar: {
    position: 'absolute',
    backgroundColor: 'primary.main',
    width: '100%',
    top: 0,
    bottom: 0,
    left: '-100%',
  },
};

type Props = {
  children: JSX.Element[];
};

export default function ProgressCarousel({ children }: Props) {
  const [viewportRef, carousel] = useEmblaCarousel({
    containScroll: 'trimSnaps',
  });
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
  const [scrollProgress, setScrollProgress] = useState(0);

  const scrollPrev = useCallback(() => carousel && carousel.scrollPrev(), [carousel]);
  const scrollNext = useCallback(() => carousel && carousel.scrollNext(), [carousel]);

  const onSelect = useCallback(() => {
    if (!carousel) return;
    setPrevBtnEnabled(carousel.canScrollPrev());
    setNextBtnEnabled(carousel.canScrollNext());
  }, [carousel]);

  const onScroll = useCallback(() => {
    if (!carousel) return;
    const progress = Math.max(0, Math.min(1, carousel.scrollProgress()));
    setScrollProgress(progress * 100);
  }, [carousel, setScrollProgress]);

  useEffect(() => {
    if (!carousel) return;
    onSelect();
    onScroll();
    carousel.on('select', onSelect);
    carousel.on('scroll', onScroll);
  }, [carousel, onSelect, onScroll]);

  return (
    <Box sx={styles.carouselOuter}>
      <Box sx={styles.carousel}>
        <Box ref={viewportRef} sx={styles.carouselViewport}>
          <Box sx={styles.carouselContainer}>
            {children.map((child, index) => (
              <Box key={index} sx={styles.carouselSlide}>
                <Box sx={styles.carouselSlideInner}>
                  <Box sx={styles.carouselSlideImage}>{child}</Box>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
        <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
        <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
      </Box>
      <Box sx={styles.progress}>
        <Box sx={[styles.progressBar, { transform: `translateX(${scrollProgress}%)` }]} />
      </Box>
    </Box>
  );
}

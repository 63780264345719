import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

type Props = {
  header: string;
  items: string[];
};

export default function BulletPoints({ header, items }: Props): JSX.Element {
  return (
    <>
      <Typography variant="h5" component="h5" sx={{ mb: 2 }}>
        {header}
      </Typography>
      <Paper sx={{ p: 1 }}>
        <List component="nav">
          {items.map((item: string, idx: number) => (
            <ListItem key={idx}>
              <ListItemIcon>
                <CheckCircleIcon color="primary" />
              </ListItemIcon>
              {item}
            </ListItem>
          ))}
        </List>
      </Paper>
    </>
  );
}

import { FC, useEffect, useState } from 'react';

// import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import ReactGA from 'react-ga4';
import { BrowserRouter as Router, Route, Switch, useHistory, useLocation } from 'react-router-dom';

import AuthenticatedHeader from '../components/AuthenticatedHeader';
import ExpiredDialog from '../components/ExpiredDialog';
import Header from '../components/Header';
import ApplicationSettings from '../features/ApplicationSettings';
import Customizations from '../features/Customizations';
import Integrations from '../features/Integrations';
import LoginRouter from '../features/Login';
import QuickStart from '../features/Quickstart';
import Register from '../features/Register';
import Reports from '../features/Reports';
import TeamSettings from '../features/TeamSettings';
import useApp from '../hooks/useApp';
import { redirectToAuth0 } from '../lib/auth0';
import { isEmailVerifyPending, hasRegistered, isExpiredJwt, isValidJwt } from '../lib/jwt';
import Routes from '../lib/routes';
import Callback from './Callback';
import Echo from './Echo';
import Error from './Error';
import Home from './Home';
import Logout from './Logout';
import Overview from './Overview';
import Verified from './Verified';

const trackingId = 'G-EQD77FTV0F';

ReactGA.initialize(trackingId, {
  testMode: process.env.NODE_ENV === 'test', // this will allow unit tests to run
});

const useStyles = makeStyles(() =>
  createStyles({
    main: {
      zIndex: 10,
    },
  }),
);

// Require user logged in and registered
// TODO: find way to DRY the code
const RegisteredRoute: FC<React.ComponentProps<typeof Route>> = (props) => {
  const { app } = useApp();
  const classes = useStyles();
  const history = useHistory();
  const [expiredSession, setExpiredSession] = useState<boolean>(false);

  useEffect(() => {
    async function checkTokenState(env: string, token: string) {
      // if token has expired show expired dialog
      if (isExpiredJwt(token)) {
        setExpiredSession(true);
      } else if (!isValidJwt(token) || isEmailVerifyPending(token)) {
        // redirect to login if token is invalid means user switched env or if the email is pending verify
        return redirectToAuth0(env);
      }
      if (!hasRegistered(currentToken)) {
        return history.push(Routes.Register);
      }
    }

    const { tokens, env } = app;
    const currentToken = tokens.get(env) ?? '';

    checkTokenState(env, currentToken);
  }, [app, history]);

  return (
    <Route {...props}>
      <ExpiredDialog open={expiredSession} />
      <div className={(classes.main, 'pageContainer')}>{props.children}</div>
    </Route>
  );
};

// Require user logged in
const PrivateRoute: FC<React.ComponentProps<typeof Route>> = (props) => {
  const classes = useStyles();
  const { app } = useApp();
  const [expiredSession, setExpiredSession] = useState<boolean>(false);

  useEffect(() => {
    async function checkTokenState(env: string, token: string) {
      // if token has expired show expired dialog
      if (isExpiredJwt(token)) {
        setExpiredSession(true);
      } else if (!isValidJwt(token) || isEmailVerifyPending(token)) {
        // redirect to login if token is invalid means user switched env or if the email is pending verify
        return redirectToAuth0(env);
      }
    }

    const { tokens, env } = app;
    const currentToken = tokens.get(env) ?? '';

    checkTokenState(env, currentToken);
  }, [app]);

  return (
    <Route {...props}>
      <ExpiredDialog open={expiredSession} />
      <div className={(classes.main, 'pageContainer')}>{props.children}</div>
    </Route>
  );
};

const PublicRoute: FC<React.ComponentProps<typeof Route>> = (props) => {
  return <Route {...props}>{props.children}</Route>;
};

const HeaderRoutes: FC<React.ComponentProps<typeof Route>> = (props) => {
  const location = useLocation();
  useEffect(() => {
    // this will be called everytime location changes for unauthenticated routes
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: document.title });
  }, [location]);
  const classes = useStyles();
  return (
    <Route {...props}>
      <div className={(classes.main, 'pageContainer')}>
        <Header />
        <Switch>
          <PrivateRoute path={Routes.Register}>
            <Register />
          </PrivateRoute>
          <PublicRoute path={Routes.Echo}>
            <Echo />
          </PublicRoute>
          <PublicRoute path={Routes.Login.Base}>
            <LoginRouter />
          </PublicRoute>
          <PublicRoute path={Routes.Logout}>
            <Logout />
          </PublicRoute>
          <PublicRoute path={Routes.Verified}>
            <Verified />
          </PublicRoute>
          <PublicRoute path={Routes.Callback}>
            <Callback />
          </PublicRoute>
          <PublicRoute path={`${Routes.Error}/:message`}>
            <Error />
          </PublicRoute>
          <PublicRoute path={Routes.Home}>
            <Home />
          </PublicRoute>
        </Switch>
      </div>
    </Route>
  );
};

const AuthentiecatedRoutes: FC<React.ComponentProps<typeof Route>> = (props) => {
  const location = useLocation();
  useEffect(() => {
    // this will be called everytime location changes for authenticated routes
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: document.title });
  }, [location]);
  return (
    <Route {...props}>
      <AuthenticatedHeader>
        <Switch>
          <PrivateRoute path={Routes.Overview}>
            <Overview />
          </PrivateRoute>
          <RegisteredRoute path={Routes.Application.Settings}>
            <ApplicationSettings />
          </RegisteredRoute>
          <RegisteredRoute path={Routes.QuickStart.Base}>
            <QuickStart />
          </RegisteredRoute>
          <RegisteredRoute path={Routes.Reports.Base}>
            <Reports />
          </RegisteredRoute>
          <RegisteredRoute path={Routes.Customizations}>
            <Customizations />
          </RegisteredRoute>
          <RegisteredRoute path={Routes.Teams.Base}>
            <TeamSettings />
          </RegisteredRoute>
          <RegisteredRoute path={Routes.Integrations.Base}>
            <Integrations />
          </RegisteredRoute>
        </Switch>
      </AuthenticatedHeader>
    </Route>
  );
};

export default function RootRouter() {
  return (
    <Router>
      <Switch>
        <AuthentiecatedRoutes
          path={[
            Routes.Overview,
            Routes.Application.Settings,
            Routes.QuickStart.Base,
            Routes.Customizations,
            Routes.Teams.Base,
            Routes.Reports.Base,
            Routes.Integrations.Base,
          ]}
        />
        <HeaderRoutes
          path={[
            Routes.Register,
            Routes.Echo,
            Routes.Login.Base,
            Routes.Logout,
            Routes.Verified,
            Routes.Callback,
            Routes.Error,
            Routes.Home,
          ]}
        />
      </Switch>
    </Router>
  );
}

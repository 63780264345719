import { createContext, useEffect, useState, FC } from 'react';

import { isValidJwt } from '../../lib/jwt';
import storage from '../../lib/storage';
import { App, AppContextState } from './types';

// TODO: May need to remove this as we no longer allow user to switch envs
export enum Env {
  Production = 'prod',
}

// Set the default env here
export const defaultEnv = Env.Production;

function enumKeys<O extends Record<string, unknown>, K extends keyof O = keyof O>(obj: O): K[] {
  return Object.keys(obj).filter((k) => Number.isNaN(+k)) as K[];
}

const getCurrentApp = (): App => {
  let env = storage.get('env');
  if (env === null) env = defaultEnv;
  const tokens = new Map<string, string>();
  for (const e of enumKeys(Env)) {
    const currEnv = Env[e];
    const t = storage.get(currEnv);
    if (t !== null) {
      tokens.set(currEnv, t);
    }
  }
  const nextRedirect = storage.get('nextRedirect') || '/overview';
  return {
    env,
    tokens,
    nextRedirect,
  };
};

const currApp = getCurrentApp();

const contextDefaultValues: AppContextState = {
  app: currApp,
  setApp: () => {}, // eslint-disable-line
};

export const AppContext = createContext<AppContextState>(contextDefaultValues);

const AppProvider: FC = ({ children }) => {
  const [app, setApp] = useState<App>(contextDefaultValues.app);

  useEffect(() => {
    // when the env is set store it to storage so it's persistent
    storage.set('env', app.env);
    for (const env of Array.from(app.tokens.keys())) {
      const token = app.tokens.get(env);
      if (isValidJwt(token)) {
        storage.set(env, String(token));
      }
    }
    storage.set('nextRedirect', app.nextRedirect || '');
  }, [app]);

  return (
    <AppContext.Provider
      value={{
        app,
        setApp,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;

import { createContext, FC, useCallback, useEffect, useState } from 'react';

import axios from 'axios';

import useApp from '../../hooks/useApp';
import { getSDKClient, TeamResponse } from '../../lib/sdk';
import storage from '../../lib/storage';
import { TeamContextState } from './types';

const isUnauthorizedError = (err: unknown): boolean => axios.isAxiosError(err) && err.response?.status === 401;

const defaultEmptyTeamValue: TeamResponse = {
  team_id: '',
  team_name: '',
  created_at: '',
};

const getCurrentTeam = (): TeamResponse => {
  const teamStr = storage.get('team');
  let team: TeamResponse;
  if (teamStr === null) {
    team = defaultEmptyTeamValue;
  } else {
    team = JSON.parse(teamStr);
  }

  return team;
};

const currentTeam = getCurrentTeam();

const contextDefaultValues: TeamContextState = {
  team: currentTeam,
  allTeams: [],
  setTeam: (teamId: string) => {}, // eslint-disable-line
  refreshTeamsThenSet: async (teamId?: string) => {}, // eslint-disable-line
};

export const TeamContext = createContext<TeamContextState>(contextDefaultValues);

const TeamProvider: FC = ({ children }) => {
  const { app } = useApp();
  const [allTeams, setAllTeams] = useState<TeamResponse[] | null>([]);
  const [selectedTeam, setSelectedTeam] = useState<TeamResponse>(contextDefaultValues.team);

  // hook to get all of users teams in the corresponding environment
  useEffect(() => {
    const { tokens, env } = app;
    const currentToken = tokens.get(env) ?? '';

    async function getTeams(token: string) {
      const sdk = getSDKClient(env, token);
      try {
        const teams = await sdk.getTeams();

        setAllTeams(teams);

        if (teams.length === 0) {
          setSelectedTeam(defaultEmptyTeamValue);
        }

        // pick default team if we are using empty value or if team is not in list of teams
        if (selectedTeam.team_id === '' || teams.find((t) => t.team_id === selectedTeam.team_id) === undefined) {
          setSelectedTeam(teams[0]);
        }
      } catch (err) {
        if (isUnauthorizedError(err)) {
          setAllTeams(null);
        } else {
          setAllTeams([]);
        }
      }
    }

    getTeams(currentToken);
  }, [app, selectedTeam.team_id]);

  // hook to store team every time it is switched
  useEffect(() => {
    storage.set('team', JSON.stringify(selectedTeam));
  }, [selectedTeam]);

  const setTeam = useCallback(
    (teamId: string) => {
      if (allTeams === null) {
        return;
      }
      const team = allTeams.find((t) => t.team_id === teamId);
      if (team === undefined) {
        return;
      }
      setSelectedTeam(team);
    },
    [allTeams],
  );

  const refreshTeamsThenSet = useCallback(
    async (teamId?: string) => {
      const { tokens, env } = app;
      const currentToken = tokens.get(env) ?? '';

      async function refreshAndSet(token: string) {
        const sdk = getSDKClient(env, token);
        try {
          const teams = await sdk.getTeams();

          setAllTeams(teams);

          if (teams.length === 0) {
            setSelectedTeam(defaultEmptyTeamValue);
            return;
          }

          if (teamId === undefined) {
            setSelectedTeam(teams[0]);
            return;
          }

          const newTeam = teams.find((t) => t.team_id === teamId);
          if (newTeam !== undefined) {
            setSelectedTeam(newTeam);
          }
        } catch (err) {
          if (isUnauthorizedError(err)) {
            setAllTeams(null);
          } else {
            setAllTeams([]);
          }
        }
      }
      refreshAndSet(currentToken);
    },
    [app],
  );

  return (
    <TeamContext.Provider
      value={{
        team: selectedTeam,
        allTeams,
        setTeam,
        refreshTeamsThenSet,
      }}
    >
      {children}
    </TeamContext.Provider>
  );
};

export default TeamProvider;

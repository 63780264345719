import { useState, useEffect, useCallback } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { Redirect } from 'react-router-dom';

import { Backdrop } from '../../components/Backdrop';
import RequestProductionMessage from '../../components/RequestProductionAccessMsg';
import useApp from '../../hooks/useApp';
import useTeam from '../../hooks/useTeam';
import { redirectToAuth0 } from '../../lib/auth0';
import { getErrorMessage } from '../../lib/error';
import { hasCustomerInfoScope, isValidJwt } from '../../lib/jwt';
import Routes from '../../lib/routes';
import { CustomerInfo, getSDKClient } from '../../lib/sdk';
import ClientInfo from './ClientInfo';
import CreateCustomer from './CreateCustomer';

const isUnauthorisedError = (error: unknown) => {
  return axios.isAxiosError(error) && error.response?.status === 401;
};

export default function ApplicationSettings(): JSX.Element {
  const { app } = useApp();
  const { team } = useTeam();
  const { enqueueSnackbar } = useSnackbar();
  const [customerInfo, setCustomerInfo] = useState<CustomerInfo | null>(null);
  const [requirePermission, setRequirePermission] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loggedOut, setLoggedOut] = useState<boolean>(false);
  const [token, setToken] = useState<string>('');

  const refreshClientSecret = async () => {
    try {
      const sdk = getSDKClient(app.env, String(token));
      if (customerInfo !== null) {
        const customerAppId = customerInfo?.customer_app_id;
        const secret = await sdk.refreshClientSecret(customerAppId);
        if (secret !== null) {
          const secrets = await sdk.getCustomerSecret(customerAppId);
          if (secrets !== null) {
            const prefixes = secrets.map((item) => item.client_secret_prefix);
            setCustomerInfo({ ...customerInfo, client_secret: secret.client_secret, prefixes });
          }
          enqueueSnackbar('Successfully generated client secret.', { variant: 'success' });
        }
      }
    } catch (err) {
      if (isUnauthorisedError(err)) {
        setLoggedOut(true);
      }
      enqueueSnackbar(getErrorMessage(err), { variant: 'error' });
    }
  };

  // TODO: This is not being used should be removed?
  const login = useCallback(() => {
    setIsLoading(true); // No need to set this back to false as we are doing redirect
    // If we do not have token just go to the login page for that env
    redirectToAuth0(app.env);
  }, [app.env]);

  useEffect(() => {
    async function updateCustomerInfo(env: string, token: string) {
      try {
        const sdk = getSDKClient(env, token);
        const customerInfo = await sdk.getCustomerInfo(team.team_id);
        setRequirePermission(false);

        if (customerInfo === null) {
          setCustomerInfo(null);
          return;
        }

        const customerAppId = customerInfo.customer_app_id;
        const secrets = await sdk.getCustomerSecret(customerAppId);
        if (secrets !== null) {
          const prefixes = secrets.map((item) => item.client_secret_prefix);
          setCustomerInfo({ ...customerInfo, prefixes });
        }
      } catch (err) {
        if (isUnauthorisedError(err)) {
          setLoggedOut(true);
        }
        enqueueSnackbar(getErrorMessage(err), { variant: 'error' });
      }
    }

    const { tokens, env } = app;
    const currentToken = tokens.get(env) ?? '';

    setToken(currentToken);

    if (hasCustomerInfoScope(currentToken)) {
      updateCustomerInfo(env, currentToken);
    } else {
      if (isValidJwt(currentToken)) {
        setRequirePermission(true);
      }
    }
  }, [app, login, team.team_id, enqueueSnackbar]);

  if (loggedOut) {
    return <Redirect to={Routes.Logout} />;
  }

  // If the customer does not have to permissions to manage the api
  if (requirePermission) {
    return <RequestProductionMessage />;
  }

  // If there is existing customer we show client info, otherwise we should create customer component
  if (customerInfo === null) {
    return (
      <>
        <Backdrop open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <CreateCustomer />
      </>
    );
  }

  // This is used for displaying customer overview.
  return (
    <>
      <Backdrop open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ClientInfo customerInfo={customerInfo} refreshClientSecret={refreshClientSecret} />
    </>
  );
}

import { DialogContent, Table, TableBody, TableRow, TableCell, DialogTitle } from '@mui/material';

import { Recipient, Sender, getCurrencyView } from '../MandatesTable';
import DialogWrapper from './Wrapper';

type Props = {
  open: boolean;
  handleClose: () => void;
  payment: Payment;
};

type Fee = {
  amount: number;
  currency: string;
  paid_by: 'SENDER' | 'RECIPIENT';
  paid_by_account_id: string;
};

export type Payment = {
  payment_id: string;
  amount: number;
  currency: string;
  type: string;
  updated_at: string;
  status: string;
  payment_details: {
    description: string;
    mandate_id: string;
    transaction_reference_id: string;
  };
  recipient: Recipient;
  sender: Sender;
  created_at: string;
  payment_method_id?: string;
  payment_method?: {
    card: {
      card_details: {
        brand: string;
        last4: string;
      };
    };
  };
  metadata?: Record<string, string>;
  fees: Fee[];
};

export function PaymentDetails({ open, handleClose, payment }: Props) {
  return (
    <DialogWrapper
      id=""
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      title="Payment Details"
      open={open}
    >
      <DialogContent dividers>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Description</TableCell>
              <TableCell align="right">{payment.payment_details.description}</TableCell>
            </TableRow>
            {rowIfNotEmpty('Mandate ID', payment.payment_details.mandate_id)}
            {rowIfNotEmpty('Payment Method ID', payment.payment_method_id)}
            {rowIfNotEmpty('Card brand', payment.payment_method?.card.card_details.brand)}
            {rowIfNotEmpty('Card number (last 4)', payment.payment_method?.card.card_details.last4)}
            <TableRow>
              <TableCell>Transaction Reference ID</TableCell>
              <TableCell align="right">{payment.payment_details.transaction_reference_id}</TableCell>
            </TableRow>
            {renderFees(payment)}
          </TableBody>
        </Table>
        {renderMetadata(payment.metadata)}
      </DialogContent>
    </DialogWrapper>
  );
}

const rowIfNotEmpty = (heading: string, value?: string): JSX.Element => {
  if (value !== undefined && value != '') {
    return (
      <TableRow>
        <TableCell sx={{ overflowWrap: 'break-word' }}>{heading}</TableCell>
        <TableCell sx={{ overflowWrap: 'break-word' }} align="right">
          {value}
        </TableCell>
      </TableRow>
    );
  } else {
    return <></>;
  }
};

const renderMetadata = (metadata?: Record<string, string>): JSX.Element => {
  if (metadata === undefined) {
    return <></>;
  }

  const rows = [];

  for (const key of Object.keys(metadata)) {
    rows.push(rowIfNotEmpty(key, metadata[key]));
  }

  return (
    <>
      <DialogTitle sx={{ textAlign: 'center' }}>Metadata</DialogTitle>
      <Table sx={{ tableLayout: 'fixed' }}>
        <TableBody>{rows}</TableBody>
      </Table>
    </>
  );
};

const renderFees = (payment: Payment): JSX.Element => {
  const feeRows = [];

  for (const fee of payment.fees) {
    if (fee.paid_by === 'SENDER') {
      feeRows.push(rowIfNotEmpty('Fee Surcharge', `${fee.currency} ${getCurrencyView(fee.amount)}`));
    } else {
      feeRows.push(rowIfNotEmpty('Payment Fee', `${fee.currency} ${getCurrencyView(fee.amount)}`));
    }
  }

  return <>{feeRows}</>;
};

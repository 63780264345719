import { useMemo, useState, ComponentProps, useCallback } from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/styles';
import { addDays, isAfter } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';

import useResponsive from '../../../../hooks/useResponsive';
import { getErrorMessage } from '../../../../lib/error';
import { utcToString } from '../../../../utils/format';
import { LinkedUser, useDownloadZip } from '../../api/useQuickStartApi';

const ROWS_PER_PAGE = 10;
const DATA_COLUMNS = 7;
const DOWNLOAD_EXPIRATION_DAYS = 45;

const EllipsisTypography = styled(Typography)({
  maxWidth: 180,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const styles = {
  tableRow: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
};

type DialogRowProps = { label: string | JSX.Element; value: string | JSX.Element; hasBottomBorder?: boolean };
const DialogRow = ({ label, value, hasBottomBorder = false }: DialogRowProps): JSX.Element => (
  <>
    <Stack direction="row" spacing={2} sx={{ justifyContent: 'space-between' }}>
      {typeof label === 'string' ? <Typography sx={{ minWidth: '20%' }}>{label}</Typography> : label}
      {typeof value === 'string' ? (
        <Typography align="right" sx={{ wordBreak: 'break-all' }}>
          {value}
        </Typography>
      ) : (
        value
      )}
    </Stack>
    {hasBottomBorder && (
      <Box sx={{ marginLeft: 4, marginRight: 4 }}>
        <Divider />
      </Box>
    )}
  </>
);

type UserRowProps = {
  linkedUser: LinkedUser;
  downloadZip: () => void;
  onRowClick?: () => void;
};
const UserRow = (props: UserRowProps) => {
  const updateDate = useMemo(() => utcToString(props.linkedUser.update_date), [props.linkedUser.update_date]);

  const dataCell = useMemo(() => {
    if (isAfter(new Date(), addDays(Date.parse(props.linkedUser.connection_date), DOWNLOAD_EXPIRATION_DAYS))) {
      return <Typography sx={{ color: 'gray', cursor: 'default' }}>Expired </Typography>;
    }

    if (props.linkedUser.status !== 'COMPLETE') {
      return <Typography sx={{ color: 'gray', cursor: 'default' }}>Download </Typography>;
    }

    return (
      <Link
        underline="hover"
        sx={{ cursor: 'pointer' }}
        onClick={(e) => {
          e.stopPropagation();
          props.downloadZip();
        }}
      >
        Download
      </Link>
    );
  }, [props]);

  return (
    <TableRow hover key={props.linkedUser.login_identity_id} onClick={props?.onRowClick} sx={styles.tableRow}>
      <TableCell align="center">{updateDate}</TableCell>
      <TableCell align="center">
        <EllipsisTypography>{props.linkedUser.application_id}</EllipsisTypography>
      </TableCell>
      <TableCell align="center">
        <EllipsisTypography>{props.linkedUser.user_id}</EllipsisTypography>
      </TableCell>
      <TableCell align="center">
        <EllipsisTypography>{props.linkedUser.institution_id}</EllipsisTypography>
      </TableCell>
      <TableCell align="center">{props.linkedUser.status}</TableCell>
      <TableCell align="center">{dataCell}</TableCell>
      <TableCell align="center">
        {props.linkedUser.download_date && (
          <Tooltip title={`Last Downloaded: ${props.linkedUser.download_date}`}>
            <CheckCircleIcon color="primary" fontSize="small" />
          </Tooltip>
        )}
      </TableCell>
    </TableRow>
  );
};

type LinkedUserModalProps = {
  linkedUser: LinkedUser | null;
  downloadZip: () => void;
  onModalClose: () => void;
};
const LinkedUserModal = ({ linkedUser, downloadZip, onModalClose: handleModalClose }: LinkedUserModalProps) => {
  const dataRow = useMemo(() => {
    if (linkedUser === null) {
      return null;
    }

    if (isAfter(new Date(), addDays(Date.parse(linkedUser.connection_date), 30))) {
      return (
        <DialogRow label="Data: " value={<Typography sx={{ color: 'gray', cursor: 'default' }}>Expired</Typography>} />
      );
    }

    if (linkedUser.status !== 'COMPLETE') {
      return (
        <DialogRow label="Data: " value={<Typography sx={{ color: 'gray', cursor: 'default' }}>Download</Typography>} />
      );
    }

    return (
      <DialogRow
        label="Data:"
        value={
          <Link
            underline="hover"
            sx={{ cursor: 'pointer' }}
            onClick={() => linkedUser.login_identity_id && downloadZip()}
          >
            Download
          </Link>
        }
      />
    );
  }, [downloadZip, linkedUser]);

  if (linkedUser == null) {
    return null;
  }

  return (
    <Dialog open onClose={handleModalClose} fullWidth maxWidth="md">
      <DialogTitle sx={{ textAlign: 'center', paddingTop: 3 }}>
        <Typography variant="h5" gutterBottom>
          User Details
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleModalClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Divider />
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <DialogRow hasBottomBorder label="Application ID:" value={linkedUser.application_id} />
          <DialogRow hasBottomBorder label="User ID:" value={linkedUser.user_id} />
          <DialogRow hasBottomBorder label="Institution:" value={linkedUser.institution_id} />
          <DialogRow hasBottomBorder label="Status:" value={linkedUser.status} />
          <DialogRow hasBottomBorder label="Last Updated (UTC):" value={utcToString(linkedUser.update_date)} />
          <DialogRow
            hasBottomBorder
            label="Last Downloaded (UTC):"
            value={linkedUser.download_date ? utcToString(linkedUser.download_date) : 'Not downloaded'}
          />
          {dataRow}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

type props = {
  users: LinkedUser[];
};
export default function QuickStartTable({ users }: props): JSX.Element {
  const { isDesktop } = useResponsive();
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams<{ customer_app_id: string }>();

  const [page, setPage] = useState(0);
  const [modalLinkedUser, setModalLinkedUser] = useState<LinkedUser | null>(null);

  const { downloadZip: downloadZipHelper } = useDownloadZip();
  const handleChangePage: ComponentProps<typeof TablePagination>['onPageChange'] = (_, newPage) => {
    setPage(newPage);
  };

  const emptyRowHeight = useMemo(() => (isDesktop ? 56 : 106), [isDesktop]);
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * ROWS_PER_PAGE - users.length) : 0;

  const handleModalOpen = useCallback((user: LinkedUser) => setModalLinkedUser(user), []);
  const handleModalClose = useCallback(() => setModalLinkedUser(null), []);

  const downloadZip = useCallback(
    async (customerAppId: string, linkedUserId: string) => {
      try {
        await downloadZipHelper(customerAppId, linkedUserId);
        enqueueSnackbar('Downloaded successfully', { variant: 'success' });
      } catch (err) {
        enqueueSnackbar(getErrorMessage(err), { variant: 'error' });
      }
    },
    [downloadZipHelper, enqueueSnackbar],
  );

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Last Updated (UTC)</TableCell>
              <TableCell align="center">Application ID</TableCell>
              <TableCell align="center">User ID</TableCell>
              <TableCell align="center">Institution</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Data</TableCell>
              <TableCell>Downloaded</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.slice(page * ROWS_PER_PAGE, (page + 1) * ROWS_PER_PAGE).map((u, i) => (
              <UserRow
                key={i}
                linkedUser={u}
                downloadZip={() => downloadZip(params.customer_app_id, u.id)}
                onRowClick={() => handleModalOpen(u)}
              />
            ))}

            {emptyRows > 0 && (
              <TableRow style={{ height: emptyRowHeight * emptyRows }}>
                <TableCell colSpan={DATA_COLUMNS} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component={Paper}
        count={users.length}
        rowsPerPage={ROWS_PER_PAGE}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[ROWS_PER_PAGE]}
      />
      <LinkedUserModal
        linkedUser={modalLinkedUser}
        downloadZip={() => {
          modalLinkedUser && downloadZip(params.customer_app_id, modalLinkedUser.id);
        }}
        onModalClose={handleModalClose}
      />
    </>
  );
}

import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

// The spaces before and after the link component are necessary
// otherwise, the components are too close together
export default function RequestProductAccess(): JSX.Element {
  return (
    <>
      <Typography variant="h1" component="h1" align="center" gutterBottom>
        Request production access
      </Typography>
      <Typography variant="h6" component="h6" align="center" gutterBottom>
        Contact{' '}
        <Link href="mailto:sales@finverse.com" target="_blank" rel="noreferrer">
          sales@finverse.com
        </Link>{' '}
        to request access to {"Finverse's"} production environment.
      </Typography>
    </>
  );
}

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';

import useResponsive from '../hooks/useResponsive';

export type NullableDate = Date | null;
export type DateRangeValues = (Date | null)[];

type DateRangePickerProps = Omit<DatePickerProps<Date, Date>, 'onChange' | 'value'> & {
  values: DateRangeValues;
  onStartDateChange: (value: NullableDate, keyboardInputValue?: string | undefined) => void;
  onEndDateChange: (value: NullableDate, keyboardInputValue?: string | undefined) => void;
};

const DateRangePicker = ({ values, onStartDateChange, onEndDateChange, ...props }: DateRangePickerProps) => {
  const { isMobile } = useResponsive();
  const [startDate = null, endDate = null] = values;

  return (
    <Stack gap={1} sx={{ flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'center', alignItems: 'center' }}>
      <DatePicker label="Start" {...props} value={startDate} onChange={onStartDateChange} />
      {!isMobile && <Typography align="center"> to </Typography>}
      <DatePicker label="End" {...props} value={endDate} onChange={onEndDateChange} />
    </Stack>
  );
};

export default DateRangePicker;

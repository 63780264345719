// A layer that wraps around the localStorage
// In case we ever decide to use indexDB or some other technology
class AppStorage {
  set(key: string, val: string): void {
    localStorage.setItem(key, val);
  }
  get(key: string): string | null {
    return localStorage.getItem(key);
  }
  clear(): void {
    localStorage.clear();
  }
}

export default new AppStorage();

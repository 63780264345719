import DialogContent from '@mui/material/DialogContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import DialogWrapper from './Wrapper';

type MandateRecipient = {
  name: string;
  accountId: string;
  customerAppId: string;
  customerAppName?: string; // we may add this in future
  settlementAccountId?: string; // we may add this in future
};

export default function MandateRecipient({
  title,
  open,
  handleClose,
  recipient,
}: {
  title: string;
  open: boolean;
  handleClose: () => void;
  recipient: MandateRecipient;
}) {
  return (
    <DialogWrapper
      id="mandate-recipient"
      onClose={handleClose}
      aria-labelledby="mandate-recipient"
      title={title}
      open={open}
    >
      <DialogContent dividers>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Recipient Name</TableCell>
              <TableCell align="right">{recipient.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Recipient Account ID</TableCell>
              <TableCell align="right">{recipient.accountId}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Customer App ID</TableCell>
              <TableCell align="right">{recipient.customerAppId}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </DialogContent>
    </DialogWrapper>
  );
}

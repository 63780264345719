import { Button, Container, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';

type Props = {
  title: string;
  subtitle: string;
  buttonText: string;
  buttonHref: string;
};
const NoResourcePage = ({ title, subtitle, buttonText, buttonHref }: Props) => {
  return (
    <Container maxWidth="lg" sx={{ flexGrow: 1, p: 4 }}>
      <Grid container spacing={3} justifyContent="center" direction="column">
        <Box>
          <Typography variant="h1" align="center" gutterBottom>
            {title}
          </Typography>
        </Box>
        <Grid container item direction="row">
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Typography variant="h4">{subtitle} </Typography>
          </Grid>
        </Grid>
        <Grid container item justifyContent="center" sx={{ marginTop: 4 }}>
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Button fullWidth variant="contained" size="large" sx={{ maxWidth: 360 }} href={buttonHref}>
              {buttonText}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default NoResourcePage;

import { useEffect, useState } from 'react';

import { Redirect, Route, Switch } from 'react-router-dom';

import Routes from '../../lib/routes';
import { GetCustomerApps } from '../../lib/useDeveloperApi';
import XeroRouter from './Xero';

const Integrations = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [customerAppId, setCustomerAppId] = useState<string | undefined>(undefined);
  const { data } = GetCustomerApps();

  useEffect(() => {
    if (data != undefined) {
      // we always get first non-quickstart app
      let newCustomerAppValue = undefined;
      for (const customerApp of data) {
        if (customerApp.quickstart === false) {
          newCustomerAppValue = customerApp.customer_app_id;
          break;
        }
      }
      setCustomerAppId(newCustomerAppValue);
      setLoading(false);
    }
  }, [data]);

  if (loading === true) {
    return <></>;
  }

  return (
    <Switch>
      <Route path={Routes.Integrations.Xero}>
        <XeroRouter customerAppId={customerAppId} />
      </Route>
      <Route path={Routes.Integrations.Base}>
        <Redirect to={Routes.Integrations.Xero} />
      </Route>
    </Switch>
  );
};

export default Integrations;

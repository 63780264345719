import axios from 'axios';

import { ErrBodyModelV2, Error400Model } from './sdk';

export const getErrorMessage = (err: unknown): string => {
  if (!axios.isAxiosError(err)) {
    return 'UNKNOWN ERROR';
  }

  if (err.response?.status === 401) {
    return 'Session expired.';
  }

  if (err.response?.status === 400) {
    let errorResponse: Error400Model;
    if (isErrBodyModel(err.response.data)) {
      errorResponse = ErrBodyModelToErr400Model(err.response.data);
    } else {
      errorResponse = err.response.data;
    }

    if (errorResponse.error.name === 'INSUFFICIENT_PERMISSION' || errorResponse.error.name === 'NOT_AUTHORIZED') {
      return 'Insufficient permission to perform this action';
    }
    if (errorResponse.error.name === 'DEVELOPER_ACCOUNT_NOT_FOUND') {
      return 'Email not found. Make sure this person has already registered an account.';
    }
    return errorResponse.error.message;
  }

  // Default error is internal error
  return 'An error occurred performing this actions. Please try again or contact customer support if the problem persists.';
};

const ErrBodyModelToErr400Model = (err: ErrBodyModelV2): Error400Model => {
  return {
    error: {
      code: 0, // does not matter, we don't really check this value
      message: err.error.message,
      name: err.error.error_code,
    },
  };
};

const isErr400Model = (err: any): err is Error400Model => {
  return err.error !== undefined && err.error.name !== undefined && err.error.message !== undefined;
};

const isErrBodyModel = (err: any): err is ErrBodyModelV2 => {
  return err.error !== undefined && err.error.message !== undefined && err.error.error_code !== undefined;
};

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    boxShadow: 'none',
    margin: '3vh 0 5vh 0',
    zIndex: -2,
    '& div': {
      zIndex: 11,
    },
  },
  toolbar: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  title: {
    flexGrow: 1,
  },
  logo: {
    width: '200px',
  },
}));

export default function Header(): JSX.Element {
  const classes = useStyles();

  return (
    <AppBar position="static" className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <a href="https://www.finverse.com">
          <img className={classes.logo} src={process.env.PUBLIC_URL + '/logo.svg'} />
        </a>
      </Toolbar>
    </AppBar>
  );
}

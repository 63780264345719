import { useRef } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import TextField from '@mui/material/TextField';

export type SearchBarProps = {
  onSearch?: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
};

const SearchBar = ({ onSearch }: SearchBarProps) => {
  const searchRef = useRef<HTMLInputElement | null>();

  const handleSearchIconClick = () => {
    searchRef.current?.focus();
  };
  return (
    <Box
      sx={{
        minWidth: { xs: 1, sm: '340px' },
        backgroundColor: '#ffffff',
      }}
    >
      <TextField
        label="Search"
        placeholder="Search User ID, institution or status..."
        variant="outlined"
        onChange={onSearch}
        inputRef={searchRef}
        fullWidth
        sx={{
          [`& .${outlinedInputClasses.root}:hover > fieldset`]: { borderColor: 'primary.dark' },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <IconButton aria-label="search bar" onClick={handleSearchIconClick}>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default SearchBar;

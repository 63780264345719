import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

import useApp from '../hooks/useApp';
import { redirectToAuth0 } from '../lib/auth0';

type DialogProps = {
  open: boolean;
};

export default function ExpiredDialog({ open }: DialogProps): JSX.Element {
  const { app } = useApp();

  const closeAndRedirect = () => {
    const { env } = app;
    return redirectToAuth0(env);
  };

  return (
    <Dialog
      open={open}
      onClose={closeAndRedirect}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Your session expired</DialogTitle>
      <DialogContent>
        <DialogContentText>Please login to Finverse Customer Portal again to resume your work</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeAndRedirect}>OK</Button>
      </DialogActions>
    </Dialog>
  );
}

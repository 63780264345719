import { useState, MouseEvent, useCallback, useMemo } from 'react';

import FileCopy from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Button, IconButton, Paper, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';

import useResponsive from '../../../../hooks/useResponsive';
import { copyToClipboard } from '../../../../lib/copy';
import { QuickStart } from '../../../../lib/routes';
import { QuickStartLink } from '../../api/useQuickStartApi';
import LinkBuilderDialog from './LinkBuilderDialog';

export default function LinkCard({ link }: { link: QuickStartLink }): JSX.Element {
  const { isTabletOrDesktop } = useResponsive();
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLButtonElement) | null>(null);
  const openMenu = Boolean(anchorEl);
  const [linkBuilderDialogOpen, setLinkBuilderDialogOpen] = useState(false);

  const showCopyToClipboardMessage = useMemo(() => {
    return () => enqueueSnackbar('Copied to clipboard', { autoHideDuration: 1000 });
  }, [enqueueSnackbar]);

  const handleMenuClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl],
  );
  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleLinkBuilderDialogOpen = useCallback(() => {
    setLinkBuilderDialogOpen(true);
  }, [setLinkBuilderDialogOpen]);

  const handleLinkBuilderDialogClose = useCallback(() => {
    setLinkBuilderDialogOpen(false);
  }, [setLinkBuilderDialogOpen]);

  return (
    <Card
      key={link.customer_app_name}
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        justifyContent: { xs: 'space-between', lg: 'flex-start' },
        alignItems: { xs: 'flex-start', sm: 'center' },
        paddingTop: { md: 2 },
        paddingBottom: { md: 2 },
      }}
    >
      <CardContent sx={{ ...(isTabletOrDesktop && { width: '50%' }) }}>
        <Typography sx={{ wordBreak: 'break-all' }}>{link.quickstart_url}</Typography>
      </CardContent>
      <CardActions sx={{ flex: 1, display: 'flex', justifyContent: 'space-around' }}>
        {isTabletOrDesktop ? (
          <>
            <Button
              variant="outlined"
              size="medium"
              component={Link}
              to={`${QuickStart.LinkedUsers}/${link.customer_app_id}`}
              aria-label="View linked users"
            >
              View Linked Users
            </Button>
            <Button
              variant="outlined"
              size="medium"
              aria-label="Open link builder modal"
              onClick={handleLinkBuilderDialogOpen}
            >
              Link Builder
            </Button>
            <LinkBuilderDialog
              open={linkBuilderDialogOpen}
              quickstartLinkId={link.quickstart_link_id}
              handleClose={handleLinkBuilderDialogClose}
            />

            <IconButton
              size="small"
              color="primary"
              aria-label="copy"
              onClick={() => {
                copyToClipboard(link.quickstart_url);
                showCopyToClipboardMessage();
              }}
              sx={{ ml: 2 }}
            >
              <FileCopy />
            </IconButton>
          </>
        ) : (
          <Paper variant="outlined">
            <IconButton size="medium" color="primary" aria-label="more" onClick={handleMenuClick}>
              <MoreHorizIcon />
            </IconButton>
            <Menu
              id="menu"
              aria-labelledby="button"
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleMenuClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem
                onClick={() => {
                  copyToClipboard(link.quickstart_url);
                  showCopyToClipboardMessage();
                  handleMenuClose();
                }}
              >
                Copy Link
              </MenuItem>
              <MenuItem
                component={Link}
                to={`${QuickStart.LinkedUsers}/${link.customer_app_id}`}
                onClick={handleMenuClose}
              >
                View Linked Users
              </MenuItem>
              <MenuItem onClick={handleLinkBuilderDialogOpen}>Link Builder</MenuItem>
            </Menu>
            <LinkBuilderDialog
              quickstartLinkId={link.quickstart_link_id}
              open={linkBuilderDialogOpen}
              handleClose={handleLinkBuilderDialogClose}
            />
          </Paper>
        )}
      </CardActions>
    </Card>
  );
}

import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';

export interface DialogWrapperProps {
  id: string;
  title: string;
  open: boolean;
  children?: React.ReactNode;
  onClose: () => void;
}

const DialogWrapper = (props: DialogWrapperProps) => {
  const { children, title, onClose, open, ...other } = props;
  return (
    <Dialog fullWidth={true} onClose={onClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle sx={{ alignSelf: 'center', fontWeight: 600 }} {...other}>
        {title}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 0,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {children}
    </Dialog>
  );
};

export default DialogWrapper;

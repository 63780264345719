import jwt_decode from 'jwt-decode';

export type DecodedToken = {
  aud: string;
  exp: number;
  iat: number;
  iss: string;
  permissions: string[];
  scope: string;
  sub: string;
  'https://finverse.net/email_verified': boolean;
  'https://finverse.net/registered': boolean;
  'https://finverse.net/email': string;
};

// This is only for decoding the token, does not verify it
export function decode(token: string): DecodedToken {
  const decoded: DecodedToken = jwt_decode(token);
  return decoded;
}

// Checks if the token has strictly expired. If the token is empty, invalid or undefined will return false
export function isExpiredJwt(token?: string): boolean {
  if (token === undefined || token === '') {
    return false;
  }
  try {
    const decodedJwt: any = jwt_decode(token);

    if (typeof decodedJwt.exp !== 'number') {
      return false;
    }

    return decodedJwt.exp * 1000 < Date.now();
  } catch (e) {
    return false;
  }
}

// Checks if the token is valid or not
export function isValidJwt(token?: string): boolean {
  if (token === undefined || token === '') return false;
  try {
    // We just deocde the JSON payload and compare the expiry
    // in order to direct the user to login if it is expired
    // since this is only for frontend validation, it is ok
    // to not verify the signature.
    const decodedJwt: any = jwt_decode(token);

    if (typeof decodedJwt.exp !== 'number') {
      return false;
    }

    if (decodedJwt.exp * 1000 < Date.now()) {
      return false;
    }

    return true;
  } catch (e) {
    return false;
  }
}

/**
 *
 * @param token
 * @returns {boolean}
 *
 * Returns true if the customer:info scope is included else false, returns false if token is invalid or scope is empty or missing
 */
export function hasCustomerInfoScope(token: string): boolean {
  if (!isValidJwt(token)) return false;
  const decodedToken = decode(token);
  if (decodedToken.scope === undefined) return false;
  return decodedToken.scope.includes('customer:info');
}

/**
 *
 * @param token
 * @returns {boolean}
 *
 * Returns true if email is pending verification else false, returns false if token is invalid or email_verified is empty or missing
 */
export function isEmailVerifyPending(token: string): boolean {
  if (!isValidJwt(token)) return true;
  const decodedToken = decode(token);
  return decodedToken['https://finverse.net/email_verified'] !== true;
}

/**
 *
 * @param token
 * @returns {boolean}
 *
 * Returns true if scope is empty else false, returns false if token is invalid or scope is empty or missing
 */
export function hasEmptyScope(token: string): boolean {
  if (!isValidJwt(token)) return true;
  const decodedToken = decode(token);
  return decodedToken.scope === '' || decodedToken.scope === undefined;
}

export function hasRegistered(token?: string): boolean {
  if (token === undefined || token === '') return false;
  const decodedToken = decode(token);
  return decodedToken['https://finverse.net/registered'];
}

export function getEmail(token?: string): string {
  if (token === undefined || token === '') return '';
  const decodedToken = decode(token);
  return decodedToken['https://finverse.net/email'];
}

import { useContext, Fragment, useMemo, useEffect } from 'react';

import ProductionIcon from '@mui/icons-material/AccountBalance';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

import { AppContext } from '../../contexts/AppContext';
import { getErrorMessage } from '../../lib/error';
import { CustomerInfo, getSDKClient, UpdateCustomerParams } from '../../lib/sdk';
import { StyledErrorIcon, SubMessage } from './Styled';

const ReadOnlyFormControl = styled(FormControl)({
  '& div': {
    backgroundColor: '#EEEEEE',
  },
});

const TableTitle = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(6),
  marginBottom: theme.spacing(2),
})) as typeof Typography;
// ^ Typescript workaround. See https://github.com/mui-org/material-ui/issues/29161

type Props = {
  customerInfo: CustomerInfo | null;
  refreshClientSecret?: () => void;
};

const bannerDialogByEnv = {
  prod: {
    title: 'Production environment',
    url: 'https://api.prod.finverse.net/',
  },
};

const APIHostBanner = ({ env }: { env: string }) => {
  const dialog = bannerDialogByEnv[env as 'prod'];
  return (
    <Alert
      iconMapping={{
        info: <ProductionIcon fontSize="inherit" />,
      }}
      severity="info"
      sx={{
        textAlign: 'left',
      }}
    >
      <AlertTitle sx={{ textAlign: 'left' }}>
        <b>{dialog.title}</b>
      </AlertTitle>
      Use
      <Box sx={{ display: 'inline', ml: 1, mr: 1, fontSize: '0.85rem' }}>
        <code>{dialog.url}</code>
      </Box>
      to connect to Finverse API
    </Alert>
  );
};

const testOrLivebannerDialogByRealEnabled = {
  test: {
    title: 'Test app',
    context:
      'Test app can only make test payments (using test cards or our testbank) and access test data (by linking our testbank). You can not make any real payments or access real banks. Contact support@finverse.com to enable real payments or access to real banks.',
  },
  live: {
    title: 'Live app',
    context:
      'Live apps can access real banks. The number of connections to real banks may be subject to quotas; contact sales@finverse.com to verify or upgrade connection quotas.',
  },
};

const TestOrLiveAlertBanner = ({ realEnabled }: { realEnabled: boolean }) => {
  let liveStatus = 'test';
  if (realEnabled) {
    liveStatus = 'live';
  }
  const dialog = testOrLivebannerDialogByRealEnabled[liveStatus as 'live' | 'test'];
  return (
    <Alert
      iconMapping={{
        info: <ProductionIcon fontSize="inherit" />,
      }}
      severity={'info'}
      sx={{
        marginTop: '10px',
        textAlign: 'left',
      }}
    >
      <AlertTitle sx={{ textAlign: 'left' }}>
        <b>{dialog.title}</b>
      </AlertTitle>
      {dialog.context}
    </Alert>
  );
};

const CredentialsInfo = ({ customerInfo, refreshClientSecret }: Props) => (
  <>
    <TableTitle variant="h2" component="h2">
      API Credentials
    </TableTitle>
    <Paper sx={{ p: 3 }}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={3}
        sx={{ textAlign: 'left' }}
      >
        <Grid container direction="row" justifyContent="center" alignItems="center" item xs={12} sm={12}>
          <Grid item xs={12} sm={6}>
            Customer App Id
          </Grid>
          <Grid item xs={12} sm={6}>
            <ReadOnlyFormControl fullWidth>
              <OutlinedInput value={customerInfo?.customer_app_id} readOnly fullWidth />
            </ReadOnlyFormControl>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12}>
          <Divider />
        </Grid>

        <Grid container direction="row" justifyContent="center" alignItems="center" item xs={12} sm={12}>
          <Grid item xs={12} sm={6}>
            Client Id
          </Grid>
          <Grid item xs={12} sm={6}>
            <ReadOnlyFormControl fullWidth>
              <OutlinedInput value={customerInfo?.client_id} readOnly fullWidth />
            </ReadOnlyFormControl>
          </Grid>
        </Grid>

        {customerInfo?.client_secret !== undefined && (
          <>
            <Grid item xs={12} sm={12}>
              <Divider />
            </Grid>

            <Grid container direction="row" justifyContent="center" alignItems="center" item xs={12} sm={12}>
              <Grid item xs={12} sm={6}>
                Client Secret
              </Grid>
              <Grid item xs={12} sm={6}>
                <ReadOnlyFormControl fullWidth>
                  <OutlinedInput value={customerInfo?.client_secret} readOnly fullWidth />
                </ReadOnlyFormControl>
              </Grid>
            </Grid>

            <Grid container direction="row" justifyContent="center" alignItems="center" item xs={12} sm={12}>
              <Grid>
                <Alert severity="warning">
                  Make sure to save this secret somewhere secure as this will not be viewable again.
                </Alert>
              </Grid>
            </Grid>
          </>
        )}

        {customerInfo?.prefixes?.map((secret, index) => {
          return (
            // changed <> to React.Fragment so we can add a unique key property
            <Fragment key={index}>
              <Grid item xs={12} sm={12}>
                <Divider />
              </Grid>

              <Grid container direction="row" justifyContent="center" alignItems="center" item xs={12} sm={12}>
                {index === 0 && (
                  <Grid item xs={12} sm={6}>
                    Client Secret Versions
                  </Grid>
                )}
                {index !== 0 && <Grid item xs={12} sm={6} />}
                {index !== 0 && <Grid />}
                <Grid item xs={12} sm={6}>
                  <ReadOnlyFormControl fullWidth>
                    <OutlinedInput value={secret} readOnly fullWidth />
                  </ReadOnlyFormControl>
                </Grid>
              </Grid>
            </Fragment>
          );
        })}
      </Grid>

      {refreshClientSecret !== undefined && (
        <Button sx={{ mt: 2 }} variant="contained" color="primary" type="submit" onClick={refreshClientSecret}>
          Refresh Client Secret
        </Button>
      )}
    </Paper>
  </>
);

/**
 * Component for displaying client details, this is used for existing customer and after a newly created customer
 */
export default function ClientInfo({ customerInfo, refreshClientSecret }: Props): JSX.Element {
  const { app } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm<UpdateCustomerParams>({
    defaultValues: {
      name: customerInfo?.name,
      redirect_uris: customerInfo?.redirect_uris,
      email: customerInfo?.email,
    },
  });

  const token = app.tokens.get(app.env);

  const updateCustomer = async (updateRequest: UpdateCustomerParams) => {
    if (customerInfo === null) {
      // Should never happen
      enqueueSnackbar('Some error occurred. Please refresh.', { variant: 'error' });
      throw new Error('Internal error');
    }

    if (updateRequest.email === undefined) {
      updateRequest.email = '';
    }

    const redirectURIs = updateRequest.redirect_uris
      .toString()
      .split(',')
      .filter((uri) => uri?.length > 0);

    updateRequest.redirect_uris = redirectURIs;

    const webhookURIs = updateRequest.webhook_uris
      .toString()
      .split(',')
      .filter((uri) => uri.length > 0);

    updateRequest.webhook_uris = webhookURIs;

    try {
      await getSDKClient(app.env, String(token)).updateCustomer(customerInfo.customer_app_id, updateRequest);
      enqueueSnackbar('Successfully updated customer info', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(getErrorMessage(e), { variant: 'error' });
    }
  };

  const authWebhookComponent = useMemo(() => {
    const authWebhooks = customerInfo?.authentication_webhook_uris;
    if (authWebhooks === undefined || authWebhooks === null || authWebhooks.length === 0) {
      return null;
    }
    return (
      <>
        <Grid item xs={12} sm={12}>
          <Divider />
        </Grid>
        <Grid container direction="row" justifyContent="center" alignItems="center" item xs={12} sm={12}>
          <Grid item xs={12} sm={6}>
            Authentication Webhook URIs
            <SubMessage variant="body2">Please contact Finverse Customer Support to update these webhooks</SubMessage>
          </Grid>
          <Grid item xs={12} sm={6}>
            <ReadOnlyFormControl fullWidth>
              <OutlinedInput
                id="authentication_webhook_uris"
                defaultValue={authWebhooks.toString()}
                rows={3}
                multiline
                readOnly
                fullWidth
              />
            </ReadOnlyFormControl>
          </Grid>
        </Grid>
      </>
    );
  }, [customerInfo?.authentication_webhook_uris]);

  useEffect(() => {
    if (customerInfo === null) return;

    setValue('name', customerInfo.name);
    setValue('email', customerInfo.email);
    setValue('redirect_uris', customerInfo.redirect_uris);
    setValue('webhook_uris', customerInfo.webhook_uris);
  }, [customerInfo, setValue]);

  return (
    <Container maxWidth="lg" sx={{ flexGrow: 1, textAlign: 'center' }}>
      <Typography variant="h1" component="h1" sx={{ marginBottom: 4 }}>
        Application settings
      </Typography>
      <Typography variant="h5" component="h5" sx={{ marginTop: 2, marginBottom: 2 }}>
        {`Use the following settings to manage your application's integration with Finverse's API and to generate new API
        keys (client_secret).`}
      </Typography>
      <APIHostBanner env={app.env} />

      <TestOrLiveAlertBanner realEnabled={customerInfo?.real_enabled ?? false} />

      <TableTitle variant="h2" component="h2">
        App Details
      </TableTitle>
      <Paper sx={{ p: 3 }}>
        <form noValidate autoComplete="off" onSubmit={handleSubmit(updateCustomer)}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
            sx={{ textAlign: 'left' }}
          >
            <Grid container direction="row" justifyContent="center" alignItems="center" item xs={12} sm={12}>
              <Grid item xs={12} sm={6}>
                App Name
                <SubMessage variant="body2">
                  Shown to users in Finverse Link (<i>&quot;[App Name] will link your bank&quot;</i> )
                </SubMessage>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <OutlinedInput
                    id="name"
                    endAdornment={<InputAdornment position="end">{errors.name && <StyledErrorIcon />}</InputAdornment>}
                    defaultValue={customerInfo?.name}
                    {...register('name', { required: true, minLength: 3 })}
                    onChange={(a) => setValue('name', a.target.value)}
                    fullWidth
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Divider />
            </Grid>

            <Grid container direction="row" justifyContent="center" alignItems="center" item xs={12} sm={12}>
              <Grid item xs={12} sm={6}>
                Support Email
                <SubMessage variant="body2">Optional, not currently shown to users</SubMessage>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <OutlinedInput
                    id="email"
                    endAdornment={<InputAdornment position="end">{errors.email && <StyledErrorIcon />}</InputAdornment>}
                    defaultValue={customerInfo?.email}
                    {...register('email', { required: false, pattern: /\S+@\S+\.\S+/ })}
                    onChange={(a) => setValue('email', a.target.value)}
                    fullWidth
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Divider />
            </Grid>

            <Grid container direction="row" justifyContent="center" alignItems="center" item xs={12} sm={12}>
              <Grid item xs={12} sm={6}>
                Redirect URIs
                <SubMessage variant="body2">
                  Comma-separated list of URLs where Finverse Link will redirect to at the end of the user flow
                </SubMessage>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <OutlinedInput
                    id="redirect_uris"
                    endAdornment={
                      <InputAdornment position="end">{errors.redirect_uris && <StyledErrorIcon />}</InputAdornment>
                    }
                    {...register('redirect_uris', { required: true })}
                    defaultValue={customerInfo?.redirect_uris.toString()}
                    onChange={(a) => setValue('redirect_uris', a.target.value.split(','))}
                    rows={3}
                    multiline
                    fullWidth
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Divider />
            </Grid>

            <Grid container direction="row" justifyContent="center" alignItems="center" item xs={12} sm={12}>
              <Grid item xs={12} sm={6}>
                Data Webhook URIs
                <SubMessage variant="body2">
                  (Data API only) Optional, comma-separated list of URLs for data update webhooks
                </SubMessage>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <OutlinedInput
                    id="webhook_uris"
                    endAdornment={
                      <InputAdornment position="end">{errors.webhook_uris && <StyledErrorIcon />}</InputAdornment>
                    }
                    {...register('webhook_uris')}
                    defaultValue={(customerInfo?.webhook_uris ?? []).toString()}
                    onChange={(a) => setValue('webhook_uris', a.target.value.split(','))}
                    rows={3}
                    multiline
                    fullWidth
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Divider />
            </Grid>

            <Grid container direction="row" justifyContent="center" alignItems="center" item xs={12} sm={12}>
              <Grid item xs={12} sm={6}>
                Payment Webhook URIs
                <SubMessage variant="body2">
                  (Payments API only) Contact sales@finverse.com to set Payment Webhook URIs
                </SubMessage>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ReadOnlyFormControl fullWidth>
                  <OutlinedInput
                    id="payment_webhook_uris"
                    rows={3}
                    value={customerInfo?.payment_webhook_uris}
                    multiline
                    fullWidth
                    readOnly
                  />
                </ReadOnlyFormControl>
              </Grid>
            </Grid>
            {authWebhookComponent}
          </Grid>
          <Button sx={{ mt: 2 }} variant="contained" color="primary" type="submit">
            Update App Details
          </Button>
        </form>
      </Paper>

      <CredentialsInfo customerInfo={customerInfo} refreshClientSecret={refreshClientSecret} />
    </Container>
  );
}

import { useContext, useEffect, useMemo } from 'react';

import { useLocation } from 'react-router-dom';

import { AppContext, defaultEnv } from '../contexts/AppContext';
import { getAuth0CallbackUriForSignup } from '../lib/auth0';
import storage from '../lib/storage';

type Data = {
  message?: string;
};

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

// To force sign up when the user is already logged in we need to
// 1. Invalidate current auth0 session and clear cookies by going to auth0's logout page and redirect back to dev portal ("/logout?action=signup") -> "action=signup_after_logout"
// 2. Redirect to auth0 sign up page -> "/logout?action=signup"
// If we go to auth0's sign up page without  signing out it will automatically redirect with token as auth0 thinks the user is already signed in

export default function Logout(): JSX.Element {
  const { setApp } = useContext(AppContext);
  const location = useLocation<Data>();
  const query = useQuery();
  // action indicates what the next action will be after logout
  const action = query.get('action');
  // if the action is "signup" redirect to the auth0 signup page
  if (action === 'signup') {
    window.location.href = getAuth0CallbackUriForSignup(defaultEnv);
  }
  // if the action is "signup_after_logout" redirect to "/logout?action=signup" which will redirect to sign up page in auth0
  const origin =
    action === 'signup_after_logout' ? `${window.location.origin}/logout?action=signup` : `${window.location.origin}`;
  const returnURL = encodeURIComponent(origin);

  useEffect(() => {
    storage.clear();
    const tokens = new Map<string, string>();
    setApp({ env: defaultEnv, tokens });
    window.location.href = `${process.env.REACT_APP_OAUTH_LOGOUT_URL}?returnTo=${returnURL}&client_id=${process.env.REACT_APP_OAUTH_CLIENT_ID}`;
  }, [location, returnURL, setApp]);
  return <></>;
}

import { useCallback, useEffect, useState } from 'react';

import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Container, Grid, InputAdornment, Paper, TextField, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';

import useApp from '../../hooks/useApp';
import useTeam from '../../hooks/useTeam';
import { getErrorMessage } from '../../lib/error';
import Routes from '../../lib/routes';
import { getSDKClient } from '../../lib/sdk';

type DangerZoneItemProps = {
  title: string;
  subtitle: string;
  buttonText: string;
  buttonAction?: () => void;
};
const DangerZoneItem = ({ title, subtitle, buttonText, buttonAction }: DangerZoneItemProps) => (
  <Paper sx={{ p: 1, borderColor: 'error.dark' }} variant="outlined">
    <Grid
      container
      direction="row"
      sx={{ display: { xs: 'block', sm: 'flex' } }}
      spacing={1.5}
      alignItems="center"
      padding={2}
    >
      <Grid item flex={1}>
        <Typography variant="h6" fontWeight={'bold'}>
          {title}
        </Typography>
        <Typography variant="caption" component="p">
          {subtitle}
        </Typography>
      </Grid>
      <Grid item textAlign="center">
        <Button
          variant="contained"
          size="large"
          color="error"
          onClick={() => {
            if (buttonAction === undefined) return;
            buttonAction();
          }}
          fullWidth
        >
          {buttonText}
        </Button>
      </Grid>
    </Grid>
  </Paper>
);

const TeamSettings = () => {
  const { app } = useApp();
  const { team, refreshTeamsThenSet } = useTeam();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const [editedTeamName, setEditedTeamName] = useState<string>(team.team_name);

  // any time team name changes, we should update text field
  useEffect(() => {
    setEditedTeamName(team.team_name);
  }, [team.team_name]);

  const updateTeam = useCallback(
    (teamName: string) => {
      if (teamName === '') {
        enqueueSnackbar('Team name cannot be empty.', { variant: 'error' });
        setEditedTeamName(team.team_name);
        return;
      }

      const { tokens, env } = app;
      const token = tokens.get(env) ?? '';

      const update = async () => {
        const sdk = getSDKClient(env, token);
        try {
          await sdk.updateTeam(team.team_id, teamName);

          await refreshTeamsThenSet(team.team_id);
          enqueueSnackbar('Team updated.', { variant: 'success' });
        } catch (err) {
          const errMessage = getErrorMessage(err);
          enqueueSnackbar(errMessage, { variant: 'error' });
        }
      };

      update();
    },
    [app, team, refreshTeamsThenSet, enqueueSnackbar],
  );

  const leaveTeam = useCallback(
    (teamId: string) => {
      const { tokens, env } = app;
      const token = tokens.get(env) ?? '';

      const leave = async () => {
        const sdk = getSDKClient(env, token);
        try {
          await sdk.leaveTeam(teamId);

          enqueueSnackbar('Exited team.', { variant: 'success' });
          await refreshTeamsThenSet(); // set team to first team in list
          history.replace(Routes.Overview);
        } catch (err) {
          const errMessage = getErrorMessage(err);
          enqueueSnackbar(errMessage, { variant: 'error' });
        }
      };

      leave();
    },
    [app, refreshTeamsThenSet, history, enqueueSnackbar],
  );

  return (
    <Container maxWidth="lg" sx={{ flexGrow: 1, p: 4 }}>
      <Grid container spacing={3} direction="column" display="flex">
        <Grid item>
          <Typography variant="h1" component="h1" gutterBottom>
            Team Settings
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h5" component="h5" fontWeight="bold" gutterBottom>
            Edit Team
          </Typography>
        </Grid>
        <Grid item>
          <Paper sx={{ p: 4 }}>
            <TextField
              id="team_name"
              variant="outlined"
              fullWidth
              label="Team Name"
              autoComplete="off"
              placeholder="Pied Piper Inc - Data Team"
              helperText="This is the team name that will be displayed to all members."
              value={editedTeamName}
              onChange={(e) => setEditedTeamName(e.target.value)}
              sx={{ mb: 2 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" sx={{ display: { sm: 'flex', xs: 'none' } }}>
                    <EditIcon color="primary" />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              id="team_id"
              variant="filled"
              fullWidth
              label="Team ID"
              value={team.team_id}
              disabled
              helperText="This is your unique team id. Please provide this to Finverse Customer Support during troubleshooting."
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" sx={{ display: { sm: 'flex', xs: 'none' } }}>
                    <AssignmentIndIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Paper>
        </Grid>
        <Grid item justifyContent="center" display="flex">
          <Button
            size="large"
            variant="contained"
            disabled={editedTeamName === team.team_name}
            onClick={() => {
              updateTeam(editedTeamName);
            }}
          >
            Update
          </Button>
        </Grid>
        <Grid item>
          <Typography variant="h5" component="h5" fontWeight="bold" gutterBottom>
            Danger Zone
          </Typography>
        </Grid>
        <Grid item>
          <DangerZoneItem
            title="Leave Team"
            subtitle="You will lose all your roles and priviliges in this team. Team Owners cannot leave their team."
            buttonText="Leave Team"
            buttonAction={() => leaveTeam(team.team_id)}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default TeamSettings;

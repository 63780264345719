import React from 'react';

import { Theme, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import NavigationCard, { NavigationCardProps } from '../components/NavigationCard';

const routes: NavigationCardProps[] = [
  {
    title: 'Try',
    description: 'Explore our Demo App UI for end-users to make bank payments or link financial account data',
    buttonText: 'Try demo app',
    route: 'https://developer.prod.finverse.net/auth/demoapp?redirect_uri=https://demo-app.prod.finverse.net/get-token',
    external: true,
  },
  {
    title: 'Learn',
    description: 'Read our API documentation and try our Postman Collection',
    buttonText: 'Read documentation',
    route: 'https://docs.finverse.com/',
    external: true,
  },
  {
    title: 'Manage API',
    description: 'Manage your registered applications and API credentials',
    buttonText: 'Manage API Settings',
    route: '/applications/settings',
  },
  {
    title: 'Collect with Xero',
    description:
      'Automate your ARs and payment reconciliation by enabling customers to make instant bank payments from any Xero invoice, using our no-code, fully managed payments service',
    buttonText: 'Connect Xero',
    route: '/settings/integrations/xero',
  },
  {
    title: 'Use Quickstart Data Links',
    description:
      'Retrieve financial account data using our no-code, fully managed linking servce. Best for pilots or non-technical teams',
    buttonText: 'SETUP QUICKSTART LINKS',
    route: '/quick-start/guide',
    disabled: false,
  },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    titleContainer: {
      margin: theme.spacing(9),
    },
    title: {
      margin: theme.spacing(3),
    },
  }),
);

const Overview = (): React.ReactElement => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.titleContainer}>
        <Typography gutterBottom variant="h5" component="h5" align="center">
          Getting started
        </Typography>
        <Typography variant="h1" component="h1" gutterBottom align="center" className={classes.title}>
          Automate bank payments and link accounts with Finverse
        </Typography>
      </div>
      <Grid container spacing={3} justifyContent="flex-start" alignItems="center">
        {routes.map((r, idx) => (
          <Grid item xs={12} sm={6} lg={4} xl={2.4} key={`navigation-${idx}`}>
            <NavigationCard {...r} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Overview;

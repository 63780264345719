import React from 'react';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    minHeight: 380,
    padding: '0.8rem',
  },
  cardContainer: {
    minHeight: 300,
  },
  button: {
    width: '100%',
  },
});

export interface NavigationCardProps {
  title: string;
  description: string;
  buttonText?: string;
  route: string;
  disabled?: boolean;
  external?: boolean;
}

export default function NavigationCard(props: NavigationCardProps): React.ReactElement {
  const classes = useStyles();
  const { route, description, title, buttonText, disabled, external } = props;

  const renderNavButton = () => {
    if (disabled) {
      return (
        <Button variant="contained" size="medium" disabled className={classes.button} disableElevation>
          Coming soon
        </Button>
      );
    }
    if (external) {
      return (
        <Button
          variant="contained"
          size="medium"
          color="primary"
          target="_blank"
          href={route}
          className={classes.button}
          disableElevation
        >
          {buttonText || 'Go'}
        </Button>
      );
    }
    return (
      <Button
        variant="contained"
        size="medium"
        color="primary"
        className={classes.button}
        disableElevation
        component={Link}
        to={route}
      >
        {buttonText || 'Go'}
      </Button>
    );
  };

  return (
    <Card className={classes.root}>
      <CardContent className={classes.cardContainer}>
        <Typography gutterBottom variant="h2" component="h2">
          {title}
        </Typography>

        <Typography variant="body1" color="textSecondary" component="p">
          {description}
        </Typography>
      </CardContent>
      <CardActions>{renderNavButton()}</CardActions>
    </Card>
  );
}

import CssBaseline from '@mui/material/CssBaseline';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { SnackbarProvider } from 'notistack';

import './scss/styles.scss';
import AppProvider from './contexts/AppContext';
import TeamProvider from './contexts/TeamContext';
import RootRouter from './routes';

function App(): JSX.Element {
  return (
    <AppProvider>
      <CssBaseline />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SnackbarProvider maxSnack={1} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={3000}>
          <TeamProvider>
            <RootRouter />
          </TeamProvider>
        </SnackbarProvider>
      </LocalizationProvider>
    </AppProvider>
  );
}

export default App;

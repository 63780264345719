import React from 'react';

import TextField, { TextFieldProps } from '@mui/material/TextField';
import { Controller } from 'react-hook-form';

import { FormInputProps } from './FormInputProps';

export const FormInputText = ({ name, control, label, rules, ...rest }: FormInputProps & TextFieldProps) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error }, formState }) => (
        <TextField
          helperText={error ? error.message : null}
          size="small"
          error={!!error}
          onChange={onChange}
          value={value}
          fullWidth
          label={label}
          variant="outlined"
          {...rest}
        />
      )}
    />
  );
};

import ErrorIcon from '@mui/icons-material/Error';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const StyledErrorIcon = styled(ErrorIcon)({
  color: '#f44336',
});

const SubMessage = styled(Typography)({
  color: '#999999',
});

export { StyledErrorIcon, SubMessage };

/**
 * Copy stuff to clipboard
 *
 * Clipboard API by default, execCommand as fallback
 * @param value
 */
export function copyToClipboard(value: any): void {
  const clipboard = navigator.clipboard;
  if (clipboard !== undefined) {
    navigator.clipboard.writeText(value);
  } else {
    if (document.execCommand) {
      const el = document.createElement('input');
      el.value = value;
      document.body.append(el);
      el.select();
      el.setSelectionRange(0, value.length);
      document.execCommand('copy');
      el.remove();
    }
  }
}
